import React from 'react';
import "../../styles/shimmer.css";


function Shimmer() {
    return (
        <>
        <div className='shimmerHead'></div>
        <div className="shimmerContainer">
            {Array(15).fill("  ").map((el, index) => {
                return (
                    <div className="cardShimmer" key={index}>
                        <div className="cardShimmerUp"></div>
                        <div className="shimmerLineOne"></div>
                        <div className="shimmerLineTwo"></div>
                        <div className="shimmerLineThree"></div>
                    </div>
                )
            })}
        </div>
        </>
    )
}

export default Shimmer;