import React, { useEffect, useState } from 'react';
import "../../../styles/dashboardtab.css";
import { Table, Modal } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useFetch } from '../../../utils/hooks/useFetch';
import { useFetchAuth } from '../../../utils/hooks/useFetchAuth';
import base_url from '../../../utils/config';
import DetailsProject from '../dashReport/DetailsProject';
import ReportList from '../dashReport/ReportList';
import { addItem } from '../../../utils/cartSlice';
import { useDispatch } from 'react-redux';
import ShimmerTableRow from '../../shimmerComponents/ShimmerTableRow';
import { tr } from 'date-fns/locale';

export function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
}
export default function DashboardProjectTab() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [toggle, setToggle] = useState(1);
    const [draftData, setDraftData] = useState([]);
    const [completeData, setCompleteData] = useState([]);
    const { data, setData,isLoading } = useFetchAuth(`client/mp/reports?pages=reports&status=ACTIVE`);
    const [loadingData,setLoadingData] = useState(false);
    const token = localStorage.getItem("token");
    const existingUser = localStorage.getItem("user_id");

    const [detailsModalShow, setDetailsModalShow] = useState({});
    const [reportsModalShow, setReportsModalShow] = useState({});
    const [activeProjectId, setActiveProjectId] = useState(null);
    const [reportActiveProjectId, setReportActiveProjectId] = useState(null);

    const getApiData = async (url) => {
        setLoadingData(true);
        try {
            const headers = {
                'Authorization': `Token ${token}`,
                'Content-Type': 'application/json'
            };

            const options = {
                method: 'GET',
                headers: headers
            };

            const response = await fetch(base_url + url, options);

            if (response.ok) {
                const result = await response.json();
                return result;
            } else {
                console.error('API request failed:', response.status, response.statusText);
            }
        } catch (error) {
            console.error('An error occurred while fetching the API:', error);
        }finally {
			setLoadingData(false);
		};
    };

    const toggleDetailsRow = (itemId) => {
        setActiveProjectId(itemId);
        setDetailsModalShow((prevDetailsModalShow) => ({
            ...prevDetailsModalShow,
            [itemId]: !prevDetailsModalShow[itemId],
        }));
    };

    const toggleReportListRow = (itemId) => {
        setReportActiveProjectId(itemId);
        setReportsModalShow((prevReportsModalShow) => ({
            ...prevReportsModalShow,
            [itemId]: !prevReportsModalShow[itemId],
        }));
    };

    async function handleclick(index) {
        setToggle(index);
        if (index === 3) {
            const result = await getApiData(`client/mp/reports?pages=dashboard&status=DRAFT`);
            setDraftData(result);
        }
        else if (index === 1) {
            const result = await getApiData(`client/mp/reports?pages=reports&status=ACTIVE`);
            setData(result);
        } else if (index === 2) {
            const result = await getApiData(`client/mp/reports?pages=reports&status=COMPLETE`);
            setCompleteData(result);
        }
    }

    function handledraftdata(project) {
        dispatch(addItem(project.mp_order.solution));
        const orderId = project?.mp_order?.id;
        localStorage.setItem("orderId",orderId);
        navigate(`/userdashboardmain/projectsetup/stepupprogressbar/${project.mp_order.solution.url_structure}`);

    }
    const handleNavigate = (audit_cycle_id) => {
        const url = `/userdashboardmain/reportstoreslist?audit_cycle_id=${audit_cycle_id}`;
        const newTab = window.open(url, '_blank');
        // navigate("/userdashboardmain/reportstoreslist", { state: audit_cycle_id })
    }

    return (
        <div className='dash_tab_btn_container_div'>
            <div className='dash_tab_btn_container'>
                <button
                    onClick={() => handleclick(1)}
                    className={(toggle === 1) ? "tab_active" : "tab"}
                >Active Projects</button>

                <button
                    onClick={() => handleclick(2)}
                    className={(toggle === 2) ? "tab_2 tab_active" : "tab_2"}
                >Delivered Projects</button>

                {/* <button
                    onClick={() => handleclick(3)}
                    className={(toggle === 3) ? "tab tab_active" : "tab"}
                >Draft Projects</button> */}
            </div>
            {/* -----------------------------ACTIVE DATA---------------------------------------------------------- */}
            <div
                className={(toggle === 1) ? "content display_content" : "content"}
            >
                {isLoading ?
                 <ShimmerTableRow /> :
                <div className='table-responsive'>
                    <Table className="text-center  table_border dash_tab_table">
                        <thead className='dash_tab_table_head'>
                            <tr>
                                <th className="fw-bold table_border">Order Placement Date</th>
                                <th className="fw-bold table_border">Project Name</th>
                                <th className="fw-bold table_border">No. of Reports</th>
                                <th className="fw-bold table_border">Status</th>
                                <th className="fw-bold table_border">Details</th>
                                <th className="fw-bold table_border">Average Score</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.audit_cycle_data ? data?.audit_cycle_data
                                .map((project,index)=> (
                                    <tr key={index}>
                                        <td className='table_border fw-bold'>{project?.audit_cycle?.start_date}</td>
                                        <td className='table_border fw-bold'>{project?.audit_cycle?.name}</td>
                                        <td className='table_border'>{project?.audit_cycle?.audit_count}</td>
                                        <td className='table_border'>{project?.audit_cycle?.order_status}</td>
                                        <td ><a href="#" onClick={() => handleNavigate(project?.id)} target="_blank" style={{ textDecoration: "none" }}> view</a></td>
                                        {project?.audit_score ?
                                            <td className='table_border'>{project?.audit_score}%</td>
                                            :
                                            <td className='table_border'>Yet to be calculated</td>
                                        }
                                        
                                    </tr>
                                ))
                                : 
                                <tr>
                                     <td colSpan="9" style={{color:"gray"}}>No Active Projects right now...</td>
                                </tr>
                            }
                        </tbody>
                    </Table>
                    </div>
                }

            </div>
            {/* ----------------------------------------COMPLETE DATA--------------------------------------------- */}
            <div className={(toggle === 2) ? "content display_content" : "content"}>
                {loadingData ? 
                    <ShimmerTableRow /> :
                 <div className='table-responsive'>
                    <Table className="text-center table-responsive table_border dash_tab_table" >
                        <thead className='dash_tab_table_head'>
                            <tr>
                                <th className="fw-bold table_border">Order Placement Date</th>
                                <th className="fw-bold table_border">Project Name</th>
                                <th className="fw-bold table_border">No. of Reports</th>
                                <th className="fw-bold table_border">Status</th>
                                <th className="fw-bold table_border">Details</th>
                                <th className="fw-bold table_border">Average Score</th>
                            </tr>
                        </thead>
                        <tbody>
                            {completeData?.audit_cycle_data ?
                            completeData?.audit_cycle_data && completeData?.audit_cycle_data
                                .map((project,index) => (
                                    <tr key={index}>
                                        <td className='table_border fw-bold'>{project?.audit_cycle?.start_date}</td>
                                        <td className='table_border fw-bold'>{project?.audit_cycle?.name}</td>
                                        <td className='table_border'>{project?.audit_cycle?.audit_count}</td>
                                        <td className='table_border'>{project?.audit_cycle?.order_status}</td>
                                        <td ><a href="#" onClick={() => handleNavigate(project?.id)} target="_blank" style={{ textDecoration: "none" }}> view</a></td>
                                        {project?.audit_score ?
                                            <td className='table_border'>{project?.audit_score}%</td>
                                            :
                                            <td className='table_border'>Yet to be calculated</td>
                                        }
                                    </tr>
                                )) : <tr>
                                    <td colSpan="9" style={{color:"gray"}}>No Delivered Projects yet...</td>
                                    </tr>}
                        </tbody>
                    </Table>
                    </div>
                    
                }
            </div>
            {/* --------------------------------------DRAFT DATA-------------------------------------------------- */}
            {/* <div className={(toggle === 3) ? "content display_content" : "content"}>
                {loadingData ?  
                    <ShimmerTableRow /> :
                 <div className='table-responsive'>
                    <Table className="text-center table-responsive table_border dash_tab_table" >
                        <thead className='dash_tab_table_head'>
                            <tr>
                                <th>#</th>
                                <th className="fw-bold table_border">Product Name</th>
                                <th>Created At</th>
                                <th>Modified At</th>
                                <th className="fw-bold table_border"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {draftData?.mp_order_data ? draftData?.mp_order_data && draftData?.mp_order_data
                                .map((project, index) => (
                                    <tr key={index}>
                                        <td className='table_border'>{index + 1}</td>
                                        <td className='table_border fw-bold'>{project?.mp_order?.solution?.name}</td>
                                        <td className='table_border'> {project?.mp_order?.created_at
                                            ? formatDate(project?.mp_order?.created_at)
                                            : '---'}</td>
                                        <td className='table_border'> {project?.mp_order?.modified_at
                                            ? formatDate(project?.mp_order?.modified_at)
                                            : '---'}</td>
                                        <td className='table_border' onClick={() => handledraftdata(project)} style={{ color: "blue", cursor: "pointer" }}>Click to Continue Purchase</td>
                                    </tr>
                                )) : 
                                <tr>
                                     <td colSpan="9" style={{color:"red"}}>No Data found...</td>
                                </tr>
                                }
                        </tbody>
                    </Table>
                    </div>
                }

            </div> */}
        </div>
    )
}
