import React, { useState } from 'react';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import "../styles/homeBanner.css";

const HomeBanner = () => {
	const [modalShow, setModalShow] = useState(false);
	return (
		<div>
			<section className='home-banner-area'>
				<div className='home-banner-content-box'>
					<Container fluid className='home-banner-content'>
						<Row>
							<Col md='12'>
								<div className='hero-box'>
									<h1>Get Real-time Store Insights</h1>
									<p> A plug and play platform for research and audit solutions. With over 5,00,000+ field force present across the nation and 22+ countries. We get insights for you from on ground in a giffy. On-ground sureys and retail audits have never been easier!</p>

									<Col md='12' className='banner-btn-container'>
										<div className='banner-btn' style={{textAlign:"left"}}>
											<Link to="/exploremarketplace"
												style={{ textDecoration: "none" }}
											// className="btn mb-5 mt-3 btn-md text-center btn-size" style={{background:"#007DC1",color:"white"}}
											>Explore MarketPlace</Link>
										</div>
										<div className='banner-btn'>
											<button
												style={{
													border: 'none',
													outline: 'none',
												}}
												onClick={() =>
													setModalShow(
														true,
													)
												}>
												Talk To An Expert
											</button>
										</div>
									</Col>
								</div>
							</Col>
						</Row>
					</Container>
					<Modal
						show={modalShow}
						centered
						size='lg'
						style={{ marginTop: '40px' }}
						scrollable={true}
						className='modal-dialog-scrollable p-6 modal_mobile'
						onHide={() => setModalShow(false)}>
						<div style={{ position: "absolute", right: "2rem", top: ".8rem", cursor: "pointer", }}
							onClick={() => setModalShow(false)}
						><i className="fa-solid fa-x"></i></div>
						<iframe
							frameBorder='0'
							style={{
								height: '500px',
								width: '99%',
								border: 'none',
							}}
							src='https://forms.zohopublic.com/floorwalk/form/MysteryShoppingServices/formperma/xW3CS_zHKW2DcCLHVZUplXsC1wLR_fITFv3kCFrBY9U?zf_rszfm=1'></iframe>
					</Modal>
				</div>
			</section>
		</div>
	);
};

export default HomeBanner;
