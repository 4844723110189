import React, { useEffect, useState } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import shotcard from "../../../images/shotcard.jpg";
import shotcenter from "../../../images/shotcenter.jpg";
import shotside1 from "../../../images/shotside1.png";
import shotside2 from "../../../images/shotside2.png";
import piaggiocard from "../../../images/piaggiocard.png";
import piaggiocenter from "../../../images/piaggiocenter.jpg";
import piaggioside1 from "../../../images/piaggioside1.png";
import piaggioside2 from "../../../images/piaggioside2.png";
import CommonCards from './CommonCards';


export default function Piaggio() {
    const [isReadMoreApproach, setIsReadMoreApproach] = useState(false);
    const [isReadMoreOutcome, setIsReadMoreOutcome] = useState(false);
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, []);

    return (
        <div>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: "3rem", marginTop: "1rem" }}>
                <img src={piaggiocard} alt="piaggio_logo" style={{width:"200px"}}/>
                <div className='lead manyavar_heading_text'>
                    <span>Piaggio & C. SpA is an Italian motor vehicle manufacturer, which produces a range of two-wheeled motor vehicles and compact commercial vehicles under four brands: Piaggio, Vespa, Aprilia and Moto Guzzi.</span>
                </div>
                <h3 className='lead manyavar_heading'>Piaggio opted for sales process audits to check the product demo and product pitch by the staff which in turn would help to assess staff knowledge </h3>
            </div>
            <div className='container'>
                <div className='row row-cols-1 row-cols-md-3'>
                    <div className='col-4'>
                        <div style={{ marginRight: "-20px" }}>
                            <img src={piaggioside1} alt="" style={{ width: "100%", height: "100%" }} className='card-img-top img-float' />
                        </div>
                    </div>
                    <div className='col-4'>
                        <div style={{ marginLeft: "-30px", marginTop: "-16px" }}>
                            <img src={piaggiocenter} alt="" style={{ width: "100%", height: "100%" }} className='card-img-top img-float' />
                        </div>
                    </div>
                    <div className='col-4'>
                        <div style={{ marginLeft: "-24px" }}>
                            <img src={piaggioside2} alt="" style={{ width: "100%", height: "100%" }} className='card-img-top img-float' />
                        </div>
                    </div>
                </div>
            </div>
            <div className='manyavar_heading_next_box'>
                <div className='row row-cols-1 row-cols-lg-3 g-5'>
                    <div className='col-lg-4'>
                        <div className='card h-100' style={{ borderRadius: "20px" }}>
                            <div className='card-body shadow' style={{ borderRadius: "20px" }}>
                                <h3 className='card-title mt-sm-4 text-center' style={{ color: "#007DC1" }}>Objective </h3>
                                <p className='card-text mt-sm-4' style={{ padding: "0rem 1rem 1rem 1rem" }}>
                                <b> 1.</b> The main purpose of the audit is to check the sales intent of the representative regarding the said 2 wheeler model  <br />
                                    <b> 2.</b> The main objective of this activity was to gauge the sales performance or sales pitch by the staff for Piaggio model Aprilia SXR 160  <br />
                                    <b>3. </b>They decieded to address the objective by opting fot the mystery shopping services through FloorWalk auditors who checked thier end to end sales process by walking into the showroom <br />
                                </p>

                            </div>

                        </div>

                    </div>
                    <div className='col-lg-4'>
                        <div className='card h-100' style={{ borderRadius: "20px" }}>
                            <div className='card-body shadow' style={{ borderRadius: "20px" }}>
                                <h3 className='card-title mt-sm-4 text-center' style={{ color: "#007DC1" }}>Approach </h3>
                                <p className='card-text mt-sm-4' style={{ padding: "0rem 1rem 1rem 1rem" }}>
                                    <b> 1.</b> The auditor's role involved assuming the persona of a prospective two-wheeler buyer. The objective was to evaluate the salesperson's ability to comprehend the buyer's needs and preferences. This included assessing how effectively the salesperson presented and explained the product's features and addressed any inquiries. Additionally, the auditor was tasked with providing insights into the test drive experience. <br />Furthermore, the mystery shopper was <br />
 {/* responsible for gathering information about payment terms and the available payment methods. An essential aspect of the assessment was evaluating the staff's negotiation skills, particularly in their willingness to offer discounts when requested. The comprehensive evaluation aimed to provide a positive and informative perspective on the entire customer experience. <br />
                                    <b> 2.</b> Working proffestionals were used aged between 21 to 30 who acted as a potential buyer and were also bike enthusiasts    <br /> */}
                                    {isReadMoreApproach ? 
                                 <><span> 
                                    responsible for gathering information about payment terms and the available payment methods. An essential aspect of the assessment was evaluating the staff's negotiation skills, particularly in their willingness to offer discounts when requested. The comprehensive evaluation aimed to provide a positive and informative perspective on the entire customer experience. <br />
                                    <b> 2.</b> Working proffestionals were used aged between 21 to 30 who acted as a potential buyer and were also bike enthusiasts    <br /> </span>
                                   <span onClick={()=>setIsReadMoreApproach(false)} style={{ color: "blue", cursor: "pointer", fontSize: "14px", 
                                   textDecoration:"underline",textUnderlineOffset:"5px",
                                   position:"absolute",right:"3rem",
                                   paddingTop:".5rem" }}>Read Less</span> <br /></> 
                                   :
                                 <>  <span onClick={()=>setIsReadMoreApproach(true)} style={{ color: "blue", cursor: "pointer", fontSize: "14px", 
                                    textDecoration:"underline",textUnderlineOffset:"5px",
                                    position:"absolute",right:"3rem",
                                    paddingTop:".5rem" }}>Read More</span> <br />
                                    </>
                                }
                                </p>

                            </div>

                        </div>

                    </div>
                    <div className='col-lg-4'>
                        <div className='card h-100' style={{ borderRadius: "20px" }}>
                            <div className='card-body shadow' style={{ borderRadius: "20px" }}>
                                <h3 className='card-title mt-sm-4 text-center' style={{ color: "#007DC1" }}>Outcome</h3>
                                <p className='card-text mt-sm-4' style={{ padding: "0rem 1rem 1rem 1rem" }}>
                                    <b>  1.</b> The sales representative missed opportunities to provide additional services, such as home test drives or family test drives, and failed to initiate further discussions. They did not present lease or exchange options to the client, nor did they offer a test drive of the vehicle. Additionally, they did not adequately explain available offers and discount schemes, and the client's inquiries regarding deals and offers were not sufficiently addressed.       <br />
                                    <b> 2.</b> Using such auditors helped to create a <br />
                                    {/* genuine lead and amatched with target customers of the brand   <br />
                                    <b> 3. </b>The sales pitch was not up to the mark and the staff was not very persuasive. Areas of improvement were observed and training were given to the sales staff post the mystery shopping was done  */}

                                    {isReadMoreOutcome ?
                                        <><span>
                                              genuine lead and amatched with target customers of the brand   <br />
                                    <b> 3. </b>The sales pitch was not up to the mark and the staff was not very persuasive. Areas of improvement were observed and training were given to the sales staff post the mystery shopping was done </span> <br />
                                            <span onClick={() => setIsReadMoreOutcome(false)} style={{
                                                color: "blue", cursor: "pointer", fontSize: "14px",
                                                textDecoration: "underline", textUnderlineOffset: "5px",
                                                position: "absolute", right: "3rem",
                                                paddingTop: ".5rem"
                                            }}>Read Less</span> <br /></>
                                        :
                                        <> <span onClick={() => setIsReadMoreOutcome(true)} style={{
                                            color: "blue", cursor: "pointer", fontSize: "14px",
                                            textDecoration: "underline", textUnderlineOffset: "5px",
                                            position: "absolute", right: "3rem",
                                            paddingTop: ".5rem"
                                        }}>Read More</span> <br />
                                        </>
                                    }
                                </p>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
           <CommonCards/>
        </div>
    )
}
