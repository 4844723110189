import React, { useState } from 'react';
import { Row, Col, Modal } from "react-bootstrap";
import "../styles/individualcategoryspecial.css";
import { Link } from 'react-router-dom';

export default function IndividualCategorySpecial() {
    const [modalShow, setModalShow] = useState(false);
    return (
        <div>
            <div className='categoryname_banner'>
                <h1>Category Name</h1>
            </div>
            <div className='special_category_container'>
                <Col md="3" className='customer_reviews_sidebar'>
                    <div className='online_customer_reviews_box'>
                        <h2>Online Customer Reviews</h2>
                        <div className='online_customer_reviews'>
                            <i class="fa-sharp fa-solid fa-circle-check"></i>
                            <span>what You'll Get - Point 1</span>
                        </div>
                        <div className='online_customer_reviews'>
                            <i className="fa-sharp fa-solid fa-circle-check"></i>
                            <span>what You'll Get - Point 1</span>
                        </div>
                        <div className='online_customer_reviews'>
                            <i className="fa-sharp fa-solid fa-circle-check"></i>
                            <span>what You'll Get - Point 1</span>
                        </div>
                        <div className='online_customer_reviews'>
                            <i className="fa-sharp fa-solid fa-circle-check"></i>
                            <span>what You'll Get - Point 1</span>
                        </div>
                        <div className='online_customer_reviews'>
                            <i className="fa-sharp fa-solid fa-circle-check"></i>
                            <span>what You'll Get - Point 1</span>
                        </div>
                    </div>
                </Col>
                <Col md="9">
                    <div className='online_product_container_main'>
                        <h2>Online Products</h2>
                        <div className='online_product_container'>
                            <Col md="4" className='category_product_box mt-5'>
                                <div className='category_name'>
                                    Category Name
                                </div>
                                <div className='category_product_box_img' style={{ background: "url(https://marketplace.floorwalk.in/media/homepage/blogs/anytimehome.png)center/cover" }}>
                                    <div className='categoty_price'>
                                        Price /Response
                                    </div>
                                </div>
                                <div className='category_product_box_text'>
                                    <h5>Product Heading</h5>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                                </div>
                                <div className='category_product_btn_container'>
                                    <Link to="/individualproduct">
                                        <i className="fa-solid fa-arrow-right"></i>
                                        <span>Buy Now</span>
                                    </Link>
                                </div>
                            </Col>
                            <Col md="4" className='category_product_box mt-5'>
                                <div className='category_name'>
                                    Category Name
                                </div>
                                <div className='category_product_box_img' style={{ background: "url(https://marketplace.floorwalk.in/media/homepage/blogs/anytimehome.png)center/cover" }}>
                                    <div className='categoty_price'>
                                        Price /Response
                                    </div>
                                </div>
                                <div className='category_product_box_text'>
                                    <h5>Product Heading</h5>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                                </div>
                                <div className='category_product_btn_container'>
                                    <Link to="/individualproduct">
                                        <i className="fa-solid fa-arrow-right"></i>
                                        <span>Buy Now</span>
                                    </Link>
                                </div>
                            </Col>
                            <Col md="4" className='category_product_box mt-5'>
                                <div className='category_name'>
                                    Category Name
                                </div>
                                <div className='category_product_box_img' style={{ background: "url(https://marketplace.floorwalk.in/media/homepage/blogs/anytimehome.png)center/cover" }}>
                                    <div className='categoty_price'>
                                        Price /Response
                                    </div>
                                </div>
                                <div className='category_product_box_text'>
                                    <h5>Product Heading</h5>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                                </div>
                                <div className='category_product_btn_container'>
                                    <Link to="/individualproduct">
                                        <i className="fa-solid fa-arrow-right"></i>
                                        <span>Buy Now</span>
                                    </Link>
                                </div>
                            </Col>
                            <Col md="4" className='category_product_box mt-5'>
                                <div className='category_name'>
                                    Category Name
                                </div>
                                <div className='category_product_box_img' style={{ background: "url(https://marketplace.floorwalk.in/media/homepage/blogs/anytimehome.png)center/cover" }}>
                                    <div className='categoty_price'>
                                        Price /Response
                                    </div>
                                </div>
                                <div className='category_product_box_text'>
                                    <h5>Product Heading</h5>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                                </div>
                                <div className='category_product_btn_container'>
                                    <Link to="/individualproduct">
                                        <i className="fa-solid fa-arrow-right"></i>
                                        <span>Buy Now</span>
                                    </Link>
                                </div>
                            </Col>
                            <Col md="4" className='category_product_box mt-5'>
                                <div className='category_name'>
                                    Category Name
                                </div>
                                <div className='category_product_box_img' style={{ background: "url(https://marketplace.floorwalk.in/media/homepage/blogs/anytimehome.png)center/cover" }}>
                                    <div className='categoty_price'>
                                        Price /Response
                                    </div>
                                </div>
                                <div className='category_product_box_text'>
                                    <h5>Product Heading</h5>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                                </div>
                                <div className='category_product_btn_container'>
                                    <Link to="/individualproduct">
                                        <i className="fa-solid fa-arrow-right"></i>
                                        <span>Buy Now</span>
                                    </Link>
                                </div>
                            </Col>
                            <Col md="4" className='category_product_box mt-5'>
                                <div className='category_name'>
                                    Category Name
                                </div>
                                <div className='category_product_box_img' style={{ background: "url(https://marketplace.floorwalk.in/media/homepage/blogs/anytimehome.png)center/cover" }}>
                                    <div className='categoty_price'>
                                        Price /Response
                                    </div>
                                </div>
                                <div className='category_product_box_text'>
                                    <h5>Product Heading</h5>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                                </div>
                                <div className='category_product_btn_container'>
                                    <Link to="/individualproduct">
                                        <i className="fa-solid fa-arrow-right"></i>
                                        <span>Buy Now</span>
                                    </Link>
                                </div>
                            </Col>
                            <Col md="4" className='category_product_box mt-5'>
                                <div className='category_name'>
                                    Category Name
                                </div>
                                <div className='category_product_box_img' style={{ background: "url(https://marketplace.floorwalk.in/media/homepage/blogs/anytimehome.png)center/cover" }}>
                                    <div className='categoty_price'>
                                        Price /Response
                                    </div>
                                </div>
                                <div className='category_product_box_text'>
                                    <h5>Product Heading</h5>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                                </div>
                                <div className='category_product_btn_container'>
                                    <Link to="/individualproduct">
                                        <i className="fa-solid fa-arrow-right"></i>
                                        <span>Buy Now</span>
                                    </Link>
                                </div>
                            </Col>
                            <Col md="4" className='category_product_box mt-5'>
                                <div className='category_name'>
                                    Category Name
                                </div>
                                <div className='category_product_box_img' style={{ background: "url(https://marketplace.floorwalk.in/media/homepage/blogs/anytimehome.png)center/cover" }}>
                                    <div className='categoty_price'>
                                        Price /Response
                                    </div>
                                </div>
                                <div className='category_product_box_text'>
                                    <h5>Product Heading</h5>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                                </div>
                                <div className='category_product_btn_container'>
                                    <Link to="/individualproduct">
                                        <i className="fa-solid fa-arrow-right"></i>
                                        <span>Buy Now</span>
                                    </Link>
                                </div>
                            </Col>
                            <Col md="4" className='category_product_box mt-5'>
                                <div className='category_name'>
                                    Category Name
                                </div>
                                <div className='category_product_box_img' style={{ background: "url(https://marketplace.floorwalk.in/media/homepage/blogs/anytimehome.png)center/cover" }}>
                                    <div className='categoty_price'>
                                        Price /Response
                                    </div>
                                </div>
                                <div className='category_product_box_text'>
                                    <h5>Product Heading</h5>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                                </div>
                                <div className='category_product_btn_container'>
                                    <Link to="/individualproduct">
                                        <i className="fa-solid fa-arrow-right"></i>
                                        <span>Buy Now</span>
                                    </Link>
                                </div>
                            </Col>

                        </div>
                    </div>
                </Col>
            </div>

            <div className='special_category_container'>
                <Col md="3">
                    <div className='offline_customer_reviews_box'>
                        <h2>Offline Customer Reviews</h2>
                        <div className='online_customer_reviews'>
                            <i className="fa-sharp fa-solid fa-circle-check"></i>
                            <span>what You'll Get - Point 1</span>
                        </div>
                        <div className='online_customer_reviews'>
                            <i className="fa-sharp fa-solid fa-circle-check"></i>
                            <span>what You'll Get - Point 1</span>
                        </div>
                        <div className='online_customer_reviews'>
                            <i className="fa-sharp fa-solid fa-circle-check"></i>
                            <span>what You'll Get - Point 1</span>
                        </div>
                        <div className='online_customer_reviews'>
                            <i className="fa-sharp fa-solid fa-circle-check"></i>
                            <span>what You'll Get - Point 1</span>
                        </div>
                        <div className='online_customer_reviews'>
                            <i className="fa-sharp fa-solid fa-circle-check"></i>
                            <span>what You'll Get - Point 1</span>
                        </div>
                    </div>
                </Col>
                <Col md="9">
                    <div className='offline_product_container_main'>
                        <h2>Offline Products</h2>
                        <div className='offline_product_container'>
                            <Col md="4" className='category_product_box mt-5'>
                                <div className='category_name'>
                                    Category Name
                                </div>
                                <div className='category_product_box_img' style={{ background: "url(https://marketplace.floorwalk.in/media/homepage/blogs/anytimehome.png)center/cover" }}>
                                    <div className='categoty_price'>
                                        Price /Response
                                    </div>
                                </div>
                                <div className='category_product_box_text'>
                                    <h5>Product Heading</h5>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                                </div>
                                <div className='category_product_btn_container'>
                                    <Link to="/individualproduct">
                                        <i class="fa-solid fa-arrow-right"></i>
                                        <span>Buy Now</span>
                                    </Link>
                                </div>
                            </Col>
                            <Col md="4" className='category_product_box mt-5'>
                                <div className='category_name'>
                                    Category Name
                                </div>
                                <div className='category_product_box_img' style={{ background: "url(https://marketplace.floorwalk.in/media/homepage/blogs/anytimehome.png)center/cover" }}>
                                    <div className='categoty_price'>
                                        Price /Response
                                    </div>
                                </div>
                                <div className='category_product_box_text'>
                                    <h5>Product Heading</h5>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                                </div>
                                <div className='category_product_btn_container'>
                                    <Link to="/individualproduct">
                                        <i className="fa-solid fa-arrow-right"></i>
                                        <span>Buy Now</span>
                                    </Link>
                                </div>
                            </Col>
                            <Col md="4" className='category_product_box mt-5'>
                                <div className='category_name'>
                                    Category Name
                                </div>
                                <div className='category_product_box_img' style={{ background: "url(https://marketplace.floorwalk.in/media/homepage/blogs/anytimehome.png)center/cover" }}>
                                    <div className='categoty_price'>
                                        Price /Response
                                    </div>
                                </div>
                                <div className='category_product_box_text'>
                                    <h5>Product Heading</h5>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                                </div>
                                <div className='category_product_btn_container'>
                                    <Link to="/individualproduct">
                                        <i className="fa-solid fa-arrow-right"></i>
                                        <span>Buy Now</span>
                                    </Link>
                                </div>
                            </Col>

                        </div>
                    </div>
                </Col>
            </div>
            <div className='special_category_lowerpart'>
                <div className='special_category_tellus'>
                    <div>
                        <p>Want a personalized solution? Talk to us and tell us what you want <i class="fa-solid fa-arrow-right"></i></p>
                    </div>
                    <div>
                        <button
                            className="btn btn-md text-center btn-size special_category_tellus_btn"
                            onClick={() =>
                                setModalShow(
                                    true,
                                )
                            }>
                            DROP YOUR ENQUIRY
                        </button>
                    </div>
                </div>
                <div className='special_category_tellus'>
                    <div>
                        <p>Search Products based on Solutions <i class="fa-solid fa-arrow-right"></i></p>
                    </div>
                    <div>
                        <Link to="/solutionpage">
                            <button
                                className="btn btn-md text-center btn-size special_category_tellus_btn"
                            >
                                Solution Page
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
            <Modal
                show={modalShow}
                centered
                size='lg'
                style={{ marginTop: '40px' }}
                scrollable={true}
                className='modal-dialog-scrollable p-6'
                onHide={() => setModalShow(false)}>
                <iframe
                    frameBorder='0'
                    style={{
                        height: '500px',
                        width: '99%',
                        border: 'none',
                    }}
                    src='https://forms.zohopublic.com/floorwalk/form/JobApplication/formperma/DnLJhIYypMsuI8sq_ztELdF2DZCatveMN0vXWiWSsJk'></iframe>
            </Modal>
        </div>
    )
}
