import base_url from "../config";

const token = localStorage.getItem("token");
const existingUser = localStorage.getItem("user_id");
const existingClient = localStorage.getItem("existingClient");
// const orderId = localStorage.getItem('orderId');

export function postStoreData(requestData, url) {
    const token = localStorage.getItem("token");
    return (
        fetch(base_url + url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
            body: JSON.stringify(requestData)
        })
    );
}

export function updateStoreData(requestData, url) {
    const token = localStorage.getItem("token");
    return (
        fetch(base_url + url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
            body: JSON.stringify(requestData)
        })
    );
}
export function deleteStoreData(url) {
    const token = localStorage.getItem("token");
    return (
        fetch(base_url + url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        })
    );
}

export function postSaveForLaterData(requestData, url) {
    const token = localStorage.getItem("token");
    return (
        fetch(base_url + url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            },
            body: JSON.stringify(requestData)
        })
    );
}

export function postOrderCreateData(requestData, url) {
    const token = localStorage.getItem("token");
    return (
        fetch(base_url + url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
            body: JSON.stringify(requestData)
        })
    );
}
export function postOrderCompleteData(requestData, url) {
    const token = localStorage.getItem("token");
    return (
        fetch(base_url + url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
            body: JSON.stringify(requestData)
        })
    );
}
export function postOrderFailData(requestData, url) {
    const token = localStorage.getItem("token");
    return (
        fetch(base_url + url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
            body: JSON.stringify(requestData)
        })
    );
}
export function postClientProfileData(requestData) {
    const token = localStorage.getItem("token");
    return (
        fetch(base_url + "client/client_profile", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
            body: JSON.stringify(requestData)
        })
    );
}
export function postResetPasswordData(requestData, url) {
    const token = localStorage.getItem("token");
    return (
        fetch(base_url + url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
            body: JSON.stringify(requestData)
        })
    );
}
export function postChangePasswordDashboard(requestData, url) {
    const token = localStorage.getItem("token");
    return (
        fetch(base_url + url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
            body: JSON.stringify(requestData)
        })
    );
}

export function postUploadStoreData(file, existingClient) {
    const token = localStorage.getItem("token");
    var formData = new FormData();
    formData.append("client_id", existingClient);
    formData.append("file_uploaded", file);

    return (
        fetch(base_url + `client/mp/${existingClient}/store/import_list`, {
            method: 'POST',
            headers: {
                'Authorization': `Token ${token}`
            },
            body: formData
        })
    );
}


const completeFileAttachment = async (attachmentId,orderId) => {
    const token = localStorage.getItem("token");
    try {
        const response = await fetch(`${base_url}client/mp_order_attachment/${attachmentId}/complete`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
            body: JSON.stringify(orderId),
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json(); 

        return data; 
    } catch (error) {
        console.error('Error in completeCategoryAttachment:', error);
        throw error; 
    }
};


const doFileAttachmentUpload = async (url, file,orderId) => {
    const token = localStorage.getItem("token");
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': `Token ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                file_name: file.name,
                file_size: file.size,
                file_type: file.type,
            }),
        });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const post_data = await response.json();

        const formData = new FormData();
        formData.append("x-amz-credential", post_data.fields["x-amz-credential"]);
        formData.append("x-amz-algorithm", post_data.fields["x-amz-algorithm"]);
        formData.append("x-amz-date", post_data.fields["x-amz-date"]);
        formData.append("x-amz-signature", post_data.fields["x-amz-signature"]);
        formData.append("acl", post_data.fields.acl);
        formData.append("policy", post_data.fields.policy);
        formData.append("key", post_data.fields.key);
        formData.append("success_action_status", "201");
        formData.append('file', file);

        const uploadResponse = await fetch(post_data.url, {
            method: 'POST',
            body: formData,
        });

        if (!uploadResponse.ok) {
            throw new Error(`HTTP error! Status: ${uploadResponse.status}`);
        }

        const attachmentId = post_data.attachment.id;
        const completeResponse = await completeFileAttachment(attachmentId,orderId);
        return completeResponse; 
    } catch (error) {
        console.error('Error in doCategoryAttachmentUpload:', error);
        throw error; 
    }
};

export async function postUploadFileData(file,orderId) {
    var req_url = base_url + `client/mp_order/${orderId}/attachment`;
    try {
        const response = await doFileAttachmentUpload(req_url, file,orderId);
        return response; 
    } catch (error) {
        console.error('Error in postUploadFileData:', error);
        throw error;
    }
}

export function deleteFileData(url,orderId) {
    const token = localStorage.getItem("token");
    return (
        fetch(base_url + url, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
            body: JSON.stringify(orderId),
        })
    );
}


