import React, { useState, useEffect } from 'react';
import { Col, Table, Modal, Row, Alert } from 'react-bootstrap';
import "../styles/auditprojectsetup2.css";
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import base_url from '../utils/config';
import { useDispatch, useSelector } from 'react-redux';
import { setAuditCountData, setAuditCountDatawithId, removeStoreAuditCount } from '../utils/orderdataslice';
import { postData, postStoreData, deleteStoreData, updateStoreData } from '../utils/services/PostdataHandle';
import { setDraftData } from '../utils/draftDataSlice';
import loading from "../images/loading.avif";

export default function AuditProjectSetup2({ updateStep, currentStep }) {
	const location = useLocation();
	const id = location.state;
	const navigate = useNavigate();
	const existingUser = (localStorage.getItem('user_id'));
	const existingClient = localStorage.getItem("client_id");
	const auditcountReduxwithId = useSelector((store) => store.orderdata.secondPage.totalAuditsPerStoreWithStoreId)
	const [storeUrl, setStoreUrl] = useState(base_url + `client/mp/store_mp_get`)
	const cartItems = useSelector((store) => store.cart);
	const dispatch = useDispatch();
	const [storeModalShow, setStoreModalShow] = useState(false);
	const [modalShow, setModalShow] = useState(false);
	const [responsesLeft, setResponsesLeft] = useState(cartItems.totalQuantity);
	const [storesList, setStoresList] = useState([]);
	const [fetchedStores, setFetchedStores] = useState([]);
	const [countryData, setCountryData] = useState([]);
	const [stateData, setStateData] = useState([]);
	const [cityData, setCityData] = useState([]);
	const [audit_count, setAudit_count] = useState(auditcountReduxwithId || {});
	const [auditcountAlert, setAuditcountAlert] = useState(false);
	const [auditCountMoreAlert, setAuditCountMoreAlert] = useState(false);
	const [formSubmitted, setFormSubmitted] = useState(false);
	const [loadingData, setLoadingData] = useState(false);
	const [loadingButton, setloadingButton] = useState(false);
	const [searchStoreCount, setSearchStoreCount] = useState(0);
	const [formData, setFormData] = useState({
		country:'',
		state: '',
		city: '',
		pincode: '',
		name: '',
		address: '',
		phone: '',
		map_location_link: '',
	});
	const [errorMessages, setErrorMessages] = useState({
		countryError: '',
		stateError: '',
		cityError: '',
		pincodeError: '',
		nameError: '',
		audit_countError: '',
		addressError: '',
		phoneError: '',
		map_location_linkError: '',
	});
	const [errorMessage, setErrorMessage] = useState("");
	const [hasError, setHasError] = useState(false);
	const [hasResponseError, setHasResponseError] = useState(false);
	const [showAddAlert, setShowAddAlert] = useState(false);
	const [showEditAlert, setShowEditAlert] = useState(false);
	const [showDeleteAlert, setShowDeleteAlert] = useState(false);
	const [showDeleteFailAlert, setShowDeleteFailAlert] = useState(false);
	const [selectedState, setSelectedState] = useState('');
	const [selectedCountry, setSelectedCountry] = useState('');
	const [selectedCity, setSelectedCity] = useState('');
	const [selectedStoreId, setSelectedStoreId] = useState(null);
	const [storeCounts, setStoreCounts] = useState({});
	const [draftProduct, setDraftProduct] = useState([]);
	const [nextScroll, setNextScroll] = useState(false);
	const [deletAuditCount, setDeleteAuditCount] = useState(false);
	const [searchFetchedCountryData, setSearchFetchedCountryData] = useState({});
	const [searchFetchedData, setSearchFetchedData] = useState({});
	const [searchFetchedCityData, setSearchFetchedCityData] = useState({});
	const [searchNamenotFound, setSearchNameNotFound] = useState("")
	//------------------------------------AUDIT COUNT VALIDATION----------------------------------
	useEffect(() => {
		let totalAudits = 0;
		Object.values(auditcountReduxwithId).forEach(item => {
			totalAudits += +item;
		})
		if (totalAudits > cartItems.totalQuantity) {
			setResponsesLeft(0);
		} else {
			setResponsesLeft(cartItems.totalQuantity - totalAudits)
		}
		const remainingResponses = cartItems.totalQuantity - totalAudits;
		{ remainingResponses >= 0 && setResponsesLeft(remainingResponses) };

		if (remainingResponses < 0) {
			setAuditCountMoreAlert(true);
			setHasResponseError(true);
			// setTimeout(() => {
			// 	setAuditCountMoreAlert(false);
			// 	setHasResponseError(false);
			// }, 1700)
		}
	}, [audit_count, deletAuditCount])


	const handleAuditCountChange = (e, id) => {
		const { value } = e.target;
		setAuditcountAlert(false);
		setAuditCountMoreAlert(false);
		setHasResponseError(false);
		setAudit_count(prevAuditCounts => ({
			...prevAuditCounts,
			[id]: value,
		}));
		dispatch(setAuditCountDatawithId({ totalAuditsPerStoreWithStoreId: { ...audit_count, [id]: value } }));

	};


	//------------------------------ON MOUNT GETTING STATE AND STORES DETAILS TO SHOW INCLUDING DRAFT--------------------
	useEffect(() => {
		getcountryApiData();
		getApiData();
		getStoreData();
		const orderIdfromStorage = localStorage.getItem('orderId');
		if (orderIdfromStorage) {
			getIndividualDraftData(`client/mp_order/${orderIdfromStorage}`)
		}
		window.scrollTo({ top: 0, left: 0, behavior: "instant" });
		getSearchCityData(`client/mp/storecitylist`);
		getSearchStateData(`client/mp/storestatelist`);
	}, []);

	if (nextScroll) {
		window.scrollTo({ top: 0, left: 0, behavior: "instant" });
	}

	// useEffect(() => {
	// 	getSearchStateData(`client/mp/storestatelist`);

	// }, [fetchedStores])

	useEffect(() => {
		getSearchCountryData(`client/mp/storecountrylist`);

	}, [fetchedStores])

	const getApiData = async () => {
		try {
			const response = await fetch(base_url + "manager/state");
			if (response.ok) {
				const result = await response.json();
				const stateDataArray = Object.entries(result).map(([stateCode, stateName]) => ({
					stateCode,
					stateName
				}));
				setStateData(stateDataArray);
			} else {
				console.error('API request failed:', response.status, response.statusText);
			}
		} catch (error) {
			console.error('An error occurred while fetching the API:', error);
		}
	};
	const getcountryApiData = async () => {
		try {
			const headers = {
				'Authorization': `Token ${token}`,
				'Content-Type': 'application/json'
			};

			const options = {
				method: 'GET',
				headers: headers
			};
			const response = await fetch(base_url + "manager/country", options);
			if (response.ok) {
				const result = await response.json();
				const countryDataArray = Object.entries(result).map(([countryCode, countryName]) => ({
					countryCode,
					countryName
				}));
				setCountryData(countryDataArray);
			} else {
				console.error('API request failed:', response.status, response.statusText);
			}
		} catch (error) {
			console.error('An error occurred while fetching the API:', error);
		}
	};
	const getIndividualDraftData = async (url) => {
		try {
			const headers = {
				'Authorization': `Token ${token}`,
				'Content-Type': 'application/json'
			};

			const options = {
				method: 'GET',
				headers: headers
			};

			const response = await fetch(base_url + url, options);

			if (response.ok) {
				const result = await response.json();
				setDraftProduct(result);
				if (result && result.store) {
					const updatedStoreCounts = {};
					result.store.forEach(storeData => {
						updatedStoreCounts[storeData.store_id] = storeData.count;
					});
					setStoreCounts(updatedStoreCounts);
				}

				if (result.error) {
					alert("error");
				}
			} else {
				console.error('API request failed:', response.status, response.statusText);
			}
		} catch (error) {
			console.error('An error occurred while fetching the API:', error);
		}
	};
	async function getSearchStateData(url) {
		try {
			const headers = {
				'Authorization': `Token ${token}`,
				'Content-Type': 'application/json'
			};

			const options = {
				method: 'GET',
				headers: headers
			};

			const response = await fetch(base_url + url, options);

			if (response.ok) {
				const result = await response.json();
				setSearchFetchedData(result);
				setSearchFetchedCityData(result);
				if (result.error) {
					alert("error");
				}
			} else {
				console.error('API request failed:', response.status, response.statusText);
			}
		} catch (error) {
			console.error('An error occurred while fetching the API:', error);
		}
	};
	async function getSearchCountryData(url) {
		try {
			const headers = {
				'Authorization': `Token ${token}`,
				'Content-Type': 'application/json'
			};

			const options = {
				method: 'GET',
				headers: headers
			};

			const response = await fetch(base_url + url, options);

			if (response.ok) {
				const result = await response.json();
				setSearchFetchedCountryData(result);
				// setSearchFetchedData(result);
				// setSearchFetchedCityData(result);
				if (result.error) {
					alert("error");
				}
			} else {
				console.error('API request failed:', response.status, response.statusText);
			}
		} catch (error) {
			console.error('An error occurred while fetching the API:', error);
		}
	};
	async function getSearchCityData(url) {
		try {
			const headers = {
				'Authorization': `Token ${token}`,
				'Content-Type': 'application/json'
			};

			const options = {
				method: 'GET',
				headers: headers
			};

			const response = await fetch(base_url + url, options);

			if (response.ok) {
				const result = await response.json();
				setSearchFetchedCityData(result);
				if (result.error) {
					alert("error");
				}
			} else {
				console.error('API request failed:', response.status, response.statusText);
			}
		} catch (error) {
			console.error('An error occurred while fetching the API:', error);
		}
	};
	//---------------------------------AFTER FORM SUBMISSION, GETTING THE STORE DATA-----------------

	useEffect(() => {
		if (formSubmitted) {
			getStoreData();
			setFormSubmitted(false);
			getSearchCityData(`client/mp/storecitylist`);
		}
	}, [formSubmitted]);

	const token = localStorage.getItem("token");

	const getStoreData = async () => {
		setLoadingData(true);
		try {
			const headers = {
				'Authorization': `Token ${token}`,
				'Content-Type': 'application/json'
			};

			const options = {
				method: 'GET',
				headers: headers
			};
			const response = await fetch(storeUrl, options);
			if (response.ok) {
				const result = await response.json();
				const sortedStores = result.sort((a, b) => a.id - b.id);
				setFetchedStores(sortedStores);
				// setFetchedStores(result);
			} else {
				console.error('API request failed:', response.status, response.statusText);
			}
		} catch (error) {
			console.error('An error occurred while fetching the API:', error);
		} finally {
			setLoadingData(false);
		}
	};

	// -------------------------------FETCHING STATES WITH COUNTRY CODE--------------------

	const fetchStateData = async (countryCode) => {
		try {
			const response = await fetch(`${base_url}manager/${countryCode}/state_by_country_id`);
			if (response.ok) {
				const result = await response.json();
				const stateDataArray = Object.entries(result).map(([stateCode, stateName]) => ({
					stateCode,
					stateName
				}));
				setStateData(stateDataArray)
				// return result;
			} else {
				console.error('API request failed:', response.status, response.statusText);
				return [];
			}
		} catch (error) {
			console.error('An error occurred while fetching state data:', error);
			return [];
		}
	};
	// -------------------------------FETCHING CITIES WITH STATE CODE--------------------

	const fetchCityData = async (stateCode) => {
		try {
			const response = await fetch(`${base_url}manager/city/${stateCode}`);
			if (response.ok) {
				const result = await response.json();
				return result;
			} else {
				console.error('API request failed:', response.status, response.statusText);
				return [];
			}
		} catch (error) {
			console.error('An error occurred while fetching city data:', error);
			return [];
		}
	};

	// -------------------------------------ONCHANGE FUNCTIONALITY-------------------------------------

	const handleChange = (e) => {
		const { name, value } = e.target;

		setFormData(prevState => ({
			...prevState,
			[name]: value,
		}));
		setErrorMessages(prevErrors => ({
			...prevErrors,
			[`${name}Error`]: ''
		}));

		if (name === 'state') {
			fetchCityData(value)
				.then((cities) => {
					setCityData(cities);
				})
				.catch((error) => {
					console.error('Error fetching city data:', error);
				});
		}
		if (name === 'country') {
			fetchStateData(value)
				.then((states) => {
					// setStateData(states);
					const stateDataArray = Object.entries(states).map(([stateCode, stateName]) => ({
						stateCode,
						stateName
					}));
					setStateData(stateDataArray)
				})
				.catch((error) => {
					console.error('Error fetching state data:', error);
				});
		}
	};

	//  ------------------------------------------FORM SUBMISSION ADD---------------------------

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!formData.name) {
			setErrorMessages(prevErrors => ({
				...prevErrors,
				nameError: "Store name can't be empty"
			}));
			setHasError(true)
			setTimeout(() => {
				setErrorMessages(prevErrors => ({
					...prevErrors,
					nameError: ""
				}));
				setHasError(false);
			}, 1500)
		}
		if (!formData.country) {
			setErrorMessages(prevErrors => ({
				...prevErrors,
				countryError: "Country can't be empty"
			}));
			setHasError(true)
			setTimeout(() => {
				setErrorMessages(prevErrors => ({
					...prevErrors,
					countryError: ""
				}));
				setHasError(false);
			}, 1500)
		}
		if (!formData.state) {
			setErrorMessages(prevErrors => ({
				...prevErrors,
				stateError: "State can't be empty"
			}));
			setHasError(true)
			setTimeout(() => {
				setErrorMessages(prevErrors => ({
					...prevErrors,
					stateError: ""
				}));
				setHasError(false);
			}, 1500)
		}
		if (!formData.city) {
			setErrorMessages(prevErrors => ({
				...prevErrors,
				cityError: "City can't be empty"
			}));
			setHasError(true)
			setTimeout(() => {
				setErrorMessages(prevErrors => ({
					...prevErrors,
					cityError: ""
				}));
				setHasError(false);
			}, 1500)
		}
		if (!formData.address) {
			setErrorMessages(prevErrors => ({
				...prevErrors,
				addressError: "Address can't be empty"
			}));
			setHasError(true)
			setTimeout(() => {
				setErrorMessages(prevErrors => ({
					...prevErrors,
					addressError: ""
				}));
				setHasError(false);
			}, 1500)
		}
		if (selectedStoreId === null && formData.country && formData.state && formData.city && formData.name && formData.address) {
			setloadingButton(true);
			const newStore = {
				...formData,
				audit_count: audit_count[storesList.length] || '',
			};
			setStoresList(prevStoresList => [...prevStoresList, newStore]);
			// setStoresList(prevStoresList => [newStore, ...prevStoresList]);
			setAudit_count(prevAuditCounts => ({
				...prevAuditCounts,
				[storesList.length]: '',
			}));
			setSelectedCountry(formData.country);
			setSelectedState(formData.state);
			setSelectedCity(formData.city);
			const requestData = {
				country: formData.country,
				state: formData.state,
				city: +formData.city,
				pincode: formData.pincode,
				name: formData.name,
				address: formData.address,
				phone: formData.phone,
				map_location_link: formData.map_location_link,
				client: existingClient
			};
			const promise = postStoreData(requestData, `client/mp/store_mp`);
			promise.then(response => response.json())
				.then(data => {
					setHasError(false);
					setShowAddAlert(true);
					setTimeout(() => {
						setShowAddAlert(false);
						setloadingButton(false);
					}, 1500)
					setErrorMessage(data);
					setFormData({
						country: '',
						state: '',
						city: '',
						pincode: '',
						name: '',
						address: '',
						phone: '',
						map_location_link: '',
					});
					setFormSubmitted(true);
				})
				.catch(error => {
					console.error('Error:', error);
				}).finally(() => {
					setloadingButton(false);
				});
			setStoreModalShow(false);
		}
	}



	const handleAddStore = () => {
		setSelectedStoreId(null);
		setFormData({
			country: '',
			state: '',
			city: '',
			pincode: '',
			name: '',
			address: '',
			phone: '',
			map_location_link: '',
		});
		setStoreModalShow(true);
	};

	// ------------------------------DELETE FUNCTIONALITY-----------------------------------

	const handleDeleteStore = (storeId) => {
		setDeleteAuditCount(false);

		const selectedStore = fetchedStores.find(store => store.id === storeId);
		if (selectedStore) {
			const promise = deleteStoreData(`client/mp/store_mp/${selectedStore.id}`);
			promise.then((response) => {
				if (response.status === 200 || response.status === 204) {
					const updatedFetchedStores = fetchedStores.filter(store => store.id !== selectedStore.id);
					setFetchedStores(updatedFetchedStores);
					getSearchCityData(`client/mp/storecitylist`);
					dispatch(removeStoreAuditCount({ storeId: storeId }));
					setDeleteAuditCount(true);
					setShowDeleteAlert(true);
					setTimeout(() => {
						setShowDeleteAlert(false);
					}, 1000)
				} else {
					setShowDeleteFailAlert(true);
					setTimeout(() => {
						setShowDeleteFailAlert(false);
					}, 1000)
				}
			}).catch((error) => {
				// alert("Deletion failed");
				setShowDeleteFailAlert(true);
				setTimeout(() => {
					setShowDeleteFailAlert(false);
				}, 1000)
			});
		}
	}


	// -------------------------------------EDIT FUNCTIONALITY------------------------------

	const handleEditStore = (store) => {
		setSelectedStoreId(store.id);
		getEditStoreData(store.id);
		setStoreModalShow(true);
	};

	const getEditStoreData = async (storeId) => {
		try {
			const headers = {
				'Authorization': `Token ${token}`,
				'Content-Type': 'application/json'
			};

			const options = {
				method: 'GET',
				headers: headers
			};
			const response = await fetch(base_url + `client/mp/store_mp/${storeId}`, options);
			if (response.ok) {
				const result = await response.json();
				getSearchCityData(`client/mp/storecitylist`);
				fetchCityData(result.city.state)
					.then((cities) => {
						setCityData(cities);
					})
					.catch((error) => {
						console.error('Error fetching city data:', error);
					});
				setFormData({
					country: result.city.country,
					state: result.city.state,
					city: result.city.id,
					pincode: result.pincode,
					name: result.name,
					address: result.address,
					phone: result.phone,
					map_location_link: result.map_location_link,
				});

			} else {
				console.error('API request failed:', response.status, response.statusText);
			}
		} catch (error) {
			console.error('An error occurred while fetching the API:', error);
		}
	};
	const handleUpdateStore = () => {
		setloadingButton(true)
		const requestData = {
			country: formData.country,
			state: formData.state,
			city: formData.city,
			pincode: formData.pincode,
			name: formData.name,
			address: formData.address,
			phone: formData.phone,
			map_location_link: formData.map_location_link,
			client: existingClient
		};

		const promise = updateStoreData(requestData, `client/mp/store_mp/${selectedStoreId}`); // Use the updateStoreData function
		promise.then(response => response.json())
			.then(data => {
				if (data) {
					setShowEditAlert(true);
					setTimeout(() => {
						setShowEditAlert(false);
						setloadingButton(false);
					}, 1500)
					setFormSubmitted(true);
				} else {
					alert("Not Editted")
				}
			})
			.catch(error => {
				console.error('Error:', error);
			}).finally(() => {
				setloadingButton(false);
			})

		setStoreModalShow(false);

	};

	//------------------NEXT BUTTON CLICK-------------------------------------

	const handleNextClick = () => {
		setNextScroll(true);
		let totalAudits = 0;
		Object.values(auditcountReduxwithId).forEach(item => {
			totalAudits += +item;
		})
		if (totalAudits === cartItems.totalQuantity) {
			setNextScroll(false);
			updateStep(currentStep + 1);
		} else {
			setAuditcountAlert(true);
			setHasResponseError(true)
			// setTimeout(() => {
			// 	setAuditcountAlert(false);
			// 	setHasResponseError(false);
			// }, 2000)
		}
	}
	//--------------------------------SEARCH FUNCTIONALITIES-----------------------------------

	// const handleSearchChange = (e) => {
	// 	const { name, value } = e.target;
	// 	setFormData(prevState => ({
	// 		...prevState,
	// 		[name]: value
	// 	}));
	// 	// if (value === "" && (name === 'state' || name === 'name')) {
	// 	// 	getSearchApiData(`client/mp/storesearch?status=ALL`);
	// 	// }
	// 	if (value === "" && (name === 'state')) {
	// 		getSearchApiData(`client/mp/storesearch?status=ALL`);
	// 	}
	// 	if (value !== "" && !formData.state && name === 'city') {
	// 		getSearchApiData(`client/mp/storesearch?city=${value}`);
	// 	}
	// 	if (value !== "" && formData.state && name === 'city') {
	// 		getSearchApiData(`client/mp/storesearch?state=${formData.state}&city=${value}`);
	// 	}
	// 	if (formData.state && value === "" && name === 'city') {
	// 		getSearchApiData(`client/mp/storesearch?state=${formData.state}`);
	// 	}
	// 	if (!formData.state && value === "" && name === 'city') {
	// 		getSearchApiData(`client/mp/storesearch?status=ALL`);
	// 	}
    //     // if( name === 'country'){

	// 	// }
	// 	if (name === 'state') {
	// 		getSearchApiData(`client/mp/storesearch?state=${value}`);
	// 		getSearchCityData(`client/mp/storestatelist?state_code=${value}`)
	// 	}

	// 	if (value !== "" && !formData.state && !formData.city && name === 'name') {
	// 		getSearchApiData(`client/mp/storesearch?name=${value}`);
	// 	}
	// 	if (value !== "" && formData.state && !formData.city && name === 'name') {
	// 		getSearchApiData(`client/mp/storesearch?state=${formData.state}&name=${value}`);
	// 	}
	// 	if (value !== "" && formData.city && !formData.state && name === 'name') {
	// 		getSearchApiData(`client/mp/storesearch?city=${formData.city}&name=${value}`);
	// 	}
	// 	if (value !== "" && formData.city && formData.state && name === 'name') {
	// 		getSearchApiData(`client/mp/storesearch?state=${formData.state}&city=${formData.city}&name=${value}`);
	// 	}
	// 	if (formData.state && !formData.city && value === "" && name === 'name') {
	// 		getSearchApiData(`client/mp/storesearch?state=${formData.state}`);
	// 	}
	// 	if (formData.city && !formData.state && value === "" && name === 'name') {
	// 		getSearchApiData(`client/mp/storesearch?city=${formData.city}`);
	// 	}
	// 	if (formData.city && formData.state && value === "" && name === 'name') {
	// 		getSearchApiData(`client/mp/storesearch?state=${formData.state}&city=${formData.city}`);
	// 	}
	// 	if (!formData.state && !formData.city && value === "" && name === 'name') {
	// 		getSearchApiData(`client/mp/storesearch?status=ALL`);
	// 	}
	// };

	const handleSearchChange = (e) => {
		const { name, value } = e.target;
		setFormData(prevState => ({
			...prevState,
			[name]: value
		}));
		// if (value === "" && (name === 'state' || name === 'name')) {
		// 	getSearchApiData(`client/mp/storesearch?status=ALL`);
		// }
		if (value === "" && (name === 'country')) {
			getSearchApiData(`client/mp/storesearch?status=ALL`);
		}
		if (value === "" && (name === 'state')) {
			getSearchApiData(`client/mp/storesearch?status=ALL`);
		}
		if (value !== "" && !formData.state && name === 'city') {
			getSearchApiData(`client/mp/storesearch?city=${value}`);
		}
		if (value !== "" && !formData.country && name === 'city') {
			getSearchApiData(`client/mp/storesearch?city=${value}`);
		}
		if (value !== "" && formData.state && name === 'city') {
			getSearchApiData(`client/mp/storesearch?state=${formData.state}&city=${value}`);
		}
		if (value !== "" && formData.country && name === 'city') {
			getSearchApiData(`client/mp/storesearch?country=${formData.country}&city=${value}`);
		}
		if (formData.state && value === "" && name === 'city') {
			getSearchApiData(`client/mp/storesearch?state=${formData.state}`);
		}
		if (formData.country && value === "" && name === 'city') {
			getSearchApiData(`client/mp/storesearch?state=${formData.country}`);
		}
		if (!formData.state && value === "" && name === 'city') {
			getSearchApiData(`client/mp/storesearch?status=ALL`);
		}
        if( name === 'country'){
			getSearchApiData(`client/mp/storesearch?country=${value}`);
			getSearchStateData(`client/mp/storecountrylist?country_code=${value}`)
			// getSearchCityData(`client/mp/storestatelist?country_code=${value}`)
		}
		if (name === 'state') {
			getSearchApiData(`client/mp/storesearch?state=${value}`);
			getSearchCityData(`client/mp/storestatelist?state_code=${value}`)
		}

		if (value !== "" && !formData.country && !formData.state && !formData.city && name === 'name') {
			getSearchApiData(`client/mp/storesearch?name=${value}`);
		}
		if (value !== "" && formData.country && !formData.city && name === 'name') {
			getSearchApiData(`client/mp/storesearch?country=${formData.country}&name=${value}`);
		}
		if (value !== "" && formData.country && formData.state && !formData.city && name === 'name') {
			getSearchApiData(`client/mp/storesearch?country=${formData.country}&state=${formData.state}&name=${value}`);
		}
		if (value !== "" && formData.country && !formData.state && formData.city && name === 'name') {
			getSearchApiData(`client/mp/storesearch?country=${formData.country}&city=${formData.city}&name=${value}`);
		}
		if (value !== "" && formData.state && !formData.city && name === 'name') {
			getSearchApiData(`client/mp/storesearch?state=${formData.state}&name=${value}`);
		}
		if (value !== "" && formData.city && !formData.state && name === 'name') {
			getSearchApiData(`client/mp/storesearch?city=${formData.city}&name=${value}`);
		}
		if (value !== "" && formData.city && formData.state && name === 'name') {
			getSearchApiData(`client/mp/storesearch?state=${formData.state}&city=${formData.city}&name=${value}`);
		}
		if (formData.state && !formData.city && value === "" && name === 'name') {
			getSearchApiData(`client/mp/storesearch?state=${formData.state}`);
		}
		if (formData.country && !formData.state && !formData.city && value === "" && name === 'name') {
			getSearchApiData(`client/mp/storesearch?country=${formData.country}`);
		}
		if (formData.city && !formData.state && value === "" && name === 'name') {
			getSearchApiData(`client/mp/storesearch?city=${formData.city}`);
		}
		if (formData.city && formData.state && value === "" && name === 'name') {
			getSearchApiData(`client/mp/storesearch?state=${formData.state}&city=${formData.city}`);
		}
		if (!formData.country && !formData.state && !formData.city && value === "" && name === 'name') {
			getSearchApiData(`client/mp/storesearch?status=ALL`);
		}
	};
	const getSearchApiData = async (url) => {
		setLoadingData(true);
		try {
			const headers = {
				'Authorization': `Token ${token}`,
				'Content-Type': 'application/json'
			};

			const options = {
				method: 'GET',
				headers: headers
			};

			const response = await fetch(base_url + url, options);

			if (response.status === 404) {
				const result = await response.json();
				if (result.error === "No records found for the given query parameters.") {
					setSearchNameNotFound(result.error);
					setTimeout(() => {
						setSearchNameNotFound("");
					}, 1800)
				}

			}

			if (response.ok) {
				const result = await response.json();
				if (result.error) {
					alert("error");
				}
				// setFetchedStores(result);
				const sortedStores = result?.data.sort((a, b) => a.id - b.id);
				setFetchedStores(sortedStores);
				setSearchStoreCount(result?.store_count);
			} else {
				console.error('API request failed:', response.status, response.statusText);
			}
		} catch (error) {
			console.error('An error occurred while fetching the API:', error);
		} finally {
			setLoadingData(false);
		};
	};
	return (
		<div>
			<div className='project_setup1_main_container'>
				{auditcountAlert && (<Alert variant="danger" onClose={() => setAuditcountAlert(false)} dismissible style={{ textAlign: "center", color: "red" }}>
					Total Responses do not match
				</Alert>)}
				{auditCountMoreAlert && (<Alert variant="danger" onClose={() => setAuditcountAlert(false)} dismissible style={{ textAlign: "center", color: "red" }}>
					You can not cross total responses
				</Alert>)}
				{
					showEditAlert && (<Alert variant="success" onClose={() => setShowEditAlert(false)} dismissible style={{ textAlign: "center" }}>
						Store is EDITTED successfully
					</Alert>)}
				{
					showAddAlert && (<Alert variant="success" onClose={() => setShowAddAlert(false)} dismissible style={{ textAlign: "center" }}>
						Store is ADDED successfully
					</Alert>)}
				{
					showDeleteAlert && (<Alert variant="success" onClose={() => setShowDeleteAlert(false)} dismissible style={{ textAlign: "center" }}>
						Store is DELETED successfully
					</Alert>)}
				{
					showDeleteFailAlert && (<Alert variant="danger" onClose={() => setShowDeleteAlert(false)} dismissible style={{ textAlign: "center" }}>
						Store is not DELETABLE now
					</Alert>)}
				<div className='project_setup1_container'>
					<div className='d-flex project_setup_storeadd'>
						<Col md="6">
							<h2>Tell us where to conduct these audits</h2>
						</Col>
						<Col md="4">
							<h5>Total Responses left: <b style={hasResponseError ? {color:"red"} : {}}>{responsesLeft}</b></h5>
						</Col>
						<Col md="2">
							<button className="btn btn-md text-center btn-size create_ac_btn"
								onClick={handleAddStore}
							>Add a Store</button>
						</Col>
						<Modal show={storeModalShow} centered size='lg' className='modal-dialog-scrollable p-6 modal_mobile' onHide={() => setStoreModalShow(false)} style={{zIndex:15000 , marginTop:"3rem"}}>
							<div style={{ width: '100%', padding: "2rem 3rem", border: 'none', position: "relative" }} >
								<div style={{ position: "absolute", right: "2rem", top: "2rem", cursor: "pointer" }} onClick={() => setStoreModalShow(false)}><i className="fa-solid fa-x"></i></div>
								<form onSubmit={handleSubmit}>
									<Col>
										<Row>
											<h5 className='setup_regs_modal_heading'>
												{selectedStoreId ? "Edit Store" : "Add a Store"}
											</h5>
										</Row>
										<Row>
											<Col md="3">
												<p className="registration_form_content">
													<label>Country<span>*</span></label>
													<select name='country' onChange={handleChange} className={hasError ? "error_border" : "success_border"} value={formData.country} >
														<option value="">Select</option>
														{countryData.map((item) => (
															<option key={item.countryCode} value={item.countryCode} >{item.countryName}</option>
														))}
													</select>
													{errorMessages.countryError && (
														<span className="error_message">{errorMessages.countryError}</span>
													)}
												</p>
											</Col>
											<Col md="3">
												<p className="registration_form_content">
													<label>State<span>*</span></label>
													<select name='state' onChange={handleChange} className={hasError ? "error_border" : "success_border"} value={formData.state} >
														<option value="">Select</option>
														{/* {stateData.map((item) => ( <option key={item.stateCode} value={item.stateCode} >{item.stateName}</option> ))} */}
														{Array.isArray(stateData) && stateData.map((item) => (
                    									<option key={item.stateCode} value={item.stateCode}>{item.stateName}</option> ))}
													</select>
													{errorMessages.stateError && (
														<span className="error_message">{errorMessages.stateError}</span>
													)}
												</p>
											</Col>
											<Col md="3">
												<p className="registration_form_content">
													<label>City<span>*</span></label>
													<select onChange={handleChange} className={hasError ? "error_border" : "success_border"} value={formData.city} name='city'>
														<option value="">Select</option>
														{cityData.map((city) => (
															<option key={city.id} value={city.id} > {city.name} </option>
														))}
													</select>
													{errorMessages.cityError && (
														<span className="error_message">{errorMessages.cityError}</span>
													)}
												</p>
											</Col>
											<Col md="3">
												<p className="registration_form_content">
													<label>Pincode</label>
													<input type="number" name="pincode" value={formData.pincode} onChange={handleChange} />
												</p>
											</Col>
										</Row>
										<Row>
											<Col md="12">
												<p className="registration_form_content">
													<label>Store Name <span>*</span></label>
													<input type="text" name='name' className={hasError ? "error_border" : "success_border"} value={formData.name} onChange={handleChange} />
													{errorMessages.nameError && (
														<span className="error_message">{errorMessages.nameError}</span>
													)}
												</p>
											</Col>
										</Row>
										<Row>
											<Col md="12">
												<p className="registration_form_content">
													<label>Store Address<span>*</span></label>
													<input type="text" name='address' className={hasError ? "error_border" : "success_border"} value={formData.address} onChange={handleChange} />
													{errorMessages.addressError && (
														<span className="error_message">{errorMessages.addressError}</span>
													)}
												</p>
											</Col>
										</Row>
										<Row>
											<Col md="4">
												<p className="registration_form_content">
													<label>Store Contact Number</label>
													<input type="tel" name='phone' value={formData.phone} onChange={handleChange} />
												</p>
											</Col>
											<Col md="8">
												<p className="registration_form_content">
													<label>Website/Google Maps map_location_link Link</label>
													<input type="text" name='map_location_link' onChange={handleChange} value={formData.map_location_link} />
												</p>
											</Col>
										</Row>
										<div className='d-flex justify-content-center align-items-center'>
											{loadingButton ?
												<button className="btn mb-5 btn-md text-center btn-size create_ac_btn" type="button" disabled style={{ fontSize: "1rem", background: "#007DC1", color: "white", }}>
													<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: ".5rem" }}></span>
													Loading...
												</button> : selectedStoreId ? (
													<button className="btn mb-5 mt-3 btn-md text-center btn-size create_ac_btn" onClick={handleUpdateStore} >
														Save Changes
													</button>
												) : (
													<button className="btn mb-5 mt-3 btn-md text-center btn-size create_ac_btn" onClick={handleSubmit} >
														Submit
													</button>
												)}
										</div>
									</Col>
								</form>
							</div>
						</Modal>
					</div>
					{(fetchedStores.length > 0 || searchStoreCount) ?
						<Row>
							<Col md="3">
								<p className="registration_form_content">
									<label style={{ fontWeight: "bold" }}>Country</label>
									<select name='country' value={formData.country} onChange={handleSearchChange}>
										<option value="">All Country</option>
										{searchFetchedCountryData.countries
											? Object.keys(searchFetchedCountryData.countries).map((countryCode) => (
												<option key={countryCode} value={countryCode}>
													{searchFetchedCountryData.countries[countryCode]}
												</option>
											))
											: null
										}
									</select>
								</p>
							</Col>
							<Col md="3">
								<p className="registration_form_content">
									<label style={{ fontWeight: "bold" }}>State</label>
									<select name='state' value={formData.state} onChange={handleSearchChange} >
										<option value="">All States</option>
										{searchFetchedData.states
											? Object.keys(searchFetchedData.states).map((stateCode) => (
												<option key={stateCode} value={stateCode}>
													{searchFetchedData.states[stateCode]}
												</option>
											))
											: null
										}
									</select>
								</p>
							</Col>
							<Col md="3">
								<p className="registration_form_content">
									<label style={{ fontWeight: "bold" }}>City</label>
									<select name='city' value={formData.city} onChange={handleSearchChange} >
										<option value="">All Cities</option>
										{searchFetchedCityData.cities
											? searchFetchedCityData.cities.map((city) => (
												<option key={city} value={city}>
													{city}
												</option>
											))
											: null
										}
									</select>
								</p>
							</Col>
							<Col md="3">
								<p className="registration_form_content">
									<label style={{ fontWeight: "bold" }}>Store Name</label>
									<input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" name="name" onChange={handleSearchChange} />
								</p>
							</Col>
						</Row>
						: null}
					<div className='table-responsive'>
						<Table className="text-center table">
							<thead className='store_details_table_head'>
								<tr>
									<th className="fw-bold">Sr. Number</th>
									<th className="fw-bold">Store Name</th>
									<th className="fw-bold">Number Of Audit Per Store</th>
									<th className="fw-bold">Action</th>
								</tr>
							</thead>
							<tbody>
								{loadingData ?
									<tr>
										<td colSpan="9"><img src={loading} alt="Loading..." height="180px" /></td>
									</tr>
									: searchNamenotFound ? (
										<tr>
											<td colSpan="9" style={{ color: "red" }}>{searchNamenotFound}</td>
										</tr>
									) :
										fetchedStores.length > 0 ? fetchedStores.map((store, index) => (
											<tr key={index}>
												<td>{index + 1}</td>
												<td>{store?.name}</td>
												<td>
													<input type="number" name="audit_count" style={hasResponseError ? { border: "2px solid red" } : null} value={audit_count[store.id] || ''} onChange={(e) => handleAuditCountChange(e, store.id)} className='audit_count_numberbox' />
												</td>
												<td>
													<i className="fa-solid fa-pen-to-square" style={{ color: "green" }} onClick={() => handleEditStore(store)} ></i>{" "}
													<span style={{ marginLeft: ".5rem" }}>
														<i className="fa-solid fa-trash" style={{ color: "#ff6f6f" }} onClick={() => handleDeleteStore(store?.id)} ></i>
													</span>
												</td>
											</tr>
										)) :
											<tr>
												<td colSpan="9" style={{ color: "red" }} >No Store Found...</td>
											</tr>
								}
							</tbody>
						</Table>
					</div>
				</div>

			</div>
			<div >
				<div className='table-responsive project_setup_btn_box_table'>
					<Table className="table text-center">
						<thead>
							<tr>
								<th>Price Per Response</th>
								<th>x</th>
								<th>Quantity </th>
								<th>=</th>
								<th>Price</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								{localStorage.getItem("selectedCountry") ?
								localStorage.getItem("selectedCountry") === "IN" ?
                                    <td>INR. {cartItems?.items[0]?.price_INR}</td>
                                    :
                                    <td> USD. {cartItems?.items[0]?.price_USD}</td>
									:
									<td>INR. {cartItems?.items[0]?.price_INR}</td>
                                }
								<td>x </td>
								<td>{cartItems?.totalQuantity}</td>
								<td>=</td>
								{localStorage.getItem("selectedCountry") ?
								localStorage.getItem("selectedCountry") === "IN" ?
									<td>INR. {cartItems?.totalAmount}</td>
									:
									<td>USD. {cartItems?.totalAmount}</td>
									:
									<td>INR. {cartItems?.totalAmount}</td>
								}
							</tr>
						</tbody>
					</Table>
				</div>
			</div>
			<div className='project_setup_btn_box project_setup_btn_box_secondpage'>
				<div className='project_setup_btn_box_left'>
					{currentStep > 0 &&
						<button className="btn btn-md text-center btn-size create_ac_btn nxtbtn_box"
							onClick={() => updateStep(currentStep - 1)}
						>Previous</button>
					}
				</div>
				<button className="btn btn-md text-center btn-size create_ac_btn nxtbtn_box"
					onClick={handleNextClick}
				>NEXT</button>
				<div className='nxtbtn_smlscrn_box_setup2'>
					{currentStep > 0 &&
						<button className="btn btn-md text-center btn-size create_ac_btn nxtbtn_smlscrn"
							onClick={() => updateStep(currentStep - 1)}
						>Previous</button>
					}
					<button className="btn btn-md text-center btn-size create_ac_btn nxtbtn_smlscrn"
						onClick={handleNextClick}
					>NEXT</button>
				</div>
			</div>
		</div>
	)
}
