import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Col, Table,Row } from 'react-bootstrap';
import { ResponsiveContainer, PieChart, Pie, Legend, Tooltip } from 'recharts';

export default function Storedetail({storeReportDetail}) {
  const [performance,setPerformance] = useState("");
 useEffect(()=>{
if(storeReportDetail?.audit_store_percentage>=95){
setPerformance("Excellent");
}else if (storeReportDetail?.audit_store_percentage>=80 && storeReportDetail?.audit_store_percentage<=94){
  setPerformance("Good"); 
}else if (storeReportDetail?.audit_store_percentage>=70 && storeReportDetail?.audit_store_percentage<=79){
  setPerformance("Average"); 
}else if (storeReportDetail?.audit_store_percentage>=50 && storeReportDetail?.audit_store_percentage<=69){
  setPerformance("Poor"); 
}else if (storeReportDetail?.audit_store_percentage>=0 && storeReportDetail?.audit_store_percentage<=50){
  setPerformance("Bad"); 
}
 },[storeReportDetail])
  return (
    <Row className='report_details_flexbox'>
      <Col md="6" className='report_details_result_box'>
        {/* <div > */}
          <h6>Audit Details</h6>
          <table className="table table-striped">
            <tbody>
              <tr>
                <td>Client: <b style={{marginLeft:"2rem"}}>{storeReportDetail?.audit?.audit_cycle?.client?.name}</b></td>
              </tr>
              <tr>
                <td>Store Code: <b style={{marginLeft:"2rem"}}>{storeReportDetail?.audit?.store?.code ? storeReportDetail?.audit?.store?.code : "---" }</b></td>
              </tr>
              <tr>
                <td>Store: <b style={{marginLeft:"2rem"}}>{storeReportDetail?.audit?.store?.name}</b></td>
              </tr>
              <tr>
                <td>Store Type: <b style={{marginLeft:"2rem"}}>{storeReportDetail?.audit?.store?.type!=="" ? storeReportDetail?.audit?.store?.type : "---"}</b></td>
              </tr>
              <tr>
                <td >Audit Type: <b style={{marginLeft:"2rem"}}>{storeReportDetail?.audit?.audit_cycle?.type}</b></td>
              </tr>
              <tr>
                <td>Address: <b style={{marginLeft:"2rem"}}>{storeReportDetail?.audit?.store?.address}</b></td>
              </tr>
              <tr>
                <td >Audit Date: <b style={{marginLeft:"2rem"}}>{storeReportDetail?.audit_date}</b></td>
              </tr>
              <tr>
                <td>Overall Experience:<b style={{marginLeft:"2rem"}}>{storeReportDetail?.audit_store_percentage} %</b></td>
              </tr>
            </tbody>
          </table>
        {/* </div> */}
      </Col>
      <Col md="6" className='report_details_result_box'>
        {/* <div className='report_details_result_box'> */}
          <h6>Overall Experience</h6>
          <div className='report_details_result_box_flex'>
          <Col md="7">
            <div className="semi_donut" style={{ '--percentage': storeReportDetail?.audit_store_percentage, '--fill': 'rgb(234,255,153)' }}>
             <p style={{color:"black"}}> {storeReportDetail?.audit_store_percentage} %</p>
            </div>
            <div style={{display:"flex", justifyContent:"center"}}>
            <h5><b>{performance}</b></h5>
            </div>
            </Col>
            <Col md="5" className='report_colorful_chart'>
            <Table style={{height:"320px"}}>
              <tbody>
                <tr style={{padding:"1rem"}}>
                  <td style={{ backgroundColor: "rgb(193,255,153)" }}><b>Excellent</b></td>
                  <td style={{ backgroundColor: "rgb(193,255,153)" }}>95% and above</td>
                </tr>
                <tr>
                  <td style={{ backgroundColor: "rgb(234,255,153)" }}><b>Good</b></td>
                  <td style={{ backgroundColor: "rgb(234,255,153)" }}>80% - 94%</td>
                </tr>
                <tr>
                  <td style={{ backgroundColor: "rgb(255,255,153)" }}><b>Average</b></td>
                  <td style={{ backgroundColor: "rgb(255,255,153)" }}>70% - 80%</td>
                </tr>
                <tr>
                  <td style={{ backgroundColor: "rgb(255,235,153)" }}><b>Poor</b></td>
                  <td style={{ backgroundColor: "rgb(255,235,153)" }}>50% - 70% </td>
                </tr>
                <tr>
                  <td style={{ backgroundColor: "rgb(255,194,153)" }}><b>Bad</b></td>
                  <td style={{ backgroundColor: "rgb(255,194,153)" }}>0% - 50%</td>
                </tr>
              </tbody>
            </Table>
            </Col>
          </div>
        {/* </div> */}
      </Col>
      </Row>
  )
}
