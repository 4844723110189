import React from 'react';
import { Link } from 'react-router-dom';

export default function ViewReport({ updateStep, currentStep }) {
    return (
        <div className='dash_stepper_box_right'>
            <div className='dash_stepper_box_right_up'>
                <i className="fa-solid fa-image"></i>
                <h2>This is where we jump in.</h2>
                <p>Now that you've sent the project request, sit back and relax. We'll take it from here. Once the project is executed, you'll see the reports in the user dashboard under 'Reports'</p>
            </div>
            <div className='dash_stepper_last_btn_container'>
            <button onClick={() => updateStep(0)} className='read_again_btn'><i className="fa-solid fa-rotate-left"></i> <span>Read again</span></button>
                <Link to="/exploremarketplace">
                <button className='dash_stepper_last_btn_container_btn'>Explore market place</button>
                </Link>
            </div>
        </div>
    )
}
