import React, { useState, useRef, useEffect } from 'react';
import { Col, Row, Form, Table, Alert } from 'react-bootstrap';
import "../styles/auditprojectsetup1.css";
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addItem, removeItem,updateQuantity } from '../utils/cartSlice';
import { clearPreferenceData, setFormData, removeFile, addFile } from '../utils/orderdataslice';
import { AgeData, OccupationData, IncomeRangeData, CarCostList, GenderList, InterestAreaList, getOccupation, getIncomeText, getCarCost, getGender, getInterestArea } from '../utils/constant';
import Select from "react-select";
import base_url from '../utils/config';
import { useFetch } from '../utils/hooks/useFetch';
import { postUploadFileData, deleteFileData } from '../utils/services/PostdataHandle';
import loadingimg from "./../images/loading.avif";
import pdfDummy from "./../images/pdfDownload.png";

export default function AuditProjectSetup1({ updateStep, currentStep }) {
    const navigate = useNavigate();
    const location = useLocation();
    if (location.state) {
        localStorage.setItem('cartItemId', location.state);
    }
    const id = localStorage.getItem('cartItemId');
    const token = localStorage.getItem("token");
    const cartItems = useSelector((store) => store.cart);
    const storedInputs = useSelector((store) => store.orderdata.firstPage.inputs);
    const dispatch = useDispatch();
    const [draftProduct,setDraftProduct] = useState([]);
    const [numberofResponseShow, setNumberofResponseShow] = useState(true);
    const [inputs, setInputs] = useState(storedInputs);
    const [orderId, setOrderId] = useState("");
    const [describe, setDescribe] = useState(storedInputs.describe || "");
    const [describeError, setDescribeError] = useState("");
    const [ageRangeError, setAgeRangeError] = useState("");
    const [responseError, setResponseError] = useState("");
    const [nextScroll, setNextScroll] = useState(false);
    const [preference, setPreference] = useState(storedInputs?.preference);
    const fileInputRef = useRef(null);
    const [selectedFile, setSelectedFile] = useState([]);
    const [fileUrl, setFileUrl] = useState(null);
    const [showFileDeleteAlert, setShowFileDeleteAlert] = useState(false);
    const [showFileDeleteFailAlert, setShowFileDeleteFailAlert] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [categoryListUrl, setCategoryListUrl] = useState(base_url + "manager/public_solution/" + id + "/full_details");
    const { data, setData } = useFetch(categoryListUrl);
    const interestAreaOptions = [];
    const genderOptions = [];
    const incomeOptions = [];
    const carCostOptions = [];
    const occupationOptions = [];
    const [auditor_age_range, setauditor_age_range] = useState(storedInputs.auditor_age_range || "");
    const [selectedValues, setSelectedValues] = useState({
        occupation: [],
        income: [],
        car_cost: [],
        gender: [],
        interest_area: []
    });
    const [selectedOccupationOptions, setSelectedOccupationOptions] = useState([]);
    const [selectedIncomeOptions, setSelectedIncomeOptions] = useState([]);
    const [selectedCarCostOptions, setSelectedCarCostOptions] = useState([]);
    const [selectedGenderOptions, setSelectedGenderOptions] = useState([]);
    const [selectedInterestAreaOptions, setSelectedInterestAreaOptions] = useState([]);
    const [selectedDraftAlignmentFactors, setSelectedDraftAlignmentFactors] = useState({});

    const setSelectedOptionsFromStoredData = () => {

        setSelectedOccupationOptions(
            getSelectedOptions(storedInputs?.occupation, occupationOptions)
        );
        setSelectedIncomeOptions(
            getSelectedOptions(storedInputs?.income, incomeOptions)
        );
        setSelectedCarCostOptions(
            getSelectedOptions(storedInputs?.car_cost, carCostOptions)
        );
        setSelectedGenderOptions(
            getSelectedOptions(storedInputs?.gender, genderOptions)
        );
        setSelectedInterestAreaOptions(
            getSelectedOptions(storedInputs?.interest_area, interestAreaOptions)
        );
    }

// --------------------------------------------------DRAFT PART------------------------------------------------

    // const setSelectedOptionsFromDraftData = () => {
    //     setSelectedOccupationOptions(
    //         getSelectedOptions(selectedDraftAlignmentFactors.occupation, occupationOptions)
    //     );
    //     setSelectedIncomeOptions(
    //         getSelectedOptions(selectedDraftAlignmentFactors.income, incomeOptions)
    //     );
    //     setSelectedCarCostOptions(
    //         getSelectedOptions(selectedDraftAlignmentFactors.car_cost, carCostOptions)
    //     );
    //     setSelectedGenderOptions(
    //         getSelectedOptions(selectedDraftAlignmentFactors.gender, genderOptions)
    //     );
    //     setSelectedInterestAreaOptions(
    //         getSelectedOptions(selectedDraftAlignmentFactors.interest_area, interestAreaOptions)
    //     );
    // }  

     // useEffect(() => {
    //     setSelectedOptionsFromDraftData();
    // }, [selectedDraftAlignmentFactors]);
    // ---------------------------------------------------------------------------------------------------- 

    useEffect(() => {
        setSelectedOptionsFromStoredData();
    }, [storedInputs]);
    
    useEffect(() => {
        if (!selectedFile) {
            dispatch(removeFile());
        }
    }, [selectedFile, dispatch]);

    function getSelectedOptions(storedValues, options){
        if (!storedValues || storedValues.length === 0) return [];
        return storedValues.map((storedValue) => {
            return options.find((option) => option.value === storedValue);
        });
    };
    useEffect(() => {
        
        const orderIdfromStorage = localStorage.getItem('orderId');
        if (orderIdfromStorage) {
            getFileData(`client/mp_order/${orderIdfromStorage}/attachment`)
            setOrderId(orderIdfromStorage);
            // getIndividualDraftData(`client/mp_order/${orderIdfromStorage}`)
        }
        
        if (location.pathname.split("/").includes("userdashboardmain")) {
            setNumberofResponseShow(false);
        }
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, [])

    // useEffect(() => {
    //     window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    //   }, [nextScroll])
if(nextScroll){
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
}
    const getFileData = async (url) => {
        try {
            const headers = {
                'Authorization': `Token ${token}`,
                'Content-Type': 'application/json'
            };

            const options = {
                method: 'GET',
                headers: headers
            };

            const response = await fetch(base_url + url, options);

            if (response.ok) {
                const result = await response.json();
               
                if (result.error) {
                    alert("error");
                }else if (Array.isArray(result) && result.length === 0) {
                    setSelectedFile([]);
                }
                else {
                    setUploading(true);
                        setSelectedFile([ {
                            attachmentId: result.id,
                            fileUrl: result.direct_url,
                            fileName: result.file_name,
                            fileType: result.mime_type,
                        }]);
                  
                }
                ;
            } else {
                console.error('API request failed:', response.status, response.statusText);
            }
        } catch (error) {
            console.error('An error occurred while fetching the API:', error);
        }finally {
            setUploading(false); 
        }
    };

    // ---------------------------------------------FOR DRAFT--------------------------------------
    // const  getIndividualDraftData = async (url) => {
    //     try {
    //       const headers = {
    //         'Authorization': `Token ${token}`,
    //         'Content-Type': 'application/json'
    //       };
    
    //       const options = {
    //         method: 'GET',
    //         headers: headers
    //       };
    
    //       const response = await fetch(base_url + url, options);
    
    //       if (response.ok) {
    //         const result = await response.json();
    //         setDraftProduct(result);
    //         setDescribe(storedInputs.describe? storedInputs.describe : result?.describe);
    //         dispatch(setFormData({ inputs: { ...inputs, describe: result?.describe } }))
    //         setPreference(result.alignment_factors.some(factor => {
    //                     if (Array.isArray(factor.value)) {
    //                         return factor.value.length > 0;
    //                     } else {
    //                         return factor.value !== '';
    //                     }
    //                 }))
    //                 if (result && result.alignment_factors && result.alignment_factors.length > 0) {
    //                     const alignmentFactors = {};
    //                     result.alignment_factors.forEach(factor => {
    //                         alignmentFactors[factor.key] = factor.value;
    //                     });
    //                     setSelectedDraftAlignmentFactors({...alignmentFactors});
    //                     setauditor_age_range(alignmentFactors.auditor_age_range);
    //                 } 
       
    //         if (result.error) {
    //             alert("error");
    //           }
    //       } else {
    //         console.error('API request failed:', response.status, response.statusText);
    //       }
    //     } catch (error) {
    //       console.error('An error occurred while fetching the API:', error);
    //     }
    //   };
// -----------------------------------------------------------------------------------------------------------
    const handleAddItem = (item) => {
        // dispatch(addItem(item));
        setResponseError("");
        if (getAddedItemCount(item.id) < 30) {
            dispatch(addItem(item));
        }
    };

    const handleRemoveItem = (itemId) => {
        dispatch(removeItem(itemId));
    };

    const getAddedItemCount = (itemId) => {
        let count = 0;
        cartItems.items.forEach((item) => {
            if (item.id === itemId) {
                count += item.quantity;
            }
        });
        return count;
    };
    const isAddedToCart = (itemId) => {
        return getAddedItemCount(itemId) > 0;
    };
    // const handleQuantityChange = (e, item) => {
    //     // dispatch(updateQuantity({ id: item.id, quantity: parseInt(e.target.value) }));

    //     const newQuantity = e.target.value === '' ? 0 : parseInt(e.target.value); //in case of manually removing the number using backSpace, it gives empty string and that makes totaQuantity null, to avoid that this part
    //     if (newQuantity >= 0 && newQuantity <= 30) {
    //         dispatch(updateQuantity({id: item.id, quantity: newQuantity}));
    //     }
    // };
   
    // ---------------------------------------------------------------------------------------
    const handleQuantityChange = (e, item) => {
        const newQuantity = e.target.value === '' ? '' : parseInt(e.target.value);
        if (!isNaN(newQuantity) && newQuantity >= 0 && newQuantity <= 30) {
            dispatch(updateQuantity({id: item.id, quantity: newQuantity}));
        }
    };
    
    // --------------------------------------------------------------------------------------
    
    const onauditor_age_rangeChanged = (e) => {
        setauditor_age_range(e.target.value);
            setAgeRangeError("");
        setInputs({ ...inputs, auditor_age_range: e.target.value });
        dispatch(setFormData({ inputs: { ...inputs, auditor_age_range: e.target.value } }))
    };
    // const isAgeRangeValid = (value) => {
    //     if (value === "") {
    //         return true;
    //     }
    //     let patterns = /\d.-\d/;
    //     if (patterns.test(value) === false) {
    //         return false;
    //     }
    //     let [min, max] = value.split("-");
    //     if (min === "" || max === "") {
    //         return false;
    //     }
    //     if (Number(min) > Number(max) || Number(max) < Number(min)) {
    //         return false;
    //     }
    //     else {
    //         return true;
    //     }
    // };

    
    const isAgeRangeValid = (value) => {
        if (value === "") {
            return true;
        }
        let patterns = /\d\s*-\s*\d/;
        if (patterns.test(value) === false) {
            return false;
        }
        let [min, max] = value.split("-");
        min = min.trim();
        max = max.trim();
        if (min === "" || max === "") {
            return false;
        }
        if (Number(min) > Number(max) || Number(max) < Number(min)) {
            return false;
        }
        else {
            return true;
        }
    };
    
    const [isFileAdded, setIsFileAdded] = useState(false);

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            try {
                setUploading(true);
                const response = await postUploadFileData(file, orderId);
                if (response && response.direct_url) {
                    setSelectedFile([...selectedFile, {
                        attachmentId: response.id,
                        fileUrl: response.direct_url,
                        fileName: file.name,
                        fileType: file.type,
                    }]);
                    setIsFileAdded(true);
                } else {
                    console.error('Unexpected response format:', response);
                }
            } catch (error) {
                console.error('Error:', error);
            } finally {
                setUploading(false); 
            }
        }
    };

    const handleRemoveFile = (attachmentId) => {
        const promise = deleteFileData(`client/mp_order_attachment/${attachmentId}/delete`, orderId);
        promise.then((response) => {
            if (response.status === 200 || response.status === 204) {
                const updatedSelectedFile = selectedFile.filter((file) => file.attachmentId !== attachmentId);
                setSelectedFile(updatedSelectedFile);
                setIsFileAdded(false);
                setShowFileDeleteAlert(true);
                setTimeout(() => {
                    setShowFileDeleteAlert(false);
                }, 1000)
            } else {
                setShowFileDeleteFailAlert(true);
                setTimeout(() => {
                    setShowFileDeleteFailAlert(false);
                }, 1000)
            }
        }).catch((error) => {
            console.error('File deletion error:', error);
            alert("Deletion failed");
        });
    }
    const [fileVisibility, setFileVisibility] = useState({});

    const toggleFileVisibility = (fileUrl) => {
        setFileVisibility((prevState) => ({
            ...prevState,
            [fileUrl]: !prevState[fileUrl],
        }));
    };

    const handleAddFileClick = () => {
        if (isFileAdded) {
            alert("please remove this file to upload another")
        }
        if (!isFileAdded) {
            fileInputRef.current.click();
        }
    };

    const handleChange = (e) => {
        setDescribe(e.target.value);
        setDescribeError("");
        setInputs({ ...inputs, describe: e.target.value });
        dispatch(setFormData({ inputs: { ...inputs, describe: e.target.value } }))
    }
    const handleSelectChange = (selectedOptions, field) => {
        const selectedValuesArray = selectedOptions.map((option) => option.value);
        setSelectedValues((prevValues) => ({
            ...prevValues,
            [field]: selectedValuesArray,
        }));
        setInputs((prevInputs) => ({
            ...prevInputs,
            [field]: selectedValuesArray,
        }));
        dispatch(setFormData({ inputs: { ...inputs, [field]: selectedValuesArray, preference: true } }));
    };


    const handleRadioButtonChange = (event) => {
        if (event.target.value === "I have a preference") {
            setPreference(true);
            dispatch(setFormData({ inputs: { ...inputs, preference: true } }));
        } else {
            setPreference(false);
            setSelectedOccupationOptions([]);
            setSelectedIncomeOptions([]);
            setSelectedCarCostOptions([]);
            setSelectedGenderOptions([]);
            setSelectedInterestAreaOptions([]);
            setauditor_age_range("");
            dispatch(setFormData({ inputs: { ...inputs, preference: false } }));
            setInputs({
                ...inputs,
                auditor_age_range: "",
                occupation: [],
                income: [],
                car_cost: [],
                gender: [],
                interest_area: [],
            });
        }

    };
    for (let option of OccupationData) {
        occupationOptions.push({
            label: getOccupation(option),
            value: option
        });
    }
    for (let option of IncomeRangeData) {
        incomeOptions.push({
            label: getIncomeText(option),
            value: option
        });
    }
    for (let option of CarCostList) {
        carCostOptions.push({
            label: getCarCost(option),
            value: option
        });
    }
    for (let option of GenderList) {
        genderOptions.push({
            label: getGender(option),
            value: option
        });
    }
    for (let option of InterestAreaList) {
        interestAreaOptions.push({
            label: getInterestArea(option),
            value: option
        });
    }
    const handleNextButtonClick = () => {
        if (isAgeRangeValid(auditor_age_range) === false) {
           setAgeRangeError("Please enter valid auditor age range (eg: 20-30)");
        //    setTimeout(()=>{
        //     setAgeRangeError("");
        //    },2500)
            return false;
        } else if (cartItems.totalQuantity === 0) {
            setResponseError("Response can not be 0");
            // setTimeout(()=>{
            //     setResponseError("");
            //    },1200)
            setNextScroll(true);
            setTimeout(()=>{
                setNextScroll(false);
                   },1200) 
        } else if (describe === "") {
            setDescribeError("This field can not be empty")
            // setTimeout(()=>{
            //     setDescribeError("");
            //    },1200)
            setNextScroll(true);
           setTimeout(()=>{
            setNextScroll(false);
               },1200) 
        }
        else if (describe !== "") {
            setInputs({ ...inputs, auditor_age_range });
            setNextScroll(false);
            updateStep(currentStep + 1);
        }
    }

    return (
        <div>
            <div className='project_setup1_main_container'>
                <div className='project_setup1_container'>
                    <h2>Give us a few details to start with.</h2>
                    <Row>
                        {numberofResponseShow ?
                            <Col md="12">
                                <div className="project_setup1_content_special">
                                    <label className='mb-2 mt-2'>Total Number of Responses <span>*</span></label>
                                    {data.map(item => (
                                        <div className="quantityContainer" key={item?.id}>
                                            <button disabled={getAddedItemCount(item?.id) <= 1}
                                                onClick={() => handleRemoveItem(item?.id)}><i className="fa-solid fa-minus" style={{ color: "black" }}></i></button>
                                            <button>{getAddedItemCount(item?.id) || 0}</button>
                                            {/* <input type="number" value={getAddedItemCount(item.id)} onChange={(e) => handleQuantityChange(e, item)} className="quantity-input"/> */}
                                            {/* <input type="text" value={getAddedItemCount(item.id) === 0 ? '' : getAddedItemCount(item.id)} onChange={(e) => handleQuantityChange(e, item)} className="quantity-input" pattern='[0-9]*'/> */}
                                            <button onClick={() => handleAddItem(item)}><i className="fa-solid fa-plus"></i></button>
                                        </div>
                                    ))}
                                    {responseError !== "" && <span>{responseError}</span>}
                                </div>
                            </Col>
                            : null}
                    </Row>
                    <Row >
                        <Col md="12">
                            <p className="project_setup1_content">
                                <label className='mb-2'>Describe the item in a few words. Mention any additional information you need us to take care of <span>*</span></label>
                                <textarea
                                    rows={5}
                                    name="describe"
                                    value={describe}
                                    style={{ padding: "1rem" }}
                                    onChange={handleChange}
                                />
                                {describeError !== "" && <span>{describeError}</span>}
                            </p>
                        </Col>
                    </Row>
                    <Row >
                        <Col md="12">
                            <p >Add an Image/ Brochure/PDFs to help us understand the product in detail</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            {
                                showFileDeleteAlert && (<Alert variant="success" onClose={() => setShowFileDeleteAlert(false)} dismissible style={{ textAlign: "center" }}>
                                    File is DELETED successfully
                                </Alert>)}
                            {
                                showFileDeleteFailAlert && (<Alert variant="danger" onClose={() => setShowFileDeleteAlert(false)} dismissible style={{ textAlign: "center" }}>
                                    File can not be DELETED
                                </Alert>)}
                                {/* <button onClick={handleAddFileClick} className="btn btn-md text-center btn-size create_ac_btn">Add File</button> */}
                            {!isFileAdded ? <button onClick={handleAddFileClick} className="btn btn-md text-center btn-size create_ac_btn">Add File</button>
                            : null}
                            {uploading && (
                                <div>
                                    <img src={loadingimg} alt="Loading..." height="120px" />
                                </div>
                            )}
                            <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                accept=".pdf, image/*"
                                onChange={handleFileChange}
                            />
                            {selectedFile.length>0 ? selectedFile.map((file, index) => (
                                <div key={index}>
                                    <div className='d-flex' style={{ marginTop: "1rem",cursor:"pointer" }}>
                                        <p onClick={() => toggleFileVisibility(file.fileName)} className='selected_files_name' >
                                            {file.fileName}
                                        </p>
                                        <button onClick={() => handleRemoveFile(file.attachmentId)} className="btn btn-md text-center btn-size" style={{ paddingTop: "0rem" }}><i className="fa-solid fa-x"></i></button>
                                    </div>
                                    { file.fileType === "application/pdf" ? (
                                        <a href={file.fileUrl} download>
                                            <img
                                                src={pdfDummy}
                                                alt={file?.fileName}
                                                style={{ display: fileVisibility[file.fileName] ? 'none' : 'block', objectFit: "cover", }}
                                            />
                                        </a>
                                    ) : (
                                        <>
                                            <img
                                                src={file?.fileUrl}
                                                alt={file?.fileName}
                                                width="300"
                                                style={{ display: fileVisibility[file.fileName] ? 'none' : 'block', objectFit: "cover" }}
                                            />
                                        </>
                                    )}                                    
                                </div>
                            )) : null}
                        </Col>
                    </Row>


                    <Row style={{ paddingTop: "2rem" }}>
                        <Col md="12">
                            <h5 style={{ paddingBottom: "1rem" }}>Choose Your Audience Demographic</h5>
                            <div className='radio_preference'>
                                <>
                            <input
                                type="radio"
                                name='preferenceSelect'
                                id="nopreference"
                                value="It doesn't matter"
                                style={{cursor:"pointer"}}
                                onChange={handleRadioButtonChange}
                                checked={!preference} // to check "It doesn't matter" when preference is false
                            />
                            <label htmlFor="nopreference" style={{ paddingLeft: "1rem", cursor:"pointer" }}>It doesn't matter</label>
                            </>
                            <>
                            <input
                                type="radio"
                                name='preferenceSelect'
                                id="preference"
                                value="I have a preference"
                                onChange={handleRadioButtonChange}
                                checked={preference} // to check "I have a preference" when preference is true
                                style={{ marginLeft: "2rem",cursor:"pointer" }}
                            />
                            <label htmlFor="preference" style={{ paddingLeft: "1rem", cursor:"pointer" }}>I have a preference</label>
                            </>
                            </div>
                        </Col>
                    </Row>
                    {preference &&
                    <>
                        <Row className='audience_preference'>
                            <div className="col-lg-4 audience_preference_smlscrn">
                                <label className='form-label fw-bold fs-6 mb-3'>Occupation</label>
                                <Select
                                    name="occupation"
                                    value={selectedOccupationOptions}
                                    onChange={(value) => {
                                        setSelectedOccupationOptions(value);
                                        handleSelectChange(value, "occupation");
                                    }}
                                    options={occupationOptions}
                                    isMulti={true} />
                            </div>
                            <div className="col-lg-4 audience_preference_smlscrn">
                                <label className='form-label fw-bold fs-6 mb-3'>Age</label>
                                <input type="text" className="form-control input_age" name="auditor_age_range"
                                    placeholder="Example: 20-50"
                                    autoComplete='off'
                                    style={ageRangeError !== "" ? { marginBottom: "10px" } : {}}
                                    value={auditor_age_range} onChange={onauditor_age_rangeChanged} />
                                     {ageRangeError !== "" && <span style={{color:"red"}}>{ageRangeError}</span>}
                            </div>
                            <div className="col-lg-4 audience_preference_smlscrn">
                                <label className='form-label fw-bold fs-6 mb-3'>Income Range</label>
                                <Select
                                    name="income"
                                    value={selectedIncomeOptions}
                                    onChange={(value) => {
                                        setSelectedIncomeOptions(value);
                                        handleSelectChange(value, "income");
                                    }}
                                    options={incomeOptions}
                                    isMulti={true} />
                            </div>
                            </Row>
                            <Row className='audience_preference audience_preference_down'>
                            <div className="col-lg-4 audience_preference_smlscrn">
                                <label className='form-label fw-bold fs-6 mb-3'>Car Owned</label>
                                <Select
                                    name="car_cost"
                                    value={selectedCarCostOptions}
                                    onChange={(value) => {
                                        setSelectedCarCostOptions(value);
                                        handleSelectChange(value, "car_cost");
                                    }}
                                    options={carCostOptions}
                                    isMulti={true} />
                            </div>
                            <div className="col-lg-4 audience_preference_smlscrn">
                                <label className='form-label fw-bold fs-6 mb-3'>Gender</label>
                                <Select
                                    name="gender"
                                    value={selectedGenderOptions}
                                    onChange={(value) => {
                                        setSelectedGenderOptions(value);
                                        handleSelectChange(value, "gender");
                                    }}
                                    options={genderOptions}
                                    isMulti={true} />
                            </div>
                            <div className="col-lg-4 audience_preference_smlscrn">
                                <label className='form-label fw-bold fs-6 mb-3'>Must Have Interest/ Experience in</label>
                                <Select
                                    name="interest_area"
                                    value={selectedInterestAreaOptions}
                                    onChange={(value) => {
                                        setSelectedInterestAreaOptions(value);
                                        handleSelectChange(value, "interest_area");
                                    }}
                                    options={interestAreaOptions}
                                    isMulti={true} />
                            </div>

                        </Row>
                        </>
                    }
                </div>
            </div >
            <div >
				<div className='table-responsive project_setup_btn_box_table'>
					<Table className="table text-center">
						<thead>
							<tr>
								<th>Price Per Response</th>
								<th>x</th>
								<th>Quantity </th>
								<th>=</th>
								<th>Price</th>
							</tr>
						</thead>
						<tbody>
                            <tr>
                                {localStorage.getItem("selectedCountry") ?
                                    localStorage.getItem("selectedCountry") === "IN" ?
                                    <td>INR. {cartItems?.items[0]?.price_INR}</td>
                                    :
                                    <td> USD. {cartItems?.items[0]?.price_USD}</td>
                                    :
                                    <td>INR. {cartItems?.items[0]?.price_INR}</td>
                                }
                                <td>x </td>
                                <td>{cartItems?.totalQuantity}</td>
                                <td>=</td>
                                {localStorage.getItem("selectedCountry") ?
                                localStorage.getItem("selectedCountry") === "IN" ?
                                    <td>INR. {cartItems?.totalAmount}</td>
                                    :
                                    <td>USD. {cartItems?.totalAmount}</td>
                                    :
                                    <td>INR. {cartItems?.totalAmount}</td>
                                }
                            </tr>
						</tbody>
					</Table>
				</div>
			</div>
            <div className='project_setup_btn_box project_setup_btn_box_secondpage'>
                <div className='project_setup_btn_box_left'>
                    {currentStep > 0 &&
                        <button className="btn btn-md text-center btn-size create_ac_btn"
                            onClick={() => updateStep(currentStep - 1)}
                        >Previous</button>
                    }
                    {/* <div className='table-responsive'>
                    <Table className="text-center table">
                        <thead>
                            <tr>
                                <th>Price Per Response</th>
                                <th>*</th>
                                <th>Quantity </th>
                                <th>=</th>
                                <th>Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>INR. {cartItems?.items[0]?.price}</td>
                                <td>* </td>
                                <td>{cartItems?.totalQuantity}</td>
                                <td>=</td>
                                <td>INR. {cartItems?.totalAmount}</td>
                            </tr>
                        </tbody>
                    </Table>
                    </div> */}
                </div>
                <button className="btn btn-md text-center btn-size create_ac_btn nxtbtn_box"
                    onClick={handleNextButtonClick}
                >NEXT</button>
                <div className='nxtbtn_smlscrn_box'>
                 <button className="btn btn-md text-center btn-size create_ac_btn nxtbtn_smlscrn"
                    onClick={handleNextButtonClick}
                >NEXT</button>
                </div>
            </div>
        </div >
    )
}