import { useState } from "react";
import { Col } from "react-bootstrap";
import SelectProduct from "./SelectProduct";
import ProjectDetail from "./ProjectDetail";
import UploadStore from "./UploadStore";
import YourAudit from "./YourAudit";
import Review from "./Review";
import ViewReport from "./ViewReport";
import "../../../styles/dashboardstepper.css";
import MobileSelectProduct from "./MobileSelectProduct";
import MobileProjectDetail from "./MobileProjectDetail";
import MobileUploadStore from "./MobileUploadStore";
import MobileYourAudit from "./MobileYourAudit";
import MobileReview from "./MobileReview";
import MobileViewReport from "./MobileViewReport";

export default function MobileSlider() {
  const steps = [MobileSelectProduct, MobileProjectDetail, MobileUploadStore, MobileYourAudit, MobileReview, MobileViewReport];
  const labelArray = ['Select Product', 'Fill in project details', 'Upload Stores', 'Tell us what you want to audit', 'Review & Pay', 'View Reports'];
  const [currentStep, setCurrentStep] = useState(0);

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrev = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const CurrentComponent = steps[currentStep];

  return (
       <div style={{margin:"2rem 0rem"}}>
      <h5>{labelArray[currentStep]}</h5>
      <CurrentComponent />
      <div style={{display:"flex",justifyContent:"space-between",padding:".5rem"}}>
      <button onClick={handlePrev} disabled={currentStep === 0} style={{background:"transparent",border:"none",outline:"none"}}><i className="fa-solid fa-arrow-left" style={{fontSize:"1.2rem",cursor:"pointer"}}></i></button>
      <button onClick={handleNext} disabled={currentStep === steps.length - 1} style={{background:"transparent",border:"none",outline:"none"}}><i className="fa-solid fa-arrow-right" style={{fontSize:"1.2rem",cursor:"pointer"}}></i></button>
      </div>
    </div>
  );
}
