import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import "../styles/individualcategory.css";
import { Row, Col, Container, Modal, Dropdown } from "react-bootstrap";
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addItem } from '../utils/cartSlice';
import base_url from '../utils/config';
import { Jodit } from 'jodit-react';
import Shimmer from './shimmerComponents/Shimmer';
import { BrandDetails } from './HomeBrand';
import talktous from "../images/talk_to_us_img.png";
import { AppContext } from '../App';

const currencyOptions = [
    { value: 'INR', label: 'INR' },
    { value: 'USD', label: 'USD' },
];

export default function IndividualCategory() {
    const navigate = useNavigate();
    // const dispatch = useDispatch();
    const { categoryDataMain, setCategoryDataMain } = useContext(AppContext);
    const [modalShow, setModalShow] = useState(false);
    const { url_structure } = useParams();
    const [categoryName, setCategoryName] = useState("");
    const location = useLocation();
    if (location.state) {
        console.log("21", location.state);
        localStorage.setItem('selectedCategoryId', location.state);
    }
    const id = localStorage.getItem('selectedCategoryId');
    const [data, setData] = useState([]);
    const pathurl = location.pathname.split("/").join("");
    const category = categoryDataMain.find(item => item?.url_structure === pathurl);
    const categoryId = category?.id;
    const [selectedCurrency, setSelectedCurrency] = useState('INR');
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('IN');

    useEffect(() => {
        const storedCountry = localStorage.getItem('selectedCountry');
        if (storedCountry) {
            setSelectedCountry(storedCountry);
        }
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
        if (categoryId && !id) {
            getApiData(base_url + "manager/public_category/" + categoryId + "/solution_detail");
            fetchCountries();
        }
       
    }, [categoryId]);

    useEffect(() => {
        const storedCountry = localStorage.getItem('selectedCountry');
        if (storedCountry) {
            setSelectedCountry(storedCountry);
        }
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
        getApiData(base_url + "manager/public_category/" + id + "/solution_detail");
        fetchCountries();
    }, [id]);
    const fetchCountries = async () => {
        try {
            const response = await fetch(base_url + "manager/country");
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            console.log('Countries data:', data);
            setCountries(data); // Assuming data is an array of country objects
        } catch (error) {
            console.error('Error fetching countries:', error);
        }
    };

    const handleCurrencyChange = (currency) => {
        setSelectedCurrency(currency);
    };

    const convertPrice = (price) => {
        if (selectedCountry === 'IN') { // Assuming 'IN' is the country code for India
            return price; // Price remains in INR
        } else {
            // Convert price to USD
            return price * 84;
        }
    };
    const handleCountryChange = (countryCode) => {
        setSelectedCountry(countryCode);
        localStorage.setItem('selectedCountry', countryCode); // Store selected country in local storage
        console.log('Selected country code:', countryCode);
    };
    
    const getApiData = async (url) => {
        try {
            const response = await fetch(url);
            if (response.ok) {
                const result = await response.json();
                setData(result);
                console.log("category", result)
                document.title = result[0].name + " | FloorWalk Marketplace"
            } else {
                console.error('API request failed:', response.status, response.statusText);
            }
        } catch (error) {
            console.error('An error occurred while fetching the API:', error);
        }
    }
    function handleBackToCategories() {
        navigate("/exploremarketplace");
    }
    function handleBackToHome() {
        navigate("/");
    }
    // const handleAddItem = (item) => {
    //     dispatch(addItem(item));
    //   };

    return data.length === 0 ? <Shimmer /> : (
        <div>
            <div className='back_market back_market_flex' style={{marginBottom:"1rem"}} >
                <div className='back_market_a'>
                <i onClick={handleBackToCategories} className="fa-solid fa-arrow-left"></i>
                <p>
                    <span onClick={handleBackToHome}>Home / </span>
                    <span onClick={handleBackToCategories}>Back to Categories</span>
                </p>
                </div>
                <div className='back_market_flex_country'>
                Select Country :    
                    <Dropdown style={{marginLeft:'10px'}}>
                        <Dropdown.Toggle variant="success" id="currency-dropdown" className="dropdown-button">
                            {selectedCountry ? countries[selectedCountry] : "Choose Country"}
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="dropdown-menu-end">
                            {/* Default option for selecting a country */}
                            <Dropdown.Item key="default" onClick={() => setSelectedCountry('')}>
                                Select Country
                            </Dropdown.Item>
                            {/* Convert countries object to an array of objects */}
                            {Object.keys(countries).map(countryCode => (
                                <Dropdown.Item key={countryCode} onClick={() => handleCountryChange(countryCode)}>
                                    {countries[countryCode]}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
            {data.map((item) => {
                return (
                    <div key={item.id}>
                        <div className='categoryname_banner'>
                            <h1>{item.name}</h1>
                        </div>
                        <div className='category_product_container'>
                            <Row>
                                <h5>{Jodit.modules.Helpers.stripTags(item.overview)}</h5>
                            </Row>
                            <div className='category_product_box_container'>
                                {item?.solutions.map((product) => {
                                    return (
                                        // <div className='category_product_box product_page_category' key={product.id} style={{ paddingTop: "2.5rem" }} onClick={() => handleAddItem(product)}>
                                        <div className='category_product_box product_page_category' key={product.id} style={{ paddingTop: "2.5rem" }}>
                                            <Link to={product?.url_structure} state={{ id: product?.id }} style={{ textDecoration: "none", color: "black" }}>

                                                {/* <div className='category_name'>
                                                    {item.name}
                                                </div> */}
                                                <div className='category_name' style={{ fontWeight: "bold", fontSize: "1rem" }}>
                                                    {product.name}
                                                </div>

                                                <div className='category_product_box_img'>
                                                    <img src={product?.attachments[0]?.preview_url} alt="" height="200px" />
                                                    <div className='categoty_price'>
                                                        {/* INR. {product.price} /Response */}
                                                        {localStorage.getItem("selectedCountry") ? localStorage.getItem("selectedCountry")==="IN"  ? "INR." : "USD." : "INR."}  {localStorage.getItem("selectedCountry") ? localStorage.getItem("selectedCountry")==="IN" ? product.price_INR : product.price_USD : product.price_INR}/Response                                                    </div>
                                                </div>
                                                <div className='category_product_box_text'>
                                                    <p>{Jodit.modules.Helpers.stripTags(product.short_description).split("").slice(0, 90).join("")}...</p>
                                                </div>
                                                <div className='category_product_btn_container'>
                                                    <button className="btn btn-md text-center btn-size create_ac_btn">Buy Now</button>
                                                </div>
                                            </Link>
                                        </div>

                                    )
                                })}
                            </div>
                        </div>
                    </div>
                )
            })}
            {/************************************************************************ */}
            <div className='explore_market_category_down' style={{ marginTop: "3rem" }}>
                <Col md="6" className='explore_market_category_down_img' >
                    <img src={talktous} alt="talk_to_us" />
                </Col>
                <Col md="6" className='explore_market_category_down_text'>
                    <h2>Still not sure these are the right fit for YOU? Let us help!</h2>
                    <p className='explore_market_category_down_text_p'>Floorwalk has expertise across retail, automative, e-commerce, Hospitality and much more. We're here to help you understand and device a customer-centric approach for your service/product delivery.</p>
                    <button
                        className="btn mb-5 mt-3 btn-md text-center btn-size create_ac_btn"
                        onClick={() =>
                            setModalShow(
                                true,
                            )
                        }
                    >
                        Talk to a consultant
                    </button>
                </Col>

            </div>

            {/* ************************************************************************************* */}
            <Container fluid className='homepage_brand_container'>
                <div className='category_brand_heading'>
                    <h3>Suggested Reads</h3>
                </div>
                <BrandDetails />
            </Container>
            <Modal
                show={modalShow}
                centered
                size='lg'
                style={{ marginTop: '40px' }}
                scrollable={true}
                className='modal-dialog-scrollable p-6 modal_mobile'
                onHide={() => setModalShow(false)}>
                <div style={{ position: "absolute", right: "2rem", top: ".8rem", cursor: "pointer", }}
                    onClick={() => setModalShow(false)}
                ><i className="fa-solid fa-x"></i></div>
                <iframe
                    frameBorder='0'
                    style={{
                        height: '500px',
                        width: '99%',
                        border: 'none',
                    }}
                    src='https://forms.zohopublic.com/floorwalk/form/MysteryShoppingServices/formperma/xW3CS_zHKW2DcCLHVZUplXsC1wLR_fITFv3kCFrBY9U?zf_rszfm=1'></iframe>
            </Modal>
        </div>
    )
}
