import React, { useEffect } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import manyavarlogo from "../../../images/manyavar_logo_case.png";
import manyavar from "../../../images/manyavar-logo.png";
import mahindra from "../../../images/Mahindra-Logo-new.png";
import porsche from "../../../images/new-porsche.png";
import manyvarimg from "../../../images/Manyawar_img.png";
import mahindraimg from "../../../images/mahindra1_img.png";
import porscheimg from "../../../images/porsche_img.png";
import side1 from "../../../images/side1.png";
import side2 from "../../../images/side2.png";
import centerimg from "../../../images/Centre.jpg";
import amazonLogo from "../../../images/Amazon_logo.png";
import CommonCards from './CommonCards';
import NewCommonCards from './NewCommonCards';


export default function Amazon() {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, []);

    return (
        <div>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: "3rem", marginTop: "1rem" }}>
                <img src={amazonLogo} alt="amazon_logo" />
                <div className='lead manyavar_heading_text'>
                    <span>Amazon.com is an e-commerce platform that sells many product lines, including media (books, movies, music, and software), apparel, groceries, and health and personal care products. Amazon websites are country-specific but also offer international shipping.</span>
                </div>
                <h3 className='lead manyavar_heading'>The client wanted to study the Market price of the various television models. This was mainly to understand price gaps in the online and offline market.</h3>
            </div>
            <div className='container'>
                <div className='row row-cols-1 row-cols-md-3'>
                    <div className='col-4'>
                        <div style={{ marginRight: "-20px" }}>
                            <img src={side1} alt="" style={{ width: "100%", height: "100%" }} className='card-img-top img-float' />
                        </div>
                    </div>
                    <div className='col-4'>
                        <div style={{ marginLeft: "-30px", marginTop: "-16px" }}>
                            <img src={centerimg} alt="" style={{ width: "100%", height: "100%" }} className='card-img-top img-float' />
                        </div>
                    </div>
                    <div className='col-4'>
                        <div style={{ marginLeft: "-24px" }}>
                            <img src={side2} alt="" style={{ width: "100%", height: "100%" }} className='card-img-top img-float' />
                        </div>
                    </div>
                </div>
            </div>
            <div className='manyavar_heading_next_box'>
                <div className='row row-cols-1 row-cols-lg-3 g-5'>
                    <div className='col-lg-4'>
                        <div className='card h-100' style={{ borderRadius: "20px" }}>
                            <div className='card-body shadow' style={{ borderRadius: "20px" }}>
                                <h3 className='card-title mt-sm-4 text-center' style={{ color: "#007DC1" }}>Objective </h3>
                                <p className='card-text mt-sm-4' style={{ padding: "0rem 1rem 1rem 1rem" }}>
                                    The primary objective here was to get hold of market prices for different television SKUs at various offline stores. Amazon wanted to understand the gaps in the pricing at online and offline marketplaces in order to ensure a fair pricing strategy and increase conversions for selected products.
                                    Additionally, the payment options offered at the stores were checked to analyse the ease of purchasing for the end consumer and compare it to the native payment schemes and options
                                    The main goal here was to divert the market crowd online and increase counter share for Amazon with enhanced customer purchasing experience for shopping goods
                                </p>

                            </div>

                        </div>

                    </div>
                    <div className='col-lg-4'>
                        <div className='card h-100' style={{ borderRadius: "20px" }}>
                            <div className='card-body shadow' style={{ borderRadius: "20px" }}>
                                <h3 className='card-title mt-sm-4 text-center' style={{ color: "#007DC1" }}>Approach </h3>
                                <p className='card-text mt-sm-4' style={{ padding: "0rem 1rem 1rem 1rem" }}>
                                    <b> 1.</b> Here the first step was to identify the most recommended product/brand by interacting with the sales staff. This allowed us to capture the market demand and prioritize focus products <br />
                                    <b> 2.</b> Next was to navigate through the product display and understand the sales pitch in order to capture the information shared to the customer <br />
                                    <b>3. </b>Lastly it was understood what pricing was offered by means of sticker display on the product and then understand the discouts offered by the staff to capture the lowest selling prices <br />
                                    <b>4.</b> Mystery shoppers aged upto 35 years were sent to execute the audit and replicate actual customer scenarios
                                </p>

                            </div>

                        </div>

                    </div>
                    <div className='col-lg-4'>
                        <div className='card h-100' style={{ borderRadius: "20px" }}>
                            <div className='card-body shadow' style={{ borderRadius: "20px" }}>
                                <h3 className='card-title mt-sm-4 text-center' style={{ color: "#007DC1" }}>Outcome</h3>
                                <p className='card-text mt-sm-4' style={{ padding: "0rem 1rem 1rem 1rem" }}>
                                    <b>  1.</b> Insights awere generated on the pricing and discounts offered by various retailers and gaps were subsequently identified <br />
                                    <b> 2.</b> These mystery shoppers had prior knowledge about the various TV features and models so they were able to make genuine inquiries  <br />
                                    <b> 3. </b>Service quality and ongoing market offers and discounts were uncovered. Staff's ability to recommend products, payment options, and source scheme letters. This objective ensured that the prices listed on the stickers of TVs are accurate and aligns with the prices presented online because Ensuring consistency in pricing helps build trust with customers.
                                </p>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
          <NewCommonCards/>
        </div>
    )
}
