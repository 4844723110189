import React from 'react';
import { Col,Row,Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import jmccard from "../../../images/jmccard.jpg";

export default function Retail() {
  return (
    <Container fluid className='homepage_brand_container'>
                <Row className='homepage_brand' style={{paddingTop:"1rem"}}>
                <Col className='homepage_brand_box casestudy_box' style={{ borderRadius: "7px" }} md="4">
                    <Link to="/casestudies/jmc">
                        <div className='homepage_brand_img'>
                            <img src={jmccard} alt="jmcimg" style={{ borderTopLeftRadius: "7px", borderTopRightRadius: "7px",height:"auto" }} />
                        </div>
                        <div className='homepage_brand_text casestudy_brand_text'>
                            <div>
                                <h5 style={{ marginLeft: "1rem",fontWeight:"bold" }}>JMC</h5>
                                <p style={{ margin: "0rem 1rem" }}>The challenge at hand was to elevate JMC's standing and performance within the Indore jewelry market. To achieve this objective, the focus was on...</p>
                                <div style={{ marginTop: "1rem", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0rem 1rem" }}>
                                    <button className="btn btn-md text-center btn-size create_ac_btn">Retail</button>
                                    <div style={{ border: "2px solid black", padding: "3px 7px", borderRadius: "50%" }}>
                                        <i className="fa-solid fa-arrow-right" style={{ marginTop: "3px", fontSize: "1.2rem" }}></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </Link>
                    </Col>
                </Row>
                
            </Container>
  )
}
