import React, { useEffect, useState } from 'react';
import { Col, Row, Table, Modal } from "react-bootstrap";
import "../styles/auditprojectsetup4.css";
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getIncomeText, getCarCost, getGender } from '../utils/constant';
import { postSaveForLaterData, postOrderCreateData, postOrderCompleteData, postOrderFailData } from '../utils/services/PostdataHandle';
import useRazorpay from "react-razorpay";
import base_url from '../utils/config';
import { clearCart } from '../utils/cartSlice';
import { clearStoreData, removeFile } from '../utils/orderdataslice';
import loadingimg from "../images/loading.avif";
import { useFetchAuth } from '../utils/hooks/useFetchAuth';
import pdfDummy from "./../images/pdfDownload.png";

export default function AuditProjectSetup3({ updateStep, currentStep }) {
    const [Razorpay] = useRazorpay();
    const dispatch = useDispatch();
    const token = localStorage.getItem("token");
    const [modalShow, setModalShow] = useState(false);
    const [viewModalShow, setViewModalShow] = useState(false);
    const navigate = useNavigate();
    const [selectedFile, setSelectedFile] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [loading, setLoading] = useState(false);
    const { data, setData } = useFetchAuth("client/client_profile");
    const [fileVisibility, setFileVisibility] = useState({});
    const cartItems = useSelector((store) => store.cart);
    const orderdata = useSelector((store) => store.orderdata);
    const incomeOptionsarr = [];
    const carownedarr = [];
    const genderarr = [];
    for (let option of orderdata.firstPage.inputs.income) {
        incomeOptionsarr.push(getIncomeText(option));
    }
    for (let option of orderdata.firstPage.inputs.car_cost) {
        carownedarr.push(getCarCost(option));
    }
    for (let option of orderdata.firstPage.inputs.gender) {
        genderarr.push(getGender(option));
    }
    const getFutureDate = (days) => {
        const futureDate = new Date();
        futureDate.setDate(futureDate.getDate() + days);
        return futureDate.toLocaleDateString('en-GB');
    };

    const futureDate = getFutureDate(7);
    // ------------------------------------------------------------------------------
    const existingUser = (localStorage.getItem('user_id'));
    const selectedCategoryId = (localStorage.getItem('selectedCategoryId'))
    const auditcountReduxwithId = useSelector((store) => store.orderdata.secondPage.totalAuditsPerStoreWithStoreId);
    // const modifiedauditcountwithId = Object.entries(auditcountReduxwithId).map(([storeId, count]) => ({ store_id: parseInt(storeId), count: parseInt(count) }));
    const modifiedauditcountwithId = Object.entries(auditcountReduxwithId).map(([storeId, count]) => {
        if (count !== "") {
            return { store_id: parseInt(storeId), count: parseInt(count) };
        }
        return null;
    }).filter(item => item !== null);
    const storedInputs = useSelector((store) => store.orderdata.firstPage.inputs);
    const orderId = localStorage.getItem("orderId")
    function excludeProperties(obj, propsToExclude) {
        const newObj = {};
        for (const key in obj) {
            if (!propsToExclude.includes(key)) {
                newObj[key] = obj[key];
            }
        }
        return newObj;
    }
    const propsToExclude = ['describe', 'preference', 'selectedFile'];
    const alignment_factors = excludeProperties(storedInputs, propsToExclude);
    const selectedFiles = useSelector((store) => store.orderdata.firstPage.selectedFile);

    const handlePaymentInfo = () => {
        updateStep(currentStep + 1);
    }
    useEffect(() => {
        const orderIdfromStorage = localStorage.getItem('orderId');
        if (orderIdfromStorage) {
            getFileData(`client/mp_order/${orderIdfromStorage}/attachment`)
            // setOrderId(orderIdfromStorage);
        }
    }, [])
    const getFileData = async (url) => {
        try {
            const headers = {
                'Authorization': `Token ${token}`,
                'Content-Type': 'application/json'
            };

            const options = {
                method: 'GET',
                headers: headers
            };

            const response = await fetch(base_url + url, options);

            if (response.ok) {
                const result = await response.json();

                if (result.error) {
                    alert("error");
                } else if (Array.isArray(result) && result.length === 0) {
                    setSelectedFile([]);
                }
                else {
                    setUploading(true);
                    setSelectedFile([{
                        attachmentId: result.id,
                        fileUrl: result.direct_url,
                        fileName: result.file_name,
                        fileType: result.mime_type,
                    }]);

                }
                ;
            } else {
                console.error('API request failed:', response.status, response.statusText);
            }
        } catch (error) {
            console.error('An error occurred while fetching the API:', error);
        } finally {
            setUploading(false);
        }
    };
   
    const handlePayment = () => {
        setLoading(true);
        const fileData = selectedFiles.length > 0
            ? {
                file_name: selectedFiles[0].fileName,
                file_size: selectedFiles[0].fileSize,
                file_type: selectedFiles[0].fileType,
            }
            : {};
        const requestData = {
            no_of_response: cartItems.totalQuantity,
            solution: cartItems.items[0].id,
            describe: storedInputs.describe,
            file: fileData,
            user: +existingUser,
            category: +selectedCategoryId,
            status: "DRAFT",
            alignment_factors: alignment_factors,
            store: modifiedauditcountwithId,
        };
        const promise = postSaveForLaterData(requestData, `client/mp_order/${orderId}`);
        promise.then(response => response.json())

            .then(data => {
                const orderCreateRequestData = {
                    mp_order_id: data.id,
                    no_of_response: cartItems.totalQuantity,
                    solution: cartItems.items[0].id,
                    describe: storedInputs.describe,
                    file: fileData,
                    user: +existingUser,
                    category: +selectedCategoryId,
                    status: "DRAFT",
                    alignment_factors: alignment_factors,
                    store: modifiedauditcountwithId,
                }
                const promise1 = postOrderCreateData(orderCreateRequestData, "client/order/created");
                promise1.then(response => response.json())
                    .then(data => {
                        const order_id = data.id;
                        const options = {
                            key:'rzp_live_5JGzDGrzqTtLPp', 
                            // key: "rzp_test_5ws7pWCryHQLI3",

                            name: "FloorWalk.in",
                            description: "Test Transaction",
                            order_id: order_id,

                            handler: function (response) {
                                // (
                                //     response.razorpay_payment_id,
                                //     response.razorpay_order_id,
                                //     response.razorpay_signature
                                //  )
                                const completeOrderData = {
                                    payment_id: response.razorpay_payment_id,
                                    order_id: response.razorpay_order_id,
                                    signature: response.razorpay_signature,
                                    mp_order_id: orderCreateRequestData.mp_order_id

                                };
                                const promise1 = postOrderCompleteData(completeOrderData, "client/order/complete");
                                promise1.then(response => response.json())
                                    .then((data) => {
                                        if (data.status === "success") {
                                            updateStep(currentStep + 1)
                                            dispatch(clearCart());
                                            dispatch(clearStoreData());
                                            dispatch(removeFile());
                                            localStorage.removeItem('orderId');
                                            return "Order completed successfully";
                                        } else {
                                            alert("payment failed")
                                            throw new Error("Failed to complete order");
                                        }
                                    })
                                    .catch((error) => {
                                        console.error("Error:", error);
                                        throw error;
                                    });

                            },
                            prefill: {
                                name: `${data?.brand}`,
                                email: `${data?.email}`,
                                contact:  `${data.mobile_number}`,
                            },
                            notes: {
                                address: "Razorpay Corporate Office",
                            },
                            theme: {
                                color: "#3399cc",
                            },
                        };

                        const rzp1 = new Razorpay(options);

                        rzp1.on("payment.failed", function (response) {
                            const failOrderData = {
                                mp_order_id: orderCreateRequestData.mp_order_id

                            };
                            const promise1 = postOrderFailData(failOrderData, "client/order/payment_failed");
                            promise1.then(response => response.json())
                                .then((data) => {
                                })
                                .catch((error) => {
                                    console.error("Error:", error);
                                    throw error;
                                });
                            alert("payment failed", response.error.code);
                            // alert("109", response.error.description);
                            // alert(response.error.source);
                            // alert(response.error.step);
                            // alert(response.error.reason);
                            // alert(response.error.metadata.order_id);
                            // alert(response.error.metadata.payment_id);
                        });

                        rzp1.open();
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    }).finally(() => {
                        setLoading(false);
                    });
            })
            .catch(error => {
                console.error('Error:', error);
            });

    }
    // const nonZeroStoreCount = Object.entries(orderdata.secondPage.totalAuditsPerStoreWithStoreId).reduce((count, [storeId, value]) => {
    //     if (value !== "" || value !== "0") {
    //         return count + 1;
    //     }
    //     return count;
    // }, 0);
    const nonZeroStoreCount = modifiedauditcountwithId.length;
    const toggleFileVisibility = (fileUrl) => {
        setFileVisibility((prevState) => ({
            ...prevState,
            [fileUrl]: !prevState[fileUrl],
        }));
    };
    return (
        <div>
            <div className='project_setup1_main_container'>
                <div className='project_setup1_container project_setup1_container_third' >
                    <Col md="12">
                        <h2>Final Check!</h2>
                    </Col>
                    <Col md="12">
                        <p>Take a final look...</p>
                    </Col>
                    <div className='final_check_container'>
                        <Col md="6" className='final_check_container_left'>
                            <h6>Estimated Date of Project Delivery : <b>{futureDate}</b></h6>
                            <h5><b>{cartItems?.items[0]?.name}</b></h5>
                            <div className='final_check_oneline'>
                                <h6>Total <b>{cartItems.totalQuantity} Audits</b> </h6>
                            </div>
                            <h6>Audience Demographics: <b>{orderdata.firstPage.inputs.preference ?
                                <button
                                    style={{ background: "transparent", border: "none", outline: "none", color: "#007DC1", fontWeight: "bold" }}
                                    onClick={() =>
                                        setViewModalShow(
                                            true,
                                        )}
                                >
                                    View</button>
                                : "NA"}</b> </h6>
                            <h6>Stores Added: <b>{nonZeroStoreCount}</b></h6>

                            <div className='final_review_description'>
                                <h6>Description:</h6>
                                <p> <b>{orderdata.firstPage.inputs.describe}</b></p>
                            </div>
                            <div> <b> Uploaded Files:</b>
                                {uploading && (
                                    <div>
                                        <img src={loadingimg} alt="Loading..." height="120px" />
                                    </div>
                                )}
                                {selectedFile.length > 0 ? selectedFile.map((file, index) => (
                                    <div key={index}>
                                        <div className='d-flex' style={{ marginTop: "1rem",cursor:"pointer" }}>
                                            <p onClick={() => toggleFileVisibility(file.fileName)} className='selected_files_name'  style={{cursor:"pointer"}}>
                                                {file.fileName}
                                            </p>
                                        </div>
                                        { file.fileType === "application/pdf" ? (
                                            <a href={file.fileUrl} download>
                                                <img
                                                    src={pdfDummy}
                                                    alt={file?.fileName}
                                                    style={{ display: fileVisibility[file.fileName] ? 'none' : 'block', objectFit: "cover", }}
                                                />
                                            </a>
                                        ) : (
                                            <>
                                                <img
                                                    src={file?.fileUrl}
                                                    alt={file?.fileName}
                                                    width="300"
                                                    style={{ display: fileVisibility[file.fileName] ? 'none' : 'block', objectFit: "cover" }}
                                                />
                                            </>
                                        )}   
                                    </div>
                                )) : <p> No files found... </p>}
                            </div>
                        </Col>
                        <Col md="6" className='final_check_container_right'>
                            <h5><b>Grand Total</b></h5>
                            <p>1 ITEM</p>
                            <div className='final_check_total_price_details table-responsive'>
                                <Table className="text-center table">
                                    <thead>
                                        <tr>
                                            <th>Sr. No.</th>
                                            <th>Item</th>
                                            <th>Qty </th>
                                            <th>Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>{cartItems?.items[0]?.name} </td>
                                            <td>{cartItems?.totalQuantity}</td>
                                            {/* <td>INR. {cartItems?.items[0]?.price} /-</td> */}
                                            {localStorage.getItem("selectedCountry")?
                                            localStorage.getItem("selectedCountry") === "IN" ?
                                                <td>INR. {cartItems?.items[0]?.price_INR}</td>
                                                :
                                                <td> USD. {cartItems?.items[0]?.price_USD}</td>
                                                :
                                                <td>INR. {cartItems?.items[0]?.price_INR}</td>
                                            }
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>

                            <div className='final_check_gst_price_box'>
                                <div className='final_check_gst_price'>
                                    <p>Total</p>
                                    {/* <p>INR. {cartItems?.totalAmount} /-</p> */}
                                    {localStorage.getItem("selectedCountry") ?
                                    localStorage.getItem("selectedCountry") === "IN" ?
                                        <td>INR. {cartItems?.totalAmount}</td>
                                        :
                                        <td>USD. {cartItems?.totalAmount}</td>
                                        :
                                        <td>INR. {cartItems?.totalAmount}</td>
                                    }
                                </div>
                                <div className='final_check_gst_price'>
                                    <p>GST {cartItems?.items[0]?.tax?.rate}%</p>

                                    {localStorage.getItem("selectedCountry") ?
                                    localStorage.getItem("selectedCountry") === "IN" ?
                                        <p>INR. {(cartItems?.totalAmount * cartItems?.items[0]?.tax?.rate) / 100} /-</p>
                                        :
                                        <p>USD. {(cartItems?.totalAmount * cartItems?.items[0]?.tax?.rate) / 100} /-</p>
                                        :
                                        <p>INR. {(cartItems?.totalAmount * cartItems?.items[0]?.tax?.rate) / 100} /-</p>
                                    }
                                </div>
                            </div>
                            <div className='final_check_gst_price_box'>
                                <div className='final_check_gst_price'>
                                    <p><b>Grand Total</b></p>

                                    {localStorage.getItem("selectedCountry") ?
                                    localStorage.getItem("selectedCountry") === "IN" ?
                                        <p><b>INR. {cartItems?.totalAmount + (cartItems?.totalAmount * cartItems?.items[0]?.tax?.rate) / 100} /-</b></p>
                                        :
                                        <p><b>USD. {cartItems?.totalAmount + (cartItems?.totalAmount * cartItems?.items[0]?.tax?.rate) / 100} /-</b></p>
                                        :
                                        <p><b>INR. {cartItems?.totalAmount + (cartItems?.totalAmount * cartItems?.items[0]?.tax?.rate) / 100} /-</b></p>
                                    }
                                </div>
                            </div>
                        </Col>
                    </div>
                </div>

            </div>
            <div className='project_setup_btn_box project_setup_btn_box_paybtn_box' >
                <div className='project_setup_btn_box_left nxtbtn_box'>
                    {currentStep > 0 &&
                        <button className="btn btn-md text-center btn-size create_ac_btn nxtbtn_box"
                            onClick={() => updateStep(currentStep - 1)}
                        >Previous</button>
                    }

                </div>
                {loading ?

                    <button className="btn btn-md text-center btn-size create_ac_btn nxtbtn_box" type="button" disabled style={{ fontSize: "1rem", background: "#007DC1", color: "white", }}>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: ".5rem" }}></span>
                        Processing...
                    </button>

                    :

                    <div className="btn btn-md text-center btn-size create_ac_btn nxtbtn_box"
                        onClick={handlePayment}
                    >Make Payment</div>
                }
                <div className='nxtbtn_smlscrn_box_setup2'>
                    {currentStep > 0 &&
                        <button className="btn btn-md text-center btn-size create_ac_btn nxtbtn_smlscrn"
                            onClick={() => updateStep(currentStep - 1)}
                        >Previous</button>
                    }
                    {loading ?

                        <button className="btn btn-md text-center btn-size create_ac_btn nxtbtn_smlscrn" type="button" disabled style={{ fontSize: "1rem", background: "#007DC1", color: "white", }}>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: ".5rem" }}></span>
                            Processing...
                        </button>

                        :

                        <div className="btn btn-md text-center btn-size create_ac_btn nxtbtn_smlscrn"
                            onClick={handlePayment}
                        >Make Payment</div>
                    }
                </div>
            </div>

            <Modal
                show={modalShow}
                centered
                size='lg'
                style={{ marginTop: '0px' }}
                scrollable={true}
                className='modal-dialog-scrollable p-6'
                onHide={() => setModalShow(false)}
            >
                <div
                    style={{
                        width: '100%',
                        padding: "2rem 3rem",
                        border: 'none',
                    }}
                >
                    <Col>
                        <Row>
                            <h5 className='setup_regs_modal_heading'>MAKE PAYMENT</h5>
                        </Row>
                        <Row>
                            <Col md="6">
                                <p className="registration_form_content">
                                    <label htmlFor="registration_wmail">Email <span>*</span></label>
                                    <input
                                        type="email"
                                        id="registration_wmail"
                                        required
                                    />
                                </p>
                            </Col>
                            <Col md="2">
                                <p className="registration_form_content">
                                    <label htmlFor="registration_mobile">Mobile <span>*</span></label>
                                    <select name="country_codes" id="country_codes">
                                        <option value="+91">+91</option>
                                        <option value="saab">Saab</option>
                                        <option value="mercedes">Mercedes</option>
                                        <option value="audi">Audi</option>
                                    </select>
                                </p>
                            </Col>
                            <Col md="4">
                                <p className="registration_form_content">
                                    <label htmlFor="registration_mobile" style={{ visibility: "hidden" }}>Mobile</label>
                                    <input
                                        type="tel"
                                        id="registration_mobile"
                                    />
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <p className="registration_form_content">
                                    <label htmlFor="registration_pswrd">Password <span>*</span></label>
                                    <input
                                        type="password"
                                        id="registration_pswrd"
                                        required
                                    />
                                </p>
                            </Col>
                            <Col md="6">
                                <p className="registration_form_content">
                                    <label htmlFor="registration_cpswrd">Confirm Password <span>*</span></label>
                                    <input
                                        type="password"
                                        id="registration_cpswrd"
                                        required
                                    />
                                </p>
                            </Col>
                        </Row>
                        <div className='d-flex justify-content-center align-items-center'>
                            <button
                                className="btn mb-5 mt-3 btn-md text-center btn-size create_ac_btn"
                                onClick={handlePaymentInfo}
                            >
                                Pay
                            </button>
                        </div>
                    </Col>

                </div>
            </Modal>
            <Modal
                show={viewModalShow}
                centered
                size='lg'
                style={{ marginTop: '0px' }}
                // scrollable={true}
                className='modal-dialog-scrollable p-6  modal_mobile'
                onHide={() => setViewModalShow(false)}
            >
                <div
                    style={{
                        width: '100%',
                        padding: "2rem 3rem",
                        border: 'none',
                    }}
                    className='preferenceviewbox'
                >
                    <div style={{ position: "absolute", right: "2rem", top: ".8rem", cursor: "pointer", }}
                        onClick={() => setViewModalShow(false)}
                    ><i className="fa-solid fa-x"></i></div>
                    <div>
                        <h6><b>Occupation</b></h6>
                        {orderdata.firstPage.inputs.occupation.length > 0 ? orderdata.firstPage.inputs.occupation.map((item, index) => {
                            return (
                                <p style={{ fontSize: ".8rem" }} key={index}><i className="fa-solid fa-circle" style={{ fontSize: ".4rem" }} /><span style={{ marginLeft: ".5rem" }}>{item}</span></p>
                            )
                        })
                            : <p style={{ fontSize: ".8rem" }} ><i className="fa-solid fa-circle" style={{ fontSize: ".4rem" }} /><span style={{ marginLeft: ".5rem" }}>No data</span></p>}
                    </div>
                    <div>
                        <h6><b>Age Range</b></h6>
                        {orderdata.firstPage.inputs.auditor_age_range != "" ? <p style={{ fontSize: ".8rem" }}><i className="fa-solid fa-circle" style={{ fontSize: ".4rem" }} /><span style={{ marginLeft: ".5rem" }}>{orderdata.firstPage.inputs.auditor_age_range}</span></p> : <p style={{ fontSize: ".8rem" }} ><i className="fa-solid fa-circle" style={{ fontSize: ".4rem" }} /><span style={{ marginLeft: ".5rem" }}>No data</span></p>}
                    </div>
                    <div>
                        <h6><b>Income Range</b></h6>
                        {incomeOptionsarr.length > 0 ? incomeOptionsarr.map((item, index) => {
                            return (
                                <p style={{ fontSize: ".8rem" }} key={index}><i className="fa-solid fa-circle" style={{ fontSize: ".4rem" }} /><span style={{ marginLeft: ".5rem" }}>{item}</span></p>
                            )
                        })
                            : <p style={{ fontSize: ".8rem" }} ><i className="fa-solid fa-circle" style={{ fontSize: ".4rem" }} /><span style={{ marginLeft: ".5rem" }}>No data</span></p>}
                    </div>
                    <div>
                        <h6><b>Car Owned</b></h6>
                        {carownedarr.length > 0 ? carownedarr.map((item, index) => {
                            return (
                                <p style={{ fontSize: ".8rem" }} key={index}><i className="fa-solid fa-circle" style={{ fontSize: ".4rem" }} /><span style={{ marginLeft: ".5rem" }}>{item}</span></p>
                            )
                        }) : <p style={{ fontSize: ".8rem" }} ><i className="fa-solid fa-circle" style={{ fontSize: ".4rem" }} /><span style={{ marginLeft: ".5rem" }}>No data</span></p>
                        }
                    </div>
                    <div>
                        <h6><b>Gender</b></h6>
                        {genderarr.length > 0 ? genderarr.map((item, index) => {
                            return (
                                <p style={{ fontSize: ".8rem" }} key={index}><i className="fa-solid fa-circle" style={{ fontSize: ".4rem" }} /><span style={{ marginLeft: ".5rem" }}>{item}</span></p>
                            )
                        }) : <p style={{ fontSize: ".8rem" }} ><i className="fa-solid fa-circle" style={{ fontSize: ".4rem" }} /><span style={{ marginLeft: ".5rem" }}>No data</span></p>
                        }
                    </div>
                    <div>
                        <h6><b>Must Have Interest/ Experience</b></h6>
                        {orderdata.firstPage.inputs.interest_area.length ? orderdata.firstPage.inputs.interest_area.map((item, index) => {
                            return (
                                <p style={{ fontSize: ".8rem" }} key={index}><i className="fa-solid fa-circle" style={{ fontSize: ".4rem" }} /><span style={{ marginLeft: ".5rem" }}>{item}</span></p>
                            )
                        }) : <p style={{ fontSize: ".8rem" }} ><i className="fa-solid fa-circle" style={{ fontSize: ".4rem" }} /><span style={{ marginLeft: ".5rem" }}>No data</span></p>
                        }
                    </div>
                </div>
            </Modal>
        </div>
    )
}
