import AuditProjectSetup1 from "./AuditProjectSetup1";
import AuditProjectSetup2 from "./AuditProjectSetup2";
import AuditProjectSetup3 from "./AuditProjectSetup3";
import AuditProjectSetupFinal from "./AuditProjectSetupFinal";
import { useState, useEffect, useLayoutEffect } from "react";
import { Col, Modal,Row } from "react-bootstrap";
import Save4LaterModal from "./Save4LaterModal";
import "../styles/stepprogressbar.css";
import { useDispatch} from "react-redux";
import { Link,useLocation, useNavigate, useParams } from "react-router-dom";
import { clearCart } from "../utils/cartSlice";
import { setCurrentStepRedux } from "../utils/stepSlice";
import { clearStoreData, removeFile } from "../utils/orderdataslice";


// const handleBackToProduct = () => {
//   dispatch(clearCart());
//   dispatch(clearStoreData());
//   dispatch(removeFile());
//   localStorage.removeItem('orderId');
//   navigate(`/public_solution/${url_structure}`,{ state: {id:id}} )

//   }

export default function StepProgressBar() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  // const id = location.state;
  if (location.state) {
    localStorage.setItem('cartItemId', location.state);
}
const id = localStorage.getItem('cartItemId');
  const {url_structure} = useParams();
  const steps = [AuditProjectSetup1, AuditProjectSetup2, AuditProjectSetup3, AuditProjectSetupFinal];
  const labelArray = ['Basic Details', 'Store Locations', 'Review and Pay', 'All Done!'];
  const [currentStep, setCurrentStep] = useState(0);
  const [isPaymentCompleted, setIsPaymentCompleted] = useState(false);
  const [saveForLaterExitClicked, setSaveForLaterExitClicked] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const handleBackToProduct = () => {
    dispatch(clearCart());
    dispatch(clearStoreData());
    dispatch(removeFile());
    localStorage.removeItem('orderId');
    navigate(`/public_solution/${url_structure}`,{ state: {id:id}} )
   
  }

  const goBack = () => {
    dispatch(clearCart());
    dispatch(clearStoreData());
    dispatch(removeFile());
    localStorage.removeItem('orderId');
    window.history.back();
  }
  function modaloff() {
    setModalShow(false)
  }
  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', (event) => {
      event.preventDefault();
      setModalShow(true)
    });
  }, []);
 
  function updateStep(step) {
    setCurrentStep(step);
    dispatch(setCurrentStepRedux(step));
    setIsPaymentCompleted(false);
  }

  const StepComponent = steps[currentStep];

  return (
    <>
      <Modal
        show={modalShow}
        centered
        size='lg'
        style={{ marginTop: '0px' }}
        scrollable={true}
        className='modal-dialog-scrollable p-6'
      >
        <div
          style={{
            width: '100%',
            padding: "2rem 3rem",
            border: 'none',
          }}
        >
          <div style={{display:"flex",justifyContent:"flex-end"}}>
          <i className="fa-solid fa-x" onClick={modaloff}/>
          </div>
          <h5 style={{margin:"1rem 0rem 2rem 0rem"}}>Are you Leaving the Page? If you didn't Save details, Save for Later</h5>
          <div style={{display:"flex",justifyContent:"center",gap:"2rem"}}>
          <button onClick={goBack} className="btn btn-md text-center btn-size create_ac_btn">Exit Page</button>
            <Save4LaterModal saveForLaterExitClicked={true} goBack={goBack}/>
             </div>
        </div>
      </Modal>
      
      {currentStep===0 ? 
      <Row className="stepper_btn_container">
      <Col md="6">
        <div className='back_market' style={{cursor:"pointer"}} 
        onClick={handleBackToProduct}
        >
          <i className="fa-solid fa-arrow-left"></i>
          <span style={{marginLeft:".5rem"}}>Back to the Product</span>
          </div>
      </Col>
      {!isPaymentCompleted &&
        <Col md="6" className="savefr_ltr_btn_box">
          <Save4LaterModal saveForLaterExitClicked={false} goBack={goBack}/>
        </Col>
      }
      </Row>
        :
        <div>
        {!isPaymentCompleted &&
          <Col className="savefr_ltr_btn_box savefr_ltr_btn_box_noback ">
            <Save4LaterModal saveForLaterExitClicked={false} goBack={goBack}/>
          </Col>
        }
         </div>
      }
      
      <div>
        <Col md="12" className={isPaymentCompleted ? "stepwrapper_col" : "stepwrapper_col1"}>
          <div className="stepWrapper">
            {labelArray.map((item, index) => (
              <div className={"stepBlock" + ((currentStep > index || isPaymentCompleted) ? " selected" : "")} key={index}>
                <div className="circleWrapper">
                  <div className="circle">
                    {(currentStep > index || isPaymentCompleted) && <i className="fa-solid fa-check"></i>}
                  </div>
                  <p className="stepper_info">{item}</p>
                </div>
              </div>
            ))}
            <div className='line'></div>
          </div>
        </Col>
      </div>

      {StepComponent &&
        <StepComponent updateStep={updateStep} currentStep={currentStep} setIsPaymentCompleted={setIsPaymentCompleted} />
      }
      
    </>
  );
}

