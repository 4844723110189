import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import "../../../styles/dashprofile.css";
import { Col, Row, Alert } from 'react-bootstrap';
import base_url from "../../../utils/config";
import { useFetchAuth } from '../../../utils/hooks/useFetchAuth';
import { postClientProfileData } from '../../../utils/services/PostdataHandle';
import loadingImg from "../../../images/loading.avif";

export default function DashProfile() {
    const navigate = useNavigate();
    const existingUser = localStorage.getItem('user_id');
    const [categoryListUrl, setCategoryListUrl] = useState(base_url + "client/client_profile");
    const { data, setData, isLoading } = useFetchAuth("client/client_profile");

    const [formData, setFormData] = useState({
        brand: "",
        username: "",
        mobile_number: "",
        first_name: "",
        last_name: "",
        gst: "",
        address: "",
    });
    const [errorMessages, setErrorMessages] = useState({
        brandError: '',
        usernameError: '',
        mobile_numberError: '',
        first_nameError: '',
        last_nameError: '',
        gstError: '',
        addressError: '',
    });

    const [AlertMessage, setAlertMessage] = useState(false);
    const [FailAlertMessage, setFailAlertMessage] = useState(false);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        setFormData({
            brand: data.brand || '',
            username: data.email || '',
            mobile_number: data.mobile_number || '',
            first_name: data.first_name || '',
            last_name: data.last_name || '',
            gst: data.gst || '',
            address: data.address || '',
        });
    }, [data]);
    useEffect(() => {
        if (AlertMessage || FailAlertMessage) {
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
        }
    }, [AlertMessage,FailAlertMessage])
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        setErrorMessages((prevErrors) => ({
            ...prevErrors,
            [`${name}Error`]: '',
        }));

        if (name === 'gst') {
            setErrorMessages((prevErrors) => ({
                ...prevErrors,
                gstError: '',
            }));
        }
    };

    const validateGSTNumber = (gstNumber) => {
        const gstPattern = /^(0[1-9]|[1-2][0-9]|3[0-8])[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
        return gstPattern.test(gstNumber);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setHasError(false);

        if (!formData.first_name) {
            setErrorMessages((prevErrors) => ({
                ...prevErrors,
                first_nameError: "First name can't be empty",
            }));
            setTimeout(() => {
                setErrorMessages((prevErrors) => ({
                    ...prevErrors,
                    first_nameError: "",
                }));
            }, 1700)
            setHasError(true);
        }

        if (!formData.brand) {
            setErrorMessages((prevErrors) => ({
                ...prevErrors,
                brandError: "Company name can't be empty",
            }));
            setTimeout(() => {
                setErrorMessages((prevErrors) => ({
                    ...prevErrors,
                    brandError: "",
                }));
            }, 1700)
            setHasError(true);
        }

        if (!formData.mobile_number) {
            setErrorMessages((prevErrors) => ({
                ...prevErrors,
                mobile_numberError: "Mobile can't be empty",
            }));
            setTimeout(() => {
                setErrorMessages((prevErrors) => ({
                    ...prevErrors,
                    mobile_numberError: "",
                }));
            }, 1700)
            setHasError(true);
        }

        const isValidMobileNumber = /^[0-9]{10}$/.test(formData.mobile_number);

        if (!formData.mobile_number || !isValidMobileNumber) {
            setErrorMessages((prevErrors) => ({
                ...prevErrors,
                mobile_numberError: "Mobile number should be a 10-digit number",
            }));
            setTimeout(() => {
                setErrorMessages((prevErrors) => ({
                    ...prevErrors,
                    mobile_numberError: "",
                }));
            }, 1700)
            setHasError(true);
        }

        if (formData.gst && !validateGSTNumber(formData.gst)) {
            setErrorMessages((prevErrors) => ({
                ...prevErrors,
                gstError: "Invalid GST Number",
            }));
            setTimeout(() => {
                setErrorMessages((prevErrors) => ({
                    ...prevErrors,
                    gstError: "",
                }));
            }, 1700)
            setHasError(true);
        }

        if (
            formData.first_name &&
            formData.brand &&
            formData.username &&
            formData.mobile_number &&
            isValidMobileNumber &&
            (!formData.gst || validateGSTNumber(formData.gst))
        ) {
            const requestData = {
                first_name: formData.first_name,
                last_name: formData.last_name,
                brand: formData.brand,
                mobile_number: formData.mobile_number,
                gst: formData.gst,
                address: formData.address,
                user_id: existingUser,
            };

            const promise = postClientProfileData(requestData);
            promise
                .then((response) => {
                    if (response.status === 200 || response.status === 204) {
                        setAlertMessage(true);
                        setTimeout(() => {
                            setAlertMessage(false);
                        }, 1500);
                    } else {
                        setFailAlertMessage(true);
                        setTimeout(() => {
                            setFailAlertMessage(false);
                        }, 1500);
                    }
                })
                .catch((error) => {
                    setFailAlertMessage(true);
                    setTimeout(() => {
                        setFailAlertMessage(false);
                    }, 1500);
                });
        }
    };

    return (
        <div>
            {AlertMessage ? (
                <Alert variant="success" onClose={() => setAlertMessage(false)} dismissible style={{ textAlign: "center" }}>
                    Your Profile is updated successfully
                </Alert>
            ) : null}
            {FailAlertMessage ? (
                <Alert variant="danger" onClose={() => setFailAlertMessage(false)} dismissible style={{ textAlign: "center" }}>
                    Your Profile is not updated
                </Alert>
            ) : null}

            <div className='profile_detail_box'>
                {isLoading ?
                    <div className='getStarted'>
                        <img src={loadingImg} alt="Loading..." style={{ width: "300px" }} />
                    </div>
                    :
                    <form onSubmit={handleSubmit}>
                        <Row>
                            <Col md="6">
                                <p className="registration_form_content">
                                    <label>First Name<span>*</span></label>
                                    <input
                                        type="text"
                                        name='first_name'
                                        value={formData.first_name}
                                        onChange={handleChange}
                                        className={hasError ? "error_border" : "success_border"}
                                    />
                                    {errorMessages.first_nameError && (
                                        <span className="error_message">{errorMessages.first_nameError}</span>
                                    )}
                                </p>
                            </Col>
                            <Col md="6">
                                <p className="registration_form_content">
                                    <label>Last Name</label>
                                    <input
                                        type="text"
                                        name='last_name'
                                        value={formData.last_name}
                                        onChange={handleChange}
                                        className={hasError ? "error_border" : "success_border"}
                                    />
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <p className="registration_form_content">
                                    <label>Brand Name<span>*</span></label>
                                    <input
                                        type="text"
                                        name='brand'
                                        value={formData.brand}
                                        onChange={handleChange}
                                        className={hasError ? "error_border" : "success_border"}
                                    />
                                    {errorMessages.brandError && (
                                        <span className="error_message">{errorMessages.brandError}</span>
                                    )}
                                </p>
                            </Col>
                            <Col md="6">
                                <p className="registration_form_content">
                                    <label>GST Number</label>
                                    <input
                                        type="text"
                                        name='gst'
                                        value={formData.gst}
                                        onChange={handleChange}
                                        className={hasError ? "error_border" : "success_border"}
                                    />
                                    {errorMessages.gstError && (
                                        <span className="error_message">{errorMessages.gstError}</span>
                                    )}
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <p className="registration_form_content">
                                    <label>Company Address</label>
                                    <input
                                        type="text"
                                        name='address'
                                        value={formData.address}
                                        onChange={handleChange}
                                        className={hasError ? "error_border" : "success_border"}
                                    />
                                </p>
                            </Col>
                            <Col md="2" className='bigscrn_mobilenumber'>
                                <p className="registration_form_content">
                                    <label htmlFor="registration_mobile_number">Mobile<span>*</span></label>
                                    <select name="country_codes" id="country_codes">
                                        <option value="+91">+91</option>
                                    </select>
                                </p>
                            </Col>
                            <Col md="4" className='bigscrn_mobilenumber'>
                                <p className="registration_form_content">
                                    <label style={{ visibility: "hidden" }}>Mobile</label>
                                    <input
                                        type="tel"
                                        value={formData.mobile_number}
                                        name='mobile_number'
                                        onChange={handleChange}
                                        className={hasError ? "error_border" : "success_border"}
                                    />
                                    {errorMessages.mobile_numberError && (
                                        <span className="error_message">{errorMessages.mobile_numberError}</span>
                                    )}
                                </p>
                            </Col>
                            {/* ------------------------------------------MOBILE VIEW------------------------------------------- */}
                            <Col md="6" className='smallscrn_mobilenumber'>
                                <label>Mobile <span style={{ color: "red" }}>*</span></label>
                                <p className="registration_form_content" style={{ flexDirection: "row" }}>
                                    <select name="country_codes" id="country_codes" style={{ width: "20%", padding: "0px", paddingLeft: "5px" }}>
                                        <option value="+91">+91</option>
                                    </select>
                                    <input
                                        type="tel"
                                        value={formData.phone}
                                        style={{ width: "80%" }}
                                        name='phone'
                                        onChange={handleChange}
                                        className={hasError ? "error_border" : "success_border"}
                                    />
                                    {errorMessages.phoneError && (
                                        <span className="error_message">{errorMessages.phoneError}</span>
                                    )}
                                </p>
                            </Col>
                            {/* ------------------------------------------------------------------------------------------------------------ */}
                        </Row>
                        <Row>
                            <Col md="6">
                                <p className="registration_form_content">
                                    <label>Email</label>
                                    <input
                                        type="email"
                                        name='username'
                                        value={formData.username}
                                        onChange={handleChange}
                                        disabled={true}
                                        style={{ color: "gray" }}
                                        className={hasError ? "error_border" : "success_border"}
                                    />
                                </p>
                            </Col>
                        </Row>
                        <button
                            className="btn mb-5 mt-3 btn-md text-center btn-size create_ac_btn"
                        >
                            Update Details
                        </button>
                    </form>
                }
            </div>
        </div>
    )
}
