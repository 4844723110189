import React, { useEffect, useState } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import indicard from "../../../images/indicard.jpg";
import indicenter from "../../../images/indicenter.png";
import indiside1 from "../../../images/indiside1.png";
import indiside2 from "../../../images/indiside2.png";
import side1 from "../../../images/side1.png";
import side2 from "../../../images/side2.png";
import centerimg from "../../../images/Centre.jpg";
import amazonLogo from "../../../images/Amazon_logo.png";
import CommonCards from './CommonCards';
import NewCommonCards from './NewCommonCards';


export default function Indyverse() {
    const [isReadMore, setIsReadMore] = useState(false);
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, []);

    return (
        <div>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: "3rem", marginTop: "1rem" }}>
                <img src={indicard} alt="amazon_logo" style={{width:"170px"}}/>
                <div className='lead manyavar_heading_text'>
                    <span>Indyverse is a global fashion wear online store for men and women. Indyverse website show cases accesseries, footwear, clothing, bags of different brands on its website</span>
                </div>
                <h3 className='lead manyavar_heading'>The challenge at hand was to bolster sales by leveraging customer reviews and ratings as a means to enhance brand visibility in the target market. </h3>
            </div>
            <div className='container'>
                <div className='row row-cols-1 row-cols-md-3'>
                    <div className='col-4'>
                        <div style={{ marginRight: "-20px" }}>
                            <img src={indiside1} alt="" style={{ width: "100%", height: "100%" }} className='card-img-top img-float' />
                        </div>
                    </div>
                    <div className='col-4'>
                        <div style={{ marginLeft: "-30px", marginTop: "-16px" }}>
                            <img src={indicenter} alt="" style={{ width: "100%", height: "100%" }} className='card-img-top img-float' />
                        </div>
                    </div>
                    <div className='col-4'>
                        <div style={{ marginLeft: "-24px" }}>
                            <img src={indiside2} alt="" style={{ width: "100%", height: "100%" }} className='card-img-top img-float' />
                        </div>
                    </div>
                </div>
            </div>
            <div className='manyavar_heading_next_box'>
                <div className='row row-cols-1 row-cols-lg-3 g-5'>
                    <div className='col-lg-4'>
                        <div className='card h-100' style={{ borderRadius: "20px" }}>
                            <div className='card-body shadow' style={{ borderRadius: "20px" }}>
                                <h3 className='card-title mt-sm-4 text-center' style={{ color: "#007DC1" }}>Objective </h3>
                                <p className='card-text mt-sm-4' style={{ padding: "0rem 1rem 1rem 1rem" }}>
                                <b> 1.</b> The goal was to generate sales through reviews and ratings  <br />
                                    <b> 2.</b> The objective of the entire activity is to increase sales and create brand awareness in the market among the target customers.  <br />
                                    <b>3. </b>They decided to address this objective by incentivizing sales received by our panel of reviewers, in turn, asking them to rate and review the product on their native website, Instagram, as well as Google.  <br />
                                </p>

                            </div>

                        </div>

                    </div>
                    <div className='col-lg-4'>
                        <div className='card h-100' style={{ borderRadius: "20px" }}>
                            <div className='card-body shadow' style={{ borderRadius: "20px" }}>
                                <h3 className='card-title mt-sm-4 text-center' style={{ color: "#007DC1" }}>Approach </h3>
                                <p className='card-text mt-sm-4' style={{ padding: "0rem 1rem 1rem 1rem" }}>
                                    <b> 1.</b> The goal was to generate sales through reviews and ratings. <br />
                                    <b> 2.</b>The objective of the entire activity is to increase sales and create brand awareness in the market among the target customers.  <br />
                                    <b> 3.</b>They decided to address this objective by incentivizing sales received by our panel of reviewers, in turn, asking them to rate and review the product on their native website, Instagram, as well as Google.  <br />
                                </p>

                            </div>

                        </div>

                    </div>
                    <div className='col-lg-4'>
                        <div className='card h-100' style={{ borderRadius: "20px" }}>
                            <div className='card-body shadow' style={{ borderRadius: "20px",position:"relative" }}>
                                <h3 className='card-title mt-sm-4 text-center' style={{ color: "#007DC1" }}>Outcome</h3>
                                <p className='card-text mt-sm-4' style={{ padding: "0rem 1rem 1rem 1rem" }}>
                                    <b>  1.</b> The mystery shopper were asked to order stuff from the indyverse wedsite observe the ease of ordering and the time it takes to get delivered also the quality of the goods and leave a genuine review on instagram as well as on the indyverse website. The product and their subsequent URLs were finalized basis on the customers preferences.         <br />
                                    <b> 2.</b> Mystery shoppers who had more than 500 followers on instagram were used    <br /> 
                                    {/* <b> 3. </b>A few of the items that were ordered experienced longer-than-anticipated delivery times, resulting in a delay in finalizing the project. Furthermore, the project progress was temporarily halted due to certain issues encountered on the website. */}
                                    
                                 {isReadMore ? 
                                 <><span> <b> 3. </b>A few of the items that were ordered experienced longer-than-anticipated delivery times, resulting in a delay in finalizing the project. Furthermore, the project progress was temporarily halted due to certain issues encountered on the website. </span>
                                   <span onClick={()=>setIsReadMore(false)} style={{ color: "blue", cursor: "pointer", fontSize: "14px", 
                                   textDecoration:"underline",textUnderlineOffset:"5px",
                                   position:"absolute",right:"3rem",
                                   paddingTop:".5rem" }}>Read Less</span></> 
                                   :
                                 <>  <span onClick={()=>setIsReadMore(true)} style={{ color: "blue", cursor: "pointer", fontSize: "14px", 
                                    textDecoration:"underline",textUnderlineOffset:"5px",
                                    position:"absolute",right:"3rem",
                                    paddingTop:".5rem" }}>Read More</span> <br />
                                    </>
                                }
                                </p>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
        <NewCommonCards/>
        </div>
    )
}
