import React from 'react';
import { Col,Row,Container } from 'react-bootstrap';
import garmincard from "../../../images/garmaincard.png";
import vajorcard from "../../../images/vajorcard.png";
import { Link } from 'react-router-dom';
import cardimg from "../../../images/card.png";

export default function Apparel() {
  return (
    <Container fluid className='homepage_brand_container'>
                <Row className='homepage_brand' style={{paddingTop:"1rem"}}>
                <Col className='homepage_brand_box casestudy_box' style={{ borderRadius: "7px" }} md="4">
                        <Link to="/casestudies/garmin">
                        <div className='homepage_brand_img'>
                            <img src={garmincard} alt="garminimg" style={{ borderTopLeftRadius: "7px", borderTopRightRadius: "7px",height:"auto" }} />
                        </div>
                        <div className='homepage_brand_text casestudy_brand_text'>
                            <div>
                                 <h5 style={{ marginLeft: "1rem",fontWeight:"bold" }}>Garmin</h5>
                                <p style={{ margin: "0rem 1rem" }}>Garmin wanted to check the stock availabiltiy and the product display at the store to understand the reason behind sales count and whether... </p>
                                 <div style={{ marginTop: "1rem", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0rem 1rem" }}>
                                    <button className="btn btn-md text-center btn-size create_ac_btn">Apparel</button>
                                    <div style={{ border: "2px solid black", padding: "3px 7px", borderRadius: "50%" }}>
                                        <i className="fa-solid fa-arrow-right" style={{ marginTop: "3px", fontSize: "1.2rem" }}></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </Link>
                    </Col>
                    <Col className='homepage_brand_box casestudy_box' style={{ borderRadius: "7px" }} md="4">
                        <Link to="/casestudies/vajor">
                        <div className='homepage_brand_img'>
                            <img src={vajorcard} alt="vajorimg" style={{ borderTopLeftRadius: "7px", borderTopRightRadius: "7px",height:"auto" }} />
                        </div>
                        <div className='homepage_brand_text casestudy_brand_text'>
                            <div>
                                <h5 style={{ marginLeft: "1rem",fontWeight:"bold" }}>Vajor</h5>
                                <p style={{ margin: "0rem 1rem" }}>The challenge at hand was to ensure that the product presentation within the company's retail outlets aligns with established standards... </p>
                                <div style={{ marginTop: "1rem", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0rem 1rem" }}>
                                    <button className="btn btn-md text-center btn-size create_ac_btn">Apparel</button>
                                    <div style={{ border: "2px solid black", padding: "3px 7px", borderRadius: "50%" }}>
                                        <i className="fa-solid fa-arrow-right" style={{ marginTop: "3px", fontSize: "1.2rem" }}></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </Link>
                    </Col>
                </Row>
                
            </Container>
  )
}
