import React from 'react';
import { Col,Row,Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import cardimg from "../../../images/card.png";
import indicard from "../../../images/indicard.jpg";
import shotcard from "../../../images/shotcard.jpg";
import garmincard from "../../../images/garmaincard.png";
import vajorcard from "../../../images/vajorcard.png";
import jmccard from "../../../images/jmccard.jpg";

export default function NewCommonCards() {
  return (
    <Container fluid className='homepage_brand_container' style={{ marginTop: "2rem" }}>
    <Row className='homepage_brand casestudy_box_container' style={{paddingTop:"1rem"}}>
    <Col className='homepage_brand_box casestudy_box' style={{ borderRadius: "7px" }}>
    <Link to="/casestudies/garmin">
    <div className='homepage_brand_img'>
        <img src={garmincard} alt="garminimg" style={{ borderTopLeftRadius: "7px", borderTopRightRadius: "7px",height:"auto" }} />
    </div>
    <div className='homepage_brand_text casestudy_brand_text'>
        <div>
             <h5 style={{ marginLeft: "1rem",fontWeight:"bold" }}>Garmin</h5>
            <p style={{ margin: "0rem 1rem" }}>Garmin wanted to check the stock availabiltiy and the product display at the store to understand the reason behind sales count and whether... </p>
             <div style={{ marginTop: "1rem", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0rem 1rem" }}>
                <button className="btn btn-md text-center btn-size create_ac_btn">Apparel</button>
                <div style={{ border: "2px solid black", padding: "3px 7px", borderRadius: "50%" }}>
                    <i className="fa-solid fa-arrow-right" style={{ marginTop: "3px", fontSize: "1.2rem" }}></i>
                </div>
            </div>
        </div>
    </div>
    </Link>
</Col>
<Col className='homepage_brand_box casestudy_box' style={{ borderRadius: "7px" }}>
<Link to="/casestudies/vajor">
<div className='homepage_brand_img'>
    <img src={vajorcard} alt="vajorimg" style={{ borderTopLeftRadius: "7px", borderTopRightRadius: "7px",height:"auto" }} />
</div>
<div className='homepage_brand_text casestudy_brand_text'>
    <div>
        <h5 style={{ marginLeft: "1rem",fontWeight:"bold" }}>Vajor</h5>
        <p style={{ margin: "0rem 1rem" }}>The challenge at hand was to ensure that the product presentation within the company's retail outlets aligns with established standards... </p>
        <div style={{ marginTop: "1rem", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0rem 1rem" }}>
            <button className="btn btn-md text-center btn-size create_ac_btn">Apparel</button>
            <div style={{ border: "2px solid black", padding: "3px 7px", borderRadius: "50%" }}>
                <i className="fa-solid fa-arrow-right" style={{ marginTop: "3px", fontSize: "1.2rem" }}></i>
            </div>
        </div>
    </div>
</div>
</Link>
</Col>
<Col className='homepage_brand_box casestudy_box' style={{ borderRadius: "7px" }}>
<Link to="/casestudies/jmc">
    <div className='homepage_brand_img'>
        <img src={jmccard} alt="jmcimg" style={{ borderTopLeftRadius: "7px", borderTopRightRadius: "7px",height:"auto" }} />
    </div>
    <div className='homepage_brand_text casestudy_brand_text'>
        <div>
            <h5 style={{ marginLeft: "1rem",fontWeight:"bold" }}>JMC</h5>
            <p style={{ margin: "0rem 1rem" }}>The challenge at hand was to elevate JMC's standing and performance within the Indore jewelry market. To achieve this objective, the focus was on...</p>
            <div style={{ marginTop: "1rem", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0rem 1rem" }}>
                <button className="btn btn-md text-center btn-size create_ac_btn">Retail</button>
                <div style={{ border: "2px solid black", padding: "3px 7px", borderRadius: "50%" }}>
                    <i className="fa-solid fa-arrow-right" style={{ marginTop: "3px", fontSize: "1.2rem" }}></i>
                </div>
            </div>
        </div>
    </div>
    </Link>
</Col>

</Row>
</Container>
  )
}
