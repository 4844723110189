import React from 'react'

export default function MobileViewReport() {
    return (
        <>
            <div className='dash_stepper_box_right_up' style={{padding:"2rem",boxShadow: "2px 4px 10px 2px rgba(0, 0, 0, 0.25)"}}>
                <h4><i className="fa-solid fa-image" style={{marginRight:".2rem"}}></i> This is where we jump in.</h4>
                <p>Now that you've sent the project request, sit back and relax. We'll take it from here. Once the project is executed, you'll see the reports in the user dashboard under 'Reports'</p>
            </div>
        </>
    )
}
