import React from 'react';
import "../../styles/shimmer.css";
import { Col } from 'react-bootstrap';
import Shimmer from './Shimmer';

function ShimmerIndividualProduct() {
    return (
        <>
        <div className="shimmer_product">
            <Col md="5" className='shimmer_left'></Col>
            <Col md="6" className='shimmer_right'></Col>
        </div>
        <div className="shimmerContainer">
            {Array(15).fill("  ").map((el, index) => {
                return (
                    <div className="cardShimmer" key={index}>
                        <div className="cardShimmerUp"></div>
                        <div className="shimmerLineOne"></div>
                        <div className="shimmerLineTwo"></div>
                        <div className="shimmerLineThree"></div>
                    </div>
                )
            })}
        </div>
        </>
    )
}

export default ShimmerIndividualProduct;