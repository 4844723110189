import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import "../styles/individualproduct.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Col, Row, Modal, Accordion, Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { addItem, removeItem, clearCart, updateQuantity } from '../utils/cartSlice';
import { useParams, useLocation } from 'react-router-dom';
import { clearStoreData, removeFile } from '../utils/orderdataslice';
import "../styles/registration.css";
import base_url from '../utils/config';
import AuthModal from './AuthModal';
import { Jodit } from 'jodit-react';
import { useFetch } from '../utils/hooks/useFetch';
import ShimmerIndividualProduct from './shimmerComponents/ShimmerIndividualProduct';
import { postSaveForLaterData } from '../utils/services/PostdataHandle';
// import { AppContext } from './MainPage';
import { AppContext } from '../App';

export default function IndividualProduct() {
  const location = useLocation();
  // const id = location.state;
  const id = location.state?.id;
  const productType = location.state?.name;
  const categoryIdForPopular = location.state?.categoryId;
  const { url_structure } = useParams();
  const navigate = useNavigate();
  const cartItems = useSelector((store) => store.cart.items);
  const cartTotalQuantity = useSelector((store) => store.cart.totalQuantity);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [productUrl, setProductUrl] = useState(base_url + "manager/public_solution/" + id + "/full_details");
  const [modalShow, setModalShow] = useState(false);
  const [talktModalShow, setTalkModalShow] = useState(false);
  const existingUser = (localStorage.getItem('user_id'));
  const [data, setData] = useState([]);
  const { popularSolutionDataMain, setPopularSolutionDataMain, categoryDataMain, setCategoryDataMain } = useContext(AppContext);
  const [isSeeMore, setIsSeeMore] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(1);
  const [currentSlide, setCurrentSlide] = useState(0);
  const visibleSlides = 3;
  // const middleIndex = (currentSlide + Math.floor(visibleSlides / 2)) % data[0]?.attachments.length;
  const selectedCategoryId = (localStorage.getItem('selectedCategoryId'))
  const auditcountReduxwithId = useSelector((store) => store.orderdata.secondPage.totalAuditsPerStoreWithStoreId);
  // const modifiedauditcountwithId = Object.entries(auditcountReduxwithId).map(([storeId, count]) => ({ store_id: parseInt(storeId), count: parseInt(count) }));
  const modifiedauditcountwithId = Object.entries(auditcountReduxwithId).map(([storeId, count]) => {
    if (count !== "") {
      return { store_id: parseInt(storeId), count: parseInt(count) };
    }
    return null;
  }).filter(item => item !== null);
  const cartItemsfordraft = useSelector((store) => store.cart);
  const storedInputs = useSelector((store) => store.orderdata.firstPage.inputs);
  const selectedFiles = useSelector((store) => store.orderdata.firstPage.selectedFile)
  function excludeProperties(obj, propsToExclude) {
    const newObj = {};
    for (const key in obj) {
      if (!propsToExclude.includes(key)) {
        newObj[key] = obj[key];
      }
    }
    return newObj;
  }
  // const propsToExclude = ['describe', 'preference'];
  const propsToExclude = ['describe', 'preference', 'selectedFile'];
  const alignment_factors = excludeProperties(storedInputs, propsToExclude);
  const fileData = selectedFiles.length > 0
    ? {
      file_name: selectedFiles[0].fileName,
      file_size: selectedFiles[0].fileSize,
      file_type: selectedFiles[0].fileType,
    }
    : {};
  function handleClick(index, itemId) {
    setCurrentIndex(index);
  }
  const arr = location.pathname.split("/")
  const producturl = arr[arr.length - 1];
  const categoryurl = arr[arr.length - 2];
  const categoryId = categoryDataMain.find(item => item?.url_structure === categoryurl)?.id;
  const [selectedCurrency, setSelectedCurrency] = useState('INR');
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('IN');

  useEffect(() => {
    // console.log("url",url_structure,location.pathname.split("/").slice(1,2).join("/"))
    const arr = location.pathname.split("/")
    if (id) {
      getProductData(base_url + "manager/public_solution/" + id + "/full_details", arr[arr.length - 2]);
      fetchCountries();
    }
    if (categoryId && !id) {
      console.log("through direct url in product page")
      getProductList(base_url + "manager/public_category/" + categoryId + "/solution_detail");
      fetchCountries();
    }

    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    const storedCountry = localStorage.getItem('selectedCountry');
	const savedCurrency = localStorage.getItem('selectedCurrency');
    if (storedCountry) {
        setSelectedCountry(storedCountry);
		setSelectedCurrency(savedCurrency);
    }
  }, [id, categoryId])
  const fetchCountries = async () => {
    try {
        const response = await fetch(base_url + "manager/country");
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        console.log('Countries data:', data);
        setCountries(data); // Assuming data is an array of country objects
    } catch (error) {
        console.error('Error fetching countries:', error);
    }
};
const handleCurrencyChange = (currency) => {
  setSelectedCurrency(currency);
};


const convertPrice = (price) => {
  if (selectedCountry === 'IN') {
      return price;
  } else {
      // Convert price to USD
      return price * 84;
  }
};
const handleCountryChange = (countryCode) => {
  setSelectedCountry(countryCode);  
  setSelectedCurrency(countryCode === 'IN' ? 'INR' : 'USD');

  localStorage.setItem('selectedCountry', countryCode);
  localStorage.setItem('selectedCurrency', countryCode === 'IN' ? 'INR' : 'USD');
};
  const getProductList = async (url) => {
    try {
      const response = await fetch(url);
      if (response.ok) {
        const result = await response.json();
        console.log("categoryproductpage", result[0].solutions.find(item => item?.url_structure === producturl))
        const requiredProduct = result[0].solutions.find(item => item?.url_structure === producturl)
        const productId = requiredProduct?.id;
        if (productId) {
          getProductData(base_url + "manager/public_solution/" + productId + "/full_details", categoryurl);
        }
      } else {
        console.error('API request failed:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('An error occurred while fetching the API:', error);
    }
  }
  const getProductData = async (url, path) => {
    try {
      const response = await fetch(url);
      if (response.ok) {
        const result = await response.json();
        setData(result);
        const categoryName = result[0].categories.find(item => item.url_structure === path).name;
        document.title = result[0].name + " - " + categoryName + " | FloorWalk Marketplace"
        console.log("130", result)
      } else {
        console.error('API request failed:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('An error occurred while fetching the API:', error);
    }
  };

  const handleAddItem = (item) => {
    // dispatch(addItem(item));
    if (getAddedItemCount(item.id) < 30) {
      dispatch(addItem(item));
    }
  };

  const handleRemoveItem = (itemId) => {
    dispatch(removeItem(itemId));
    //   if (getAddedItemCount(itemId) > 1) {
    //     dispatch(removeItem(itemId));
    // }
  };

  const handleQuantityChange = (e, item) => {

    const newQuantity = e.target.value === '' ? 0 : parseInt(e.target.value); //in case of manually removing the number using backSpace, it gives empty string and that makes totaQuantity null, to avoid that this part
    if (newQuantity >= 0 && newQuantity <= 30) {
      dispatch(updateQuantity({ id: item.id, quantity: newQuantity }));
    }
    //     const newQuantity = e.target.value === '' ? '' : parseInt(e.target.value);
    // if (newQuantity >= 0 && newQuantity <= 30) {
    //   dispatch(updateQuantity({id: item.id, quantity: newQuantity}));
    // }

    // dispatch(updateQuantity({ id: item.id, quantity: parseInt(e.target.value) }));
  };
  const getAddedItemCount = (itemId) => {
    let count = 0;
    cartItems.forEach((item) => {
      if (item.id === itemId) {
        count += item.quantity;
      }
    });
    return count;
  };

  const isAddedToCart = (itemId) => {
    return getAddedItemCount(itemId) > 0;
  };
  const handleBuyNow = (url_structure) => {
    let country_code = localStorage.getItem("selectedCountry") ? localStorage.getItem("selectedCountry") : "IN";
    if (!existingUser) {
      setModalShow(
        true,
      )
    }
    else {
      setLoading(true);
      const requestData = {
        no_of_response: cartItemsfordraft.totalQuantity,
        solution: cartItemsfordraft.items[0].id,
        describe: storedInputs.describe,
        file: fileData,
        user: +existingUser,
        status: "DRAFT",
        alignment_factors: alignment_factors,
        store: modifiedauditcountwithId,
        category: +selectedCategoryId,
        country_code: country_code,
      };

      const promise = postSaveForLaterData(requestData, "client/order_mp");
      promise.then(response => response.json())
        .then(data => {
          localStorage.setItem("orderId", data.id);
          if (!data.detail) {
            navigate(`/stepupprogressbar/${url_structure}`, { state: id });
          }
          else {
            alert("Something went wrong, please refresh")
          }

        })
        .catch(error => {
          console.error('Error:', error);
        }).finally(() => {
          setLoading(false);
        })
      // navigate(`/stepupprogressbar/${url_structure}`, { state: id });
    }
  };
  const handleBackToProducts = () => {
    dispatch(clearCart());
    dispatch(clearStoreData());
    dispatch(removeFile());
    localStorage.removeItem('orderId');
    if (productType === "popular") {
      navigate(`/${location.pathname.split("/").slice(1, 2).join("/")}`, { state: categoryIdForPopular });
    } else {
      navigate(`/${location.pathname.split("/").slice(1, 2).join("/")}`);
    }
  }
  function handleBackToHome() {
    dispatch(clearCart());
    dispatch(clearStoreData());
    dispatch(removeFile());
    localStorage.removeItem('orderId');
    navigate("/");
  }
  const [active, setActive] = useState(false);
  const showPreviousImage = () => {
    if (currentIndex === 0) {
      setCurrentIndex(data[0].attachments.length - 1);
    } else {
      setCurrentIndex(currentIndex - 1);
    }
    setActive(true)
  };

  const showNextImage = () => {
    if (currentIndex === data[0].attachments.length - 1) {
      setCurrentIndex(0);
    } else {
      setCurrentIndex(currentIndex + 1);
    }
    setActive(true)
  };
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 3 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
      slidesToSlide: 3 // optional, default to 1.
    }
  };
  const handleSeeMore = () => {
    setIsSeeMore(true);
  }
  const handleSeeLess = () => {
    setIsSeeMore(false);
  }

  const updateIndex = (currentSlide) => {
    // const visibleIndexes = Array.from({ length: visibleSlides }, (_, i) => (currentSlide + i) % data[0]?.attachments.length);
    const visibleIndexes = Array.from({ length: visibleSlides }, (_, i) => (currentSlide + i) % ((data[0]?.attachments || []).length || 1));


    // console.log(visibleIndexes,visibleIndexes[1]);
    setCurrentIndex(visibleIndexes[1])
  };

  return data.length === 0 ? <ShimmerIndividualProduct /> : (
    <div>
      <div className='back_market back_market_flex' style={{ marginBottom: "1rem", }} >
      <div className='back_market_a'>
        <i onClick={handleBackToProducts} className="fa-solid fa-arrow-left"></i>
        <p>
          <span onClick={handleBackToHome}>Home / </span>
          <span onClick={handleBackToProducts}>Back to Products</span>
        </p>
        </div>
        	{/* <div className='back_market_flex_country'>
                Select Country :    
                    <Dropdown style={{marginLeft:'10px'}}>
                        <Dropdown.Toggle variant="success" id="currency-dropdown" className="dropdown-button">
                            {selectedCountry ? countries[selectedCountry] : "Choose Country"}
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="dropdown-menu-end">
                            <Dropdown.Item key="default" onClick={() => setSelectedCountry('')}>
                                Select Country
                            </Dropdown.Item>
                            {Object.keys(countries).map(countryCode => (
                                <Dropdown.Item key={countryCode} onClick={() => handleCountryChange(countryCode)}>
                                    {countries[countryCode]}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </div> */}
      </div>
      {/*************************************************************************************** */}
      {data.length > 0 && data.map((product) => {
        return (
          <div className='product_detail_part' key={product.id}>
            {Array.isArray(product?.attachments) && product?.attachments.length > 0 &&
              (
                <Col md="5" className='product_detail_leftpart'>
                  <div className='product_detail_carousel'>
                    <img
                      className="d-block w-100 carousel_product_img"
                      src={product?.attachments[currentIndex]?.preview_url}
                      alt={product?.attachments[currentIndex]?.file_name}
                      onClick={() => setShowImageModal(true)}
                    />
                  </div>
                  {showImageModal &&
                    <div style={{ position: "fixed", top: "0", left: "0", backgroundColor: "white", width: "100%", height: "100vh", zIndex: "10000", display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <div
                        style={{
                          position: "absolute",
                          left: "2rem",
                          top: "50%",
                          cursor: "pointer",
                          fontSize: "2rem"
                        }}
                        onClick={() => showPreviousImage()}
                      >
                        <i className="fa-solid fa-chevron-left"></i>
                      </div>
                      <div
                        style={{
                          position: "absolute",
                          right: "2rem",
                          top: "50%",
                          cursor: "pointer",
                          fontSize: "2rem"
                        }}
                        onClick={() => showNextImage()}
                      >
                        <i className="fa-solid fa-chevron-right"></i>
                      </div>
                      <div style={{ position: "absolute", right: "2rem", top: "2rem", cursor: "pointer", fontSize: "1.5rem" }} onClick={() => setShowImageModal(false)}><i className="fa-solid fa-x" ></i></div>
                      <img
                        className={active ? "full-screen-image_active" : "full-screen-image"}
                        src={product?.attachments[currentIndex]?.preview_url}
                        alt={product?.attachments[currentIndex]?.file_name}

                      />
                    </div>

                  }
                  <Carousel
                    swipeable={false}
                    draggable={false}
                    showDots={false}
                    responsive={responsive}
                    ssr={true} // means to render carousel on server-side.
                    infinite={true}
                    autoPlaySpeed={1000}
                    keyBoardControl={true}
                    customTransition="all .5"
                    transitionDuration={500}
                    containerClass="carousel-container"
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                    // afterChange={updateIndex} // this gives previously shown slides details
                    beforeChange={updateIndex} // this gives currently shown slides details
                  >
                    {product?.attachments.map((attachment, index) => {
                      return (
                        <Col md="12" className='product_small_img ' key={index}>
                          <img src={attachment?.preview_url} alt={attachment?.file_name} onClick={() => handleClick(index)} />
                        </Col>
                      )
                    })}
                  </Carousel>
                </Col>
              )
            }
            <Col md="7" className='product_detail_rightpart'>
              <h3><b>{product?.name}</b></h3>
              <div className='product_response_box'>
              {/* <h6> <b>INR. {product?.price}</b> + <span style={{ fontSize: ".7rem", fontWeight: "bold" }}>{product?.tax?.rate}% GST</span> <b>/ Response </b></h6> */}
                <h6> <b> {localStorage.getItem("selectedCountry") ? localStorage.getItem("selectedCountry")==="IN" ? "INR." : "USD." : "INR."}  {localStorage.getItem("selectedCountry") ? localStorage.getItem("selectedCountry")==="IN" ? product.price_INR : product.price_USD : product.price_INR}</b> + <span style={{ fontSize: ".7rem", fontWeight: "bold" }}>{product?.tax?.rate}% GST</span> <b>/ Response </b></h6>                {cartTotalQuantity === 0 && <button className="btn btn-md text-center btn-size create_ac_btn" onClick={() => handleAddItem(product)}>Buy Now</button>}
                {cartTotalQuantity > 0 && <div className="quantityContainer">
                  <button disabled={getAddedItemCount(product.id) < 1}
                    onClick={() => handleRemoveItem(product.id)}><i className="fa-solid fa-minus" style={{ color: "black" }}></i></button>
                  <button>{getAddedItemCount(product.id) || 0}</button>
                  {/* <input type="number" value={getAddedItemCount(product.id)} onChange={(e) => handleQuantityChange(e, product)} className="quantity-input"/> */}
                  <button onClick={() => handleAddItem(product)}><i className="fa-solid fa-plus"></i></button>
                </div>
                }
                {cartTotalQuantity > 0 && (
                  loading ? (
                    <button className="btn btn-md text-center btn-size create_ac_btn" type="button" disabled style={{ fontSize: "1rem", background: "#007DC1", color: "white", }}>
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: ".5rem" }}></span>
                      Checking out...
                    </button>
                  ) : (
                    <button className="btn btn-md text-center btn-size create_ac_btn" onClick={() => handleBuyNow(product?.url_structure)}>Check Out</button>
                  )
                )}

                <AuthModal
                  modalShow={modalShow}
                  setModalShow={setModalShow}
                  url_structure={product?.url_structure}
                  id={id}
                />
                <br></br>

            <div className='back_market_flex_country_dropdown'>
                	<span style={{ fontSize: '0.875rem', marginRight: '5px' ,fontWeight: "bold" }}>Selected Country :</span>  
                    <Dropdown style={{marginLeft:'10px'}}>
                        <Dropdown.Toggle variant="success" id="currency-dropdown" className="dropdown-button" style={{ padding: '0.25rem 0.5rem', fontSize: '0.72rem',}}>
                            {selectedCountry ? countries[selectedCountry] : "Choose Country"}
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="dropdown-menu-end">
                            {/* Default option for selecting a country */}
                            <Dropdown.Item key="default" onClick={() => setSelectedCountry('')}>
                                Select Country
                            </Dropdown.Item>
                            {/* Convert countries object to an array of objects */}
                            {Object.keys(countries).map(countryCode => (
                                <Dropdown.Item key={countryCode} onClick={() => handleCountryChange(countryCode)}>
                                    {countries[countryCode]}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>              
              </div>
              {isSeeMore ? <div>
                <p style={{ fontSize: "14px" }}>{Jodit.modules.Helpers.stripTags(product?.overview)}</p>
                <p onClick={handleSeeLess} style={{ color: "blue", cursor: "pointer", fontSize: "14px" }}>Read Less...</p>
              </div> :
                <div className='product_overview_mobile'>
                  <p style={{ fontSize: "14px" }}>{Jodit.modules.Helpers.stripTags(product?.overview).split(" ").slice(0, 28).join(" ")}</p>
                  <p onClick={handleSeeMore} style={{ color: "blue", cursor: "pointer", fontSize: "14px" }}>Read more...</p>
                </div>}
              <div className='product_detail_rightpart_lower_box'>
                <Col md="6" className='product_detail_rightpart_lower product_detail_border1'>
                  <h5 style={{ padding: "0rem" }}> What Can You Expect? </h5>
                  <ul style={{ paddingLeft: "0rem" }}>
                    {Jodit.modules.Helpers.stripTags(product?.execution_time).split(".").filter((item) => item !== "").map((item, index) => (
                      <li className='product_detail_point' key={index}>
                        <span style={{ fontSize: "14px" }}><i className="fa-sharp fa-solid fa-circle-check"></i></span>
                        <p style={{ fontSize: "14px" }}>{item}</p>
                      </li>
                    ))}
                    <li className='product_detail_point'>
                      <span style={{ fontSize: "14px" }}><i className="fa-sharp fa-solid fa-circle-check"></i></span>
                      <a
                        href="https://drive.google.com/file/d/1muTTqXVMdN_dqcaPluYrSnMvqjiVrvaC/view?pli=1"
                        target='_blank'
                        style={{ fontSize: "14px" }}
                      >
                        <span>Sample Report</span>
                      </a>
                    </li>
                  </ul>
                </Col>
                <Col md="6" className='product_detail_rightpart_lower product_detail_border'>
                  <h5> How It Works </h5>
                  <ul className='product_detail_border_ul'>
                    {Jodit.modules.Helpers.stripTags(product?.how_it_work).split(".").filter((item) => item !== "").map((item, index) => (
                      <li className='product_detail_point' key={index} >
                        <span style={{ fontSize: "14px" }}><i className="fa-sharp fa-solid fa-circle-check"></i></span>
                        <p style={{ fontSize: "14px" }}>{item}</p>
                      </li>
                    ))}
                  </ul>
                </Col>
              </div>
              {/* -------------------------------MOBILE SCREEN ACCORDIAN----------------------------- */}
              <div className='product_detail_rightpart_lower_box_smallscrn'>
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header> <b> What Can You Expect?</b></Accordion.Header>
                    <Accordion.Body>
                      <ul style={{ paddingLeft: "0rem" }}>
                        {Jodit.modules.Helpers.stripTags(product?.execution_time).split(".").filter((item) => item !== "").map((item, index) => (
                          <li className='product_detail_point' key={index}>
                            <span style={{ fontSize: "14px" }}><i className="fa-sharp fa-solid fa-circle-check"></i></span>
                            <p style={{ fontSize: "14px" }}>{item}</p>
                          </li>
                        ))}
                        <li className='product_detail_point'>
                          <span style={{ fontSize: "14px" }}><i className="fa-sharp fa-solid fa-circle-check"></i></span>
                          <a
                            href="https://drive.google.com/file/d/1muTTqXVMdN_dqcaPluYrSnMvqjiVrvaC/view?pli=1"
                            target='_blank'
                            style={{ fontSize: "14px" }}
                          ><span>Sample Report</span></a>
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header> <b> How It Works </b></Accordion.Header>
                    <Accordion.Body>
                      <ul className='product_detail_border_ul'>
                        {Jodit.modules.Helpers.stripTags(product?.how_it_work).split(".").filter((item) => item !== "").map((item, index) => (
                          <li className='product_detail_point' key={index}>
                            <span style={{ fontSize: "14px" }}><i className="fa-sharp fa-solid fa-circle-check"></i></span>
                            <p style={{ fontSize: "14px" }}>{item}</p>
                          </li>
                        ))}
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
              {/* ------------------------------------------------------------------------ */}
            </Col>
          </div>
        )
      })}


      {/* ------------------------------------------------------------------------------------------------------- */}

      <div>
        <h6 style={{ textAlign: "center", marginTop: "2rem", fontWeight: "bold" }}>Suggested Products</h6>
        <div className='category_product_container'>
          <div className='category_product_box_container' style={{ justifyContent: "center", gap: "2rem" }}>
            {popularSolutionDataMain.slice(0, 3).map((product) => {
              return (
                <div className='category_product_box product_page_category' key={product.id} style={{ paddingTop: "2.5rem" }}>

                  <Link to={"/" + product.categories[0].url_structure + "/" + product?.url_structure} state={{ id: product?.id, name: "popular", categoryId: product?.categories[0]?.id }} style={{ textDecoration: "none", color: "black" }}>
                    <div className='category_name' style={{ fontWeight: "bold", fontSize: "1rem" }}>
                      {product.name}
                    </div>

                    <div className='category_product_box_img'>
                      <img src={product?.attachments[0]?.preview_url} alt={product?.attachments[0]?.file_name} />
                      <div className='categoty_price'>
                        INR. {product.price} /Response
                      </div>
                    </div>
                    <div className='category_product_box_text'>
                      <p>{Jodit.modules.Helpers.stripTags(product.short_description).split("").slice(0, 90).join("")}...</p>
                    </div>
                    <div className='category_product_btn_container'>
                      <button className="btn btn-md text-center btn-size create_ac_btn">Buy Now</button>
                    </div>
                    
                  </Link>
                </div>

              )
            })}
          </div>
        </div>
        <div className='product_page_bottom_right'>
          <div className='howitwrks_tellus howitwrks_tellus_productpage'>
            <div className='howitwrks_tellus_p howitwrks_tellus_productpage_p'>
              <p style={{ marginBottom: "0rem" }}>Still not sure whether this is the right fit? Explore Personalised Retail Audit & Research Solutions.</p>
              <i className="fa-solid fa-arrow-right howitwrks_tellus_productpage_arrow"></i>
            </div>
            <div className='howitwrks_tellus_p howitwrks_tellus_productpage_p_mobile'>
              <p style={{ marginBottom: "0rem" }} className='howitwrks_tellus_productpage_p_mobile_p'>Still not sure whether this is the right fit? </p>
              <p className='howitwrks_tellus_productpage_p_mobile_p'>Explore Personalised Retail Audit & Research Solutions.</p>
            </div>
            <div>
              <button
                onClick={() =>
                  setTalkModalShow(
                    true,
                  )
                }>
                Talk to a consultant
              </button>
            </div>
          </div>
        </div>
      </div>


      {/* ----------------------------------------------------------------------------------------------------- */}
      <Modal
        show={talktModalShow}
        centered
        size='lg'
        style={{
          marginTop: '40px',
          // background: "rgba(0,0,0,.7)" 
        }}
        scrollable={true}
        className='modal-dialog-scrollable p-6 modal_mobile'
        onHide={() => setTalkModalShow(false)}>
        <div style={{ position: "absolute", right: "2rem", top: "2rem", cursor: "pointer" }} onClick={() => setTalkModalShow(false)}><i className="fa-solid fa-x" ></i></div>
        <iframe
          frameBorder='0'
          style={{
            height: '500px',
            width: '99%',
            border: 'none',
          }}
          src='https://forms.zohopublic.com/floorwalk/form/MysteryShoppingServices/formperma/xW3CS_zHKW2DcCLHVZUplXsC1wLR_fITFv3kCFrBY9U?zf_rszfm=1'></iframe>
      </Modal>


    </div>
  )
}
