import React, { useState,useEffect } from 'react';
import { Col, Row, Table, Modal } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ReportListDetail from './ReportListDetail';
import { useFetchAuth } from '../../../utils/hooks/useFetchAuth';
import ShimmerTableRow from '../../shimmerComponents/ShimmerTableRow';
import StoreReportList from './StoreReportList';
import DetailsProject from './DetailsProject';
import base_url from '../../../utils/config';
import "../../../styles/dashreport.css";

export default function StoreLists() {
  const location = useLocation();
  const token = localStorage.getItem("token");
  const auditCycleId = location.state;
  const navigate = useNavigate();
  const [expandedReportListRows, setExpandedReportListRows] = useState({});
  const [detailsModalShow, setDetailsModalShow] = useState(false);
  const [activeProjectId, setActiveProjectId] = useState(null);
  const [data, setData] = useState([]);
  const [orderDetailData, setOrderDetailData] = useState([]);

  useEffect(() => {
    const currentUrl = window.location.href;
    if(+currentUrl.split("=").slice(1))
    {
    getApiData(`client/mp/audit_cycle/${+currentUrl.split("=").slice(1)}/audit`)
    getOrderDetailData(`client/mp/audit_cycle_detail/${+currentUrl.split("=").slice(1)}`)
    }else{
      getApiData(`client/mp/audit_cycle/${auditCycleId}/audit`)
      getOrderDetailData(`client/mp/audit_cycle_detail/${auditCycleId}`)
    }
  }, []);
 
  const getApiData = async (url) => {
    try {

        const headers = {
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json'
        };

        const options = {
            method: 'GET',
            headers: headers
        };

        const response = await fetch(base_url + url, options);

        if (response.ok) {
            const result = await response.json();
            setData(result);
        } else {
            console.error('API request failed:', response.status, response.statusText);
        }
    } catch (error) {
        console.error('An error occurred while fetching the API:', error);
    }
};
const getOrderDetailData = async (url) => {
  try {
      const headers = {
          'Authorization': `Token ${token}`,
          'Content-Type': 'application/json'
      };

      const options = {
          method: 'GET',
          headers: headers
      };

      const response = await fetch(base_url + url, options);

      if (response.ok) {
          const result = await response.json();
          setOrderDetailData(result);
      } else {
          console.error('API request failed:', response.status, response.statusText);
      }
  } catch (error) {
      console.error('An error occurred while fetching the API:', error);
  }
};
  const toggleReportListRow = (projectId) => {
    setExpandedReportListRows((prevExpandedRows) => ({
      ...prevExpandedRows,
      [projectId]: !prevExpandedRows[projectId],
    }));
  };

  const handleModalclick = () => {
    setDetailsModalShow(true);
  }
  const isRowActive = (projectId) => expandedReportListRows[projectId];
  return (
    <div>
      <div className='report_storelist_upper'>
        <div className='back_market' style={{ padding: "0rem" }}>
          <Link to="/userdashboardmain/reports">
            <i className="fa-solid fa-arrow-left"></i>
            <span>Back to Reports</span>
          </Link>
        </div>
        <div>
          <button className='btn create_ac_btn' onClick={handleModalclick}>View Order Details</button>
          {detailsModalShow ?
            <Modal
              show={detailsModalShow}
              centered
              size='xl'
              // scrollable={true}
              className='modal-dialog-scrollable p-6 modal_mobile'
              onHide={() => setDetailsModalShow(false)}
            >
              <DetailsProject project={orderDetailData} setDetailsModalShow={setDetailsModalShow}/>
            </Modal> : null}
        </div>
      </div>
      <h4 className='report_storelist_heading'>Store List</h4>
      <div className='dash_report_table table-responsive' style={{ marginTop: "0rem", paddingTop: "0rem" }}>
        {data.length <= 0 ? <ShimmerTableRow /> :
        <Table className="text-center  table_border_head_green table-hover" >
        <thead>
          <tr>
            <th className="fw-bold ">Store Name</th>
            <th className="fw-bold ">Store Address</th>
            <th className="fw-bold ">Country</th>
            <th className="fw-bold ">State</th>
            <th className="fw-bold ">City</th>
            <th className="fw-bold ">Total Score</th>
            <th className="fw-bold ">Status</th>
            <th className="fw-bold ">Planned Audits</th>
            <th className="fw-bold ">Reports</th>
          </tr>
        </thead>
        <tbody>
          {data.map((project) => (
            <>
              <tr onClick={() => toggleReportListRow(project?.id)}
                key={project?.id}
                style={{
                  cursor: "pointer",
                  height: isRowActive(project?.id) ? "80px" : "30px",
                }}>
                <td className={isRowActive(project?.id) ? "tableactive" : "tablenormal"} style={{ backgroundColor: isRowActive(project?.id) ? "rgb(245,245,245)" : "rgb(223, 240, 216)", }}> <b>{project?.store?.name}</b> </td>
                <td className={isRowActive(project?.id) ? "tableactive" : "tablenormal"} style={{ backgroundColor: isRowActive(project?.id) ? "rgb(245,245,245)" : "rgb(223, 240, 216)", }}>{project?.store?.address}</td>
                <td className={isRowActive(project?.id) ? "tableactive" : "tablenormal"} style={{ backgroundColor: isRowActive(project?.id) ? "rgb(245,245,245)" : "rgb(223, 240, 216)", }}>{project?.store?.city?.country_name}</td>
                <td className={isRowActive(project?.id) ? "tableactive" : "tablenormal"} style={{ backgroundColor: isRowActive(project?.id) ? "rgb(245,245,245)" : "rgb(223, 240, 216)", }}>{project?.store?.city?.state_name}</td>
                <td className={isRowActive(project?.id) ? "tableactive" : "tablenormal"} style={{ backgroundColor: isRowActive(project?.id) ? "rgb(245,245,245)" : "rgb(223, 240, 216)", }}>{project?.store?.city?.name}</td>
                <td className={isRowActive(project?.id) ? "tableactive" : "tablenormal"} style={{ backgroundColor: isRowActive(project?.id) ? "rgb(245,245,245)" : "rgb(223, 240, 216)", }}>{project?.store_average_score}</td>
                <td className={isRowActive(project?.id) ? "tableactive" : "tablenormal"} style={{ backgroundColor: isRowActive(project?.id) ? "rgb(245,245,245)" : "rgb(223, 240, 216)", }}> <b> {project?.audit_cycle?.status}</b></td>
                <td className={isRowActive(project?.id) ? "tableactive" : "tablenormal"} style={{ backgroundColor: isRowActive(project?.id) ? "rgb(245,245,245)" : "rgb(223, 240, 216)", }}> <b> {project?.count}</b></td>
                <td className={isRowActive(project?.id) ? "tableactive" : "tablenormal"} style={{ backgroundColor: isRowActive(project?.id) ? "rgb(245,245,245)" : "rgb(223, 240, 216)", }}> <b> {project?.report_count}</b></td>
              </tr>

              {expandedReportListRows[project?.id] && (
                <tr key={`${project?.id}-details`} className="expanded-row">
                  <td colSpan="10">
                    <div className="expanded-content">
                      <StoreReportList projectId={project?.id} />
                      <button className="close-icon" onClick={() => toggleReportListRow(project?.id)}>
                        <i className="fa-solid fa-x"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              )}
            </>
          ))}
        </tbody>
        </Table>
}
      </div>
    </div>
  )
}
