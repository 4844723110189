// import React, { useEffect, useState } from 'react';
// import { Col, Table } from 'react-bootstrap';
// import attachment1 from "../../../images/howitworks_2.webp";
// import attachment2 from "../../../images/howitworks_3.jpg";
// import { useFetchAuth } from '../../../utils/hooks/useFetchAuth';
// import base_url from '../../../utils/config';

// export default function QuestionReport({ auditStoreId }) {
//     const token = localStorage.getItem("token");
//     const [selectedImage, setSelectedImage] = useState(null);
//     const [sectionData, setSectionData] = useState([]);
//     const [answerData, setAnswerData] = useState([]);
//     const [reportSummary,setReportSummary] = useState([]);
//     const [attachments, setAttachments] = useState([]);
//     const [sectionId, setSectionId] = useState(null);

//     useEffect(() => {
//         getSectionData(`client/audit_store/${auditStoreId}/section`);
//         getAnswerData(`client/audit_store/${auditStoreId}/answer`);
//         getReportSummaryData(`client/audit_store/${auditStoreId}/report_section`);
//     }, [auditStoreId])

//     useEffect(() => {
//         if (sectionId) {
//             getAttachments(`client/audit_store/${auditStoreId}/section/${sectionId}/attachment`);
//         }
//     }, [sectionId]);

//     const getSectionData = async (url) => {
//         try {
//             const headers = {
//                 'Authorization': `Token ${token}`,
//                 'Content-Type': 'application/json'
//             };

//             const options = {
//                 method: 'GET',
//                 headers: headers
//             };

//             const response = await fetch(base_url + url, options);

//             if (response.ok) {
//                 const result = await response.json();
//                 setSectionData(result);
//                 if (result[0]?.id) {
//                     setSectionId(result[0].id);
//                 }
//             } else {
//                 console.error('API request failed:', response.status, response.statusText);
//             }
//         } catch (error) {
//             console.error('An error occurred while fetching the API:', error);
//         }
//     };

//     const getAnswerData = async (url) => {
//         try {
//             const headers = {
//                 'Authorization': `Token ${token}`,
//                 'Content-Type': 'application/json'
//             };

//             const options = {
//                 method: 'GET',
//                 headers: headers
//             };

//             const response = await fetch(base_url + url, options);

//             if (response.ok) {
//                 const result = await response.json();
//                 setAnswerData(result);
//             } else {
//                 console.error('API request failed:', response.status, response.statusText);
//             }
//         } catch (error) {
//             console.error('An error occurred while fetching the API:', error);
//         }
//     };
//     const getReportSummaryData = async (url) => {
//         try {
//             const headers = {
//                 'Authorization': `Token ${token}`,
//                 'Content-Type': 'application/json'
//             };

//             const options = {
//                 method: 'GET',
//                 headers: headers
//             };

//             const response = await fetch(base_url + url, options);

//             if (response.ok) {
//                 const result = await response.json();
//                 setReportSummary(result);
//             } else {
//                 console.error('API request failed:', response.status, response.statusText);
//             }
//         } catch (error) {
//             console.error('An error occurred while fetching the API:', error);
//         }
//     };
//     const getAttachments = async (url) => {
//         try {
//             const headers = {
//                 'Authorization': `Token ${token}`,
//                 'Content-Type': 'application/json'
//             };

//             const options = {
//                 method: 'GET',
//                 headers: headers
//             };

//             const response = await fetch(base_url + url, options);

//             if (response.ok) {
//                 const result = await response.json();
//                 setAttachments(result);
//             } else {
//                 console.error('API request for attachments failed:', response.status, response.statusText);
//             }
//         } catch (error) {
//             console.error('An error occurred while fetching attachments from the API:', error);
//         }
//     };
//     const handleImageClick = (imageSrc) => {
//         setSelectedImage(imageSrc);
//     };

//     const handleCloseImage = () => {
//         setSelectedImage(null);
//     };
//     const handleDownloadImage = () => {
//         if (selectedImage) {
//             const anchor = document.createElement('a');
//             anchor.href = selectedImage;
//             anchor.download = 'image.jpg'; // Set the desired file name here
//             anchor.click();
//         }
//     };

//     return (
//         <div style={{ marginTop: "2rem" }}>
//             <div className='report_details_result_box'>
//                 <h6> <span style={{ marginRight: "1rem" }}>1.</span>Audit Details</h6>
//             </div>
//             <Table>
//                 <thead>
//                     <tr>
//                         <th>#</th>
//                         <th>Question</th>
//                         <th>Answer</th>
//                         <th>Marks</th>
//                         <th>Max. Marks</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {sectionData[0]?.questions.map((question) => {
//                         const correspondingAnswer = answerData.find((answer) => answer.question === question.id);
//                         return (
//                             <tr key={question.id}>
//                                 <td>{question.sequence}</td>
//                                 <td>{question.question_txt}</td>
//                                 <td>{correspondingAnswer ? correspondingAnswer.answer_text : 'N/A'}</td>
//                                 <td>{correspondingAnswer ? correspondingAnswer.marks_obtained : 'N/A'}</td>
//                                 <td>{question.max_marks}</td>
//                             </tr>
//                         );
//                     })}

//                 </tbody>
//             </Table>
//             {sectionData.map((section) => {
//                         const correspondingSummary = reportSummary.find((summary) => summary.section === section?.id);
//                         return(
//                             <div className='report_question_summary'>
//                             <p> <b>Total Marks :</b> {correspondingSummary ? `${correspondingSummary.marks_obtained} out of ${correspondingSummary.max_marks}` : 'N/A'}</p>
//                             <p style={{overflow:'hidden'}}> <b>Section Summary :</b> {correspondingSummary?.auditor_comment} </p>
//                         </div>
//                         )
//             })}
//             <div className='report_question_attachment'>
//                 <h5>Attachments : </h5>
//                 <div className='report_question_attachment_imgcontainer'>
//                       {attachments.map((attachment) => (
//                         <img
//                             key={attachment.id}
//                             src={attachment?.direct_url}
//                             alt={attachment.file_name}
//                             onClick={() => handleImageClick(attachment?.direct_url)}
//                         />
//                     ))}
//                 </div>
//                 {selectedImage && (
//                     <div className='larger-image-container'>
//                         <div className='larger-image-container_closebtn'>
//                             <p>large Image</p>
//                             <button className="btn" onClick={handleCloseImage}><i class="fa-solid fa-x"></i></button>
//                         </div>
//                         <div className='larger-image-container_imgbox'>
//                             <img src={selectedImage} alt="Large Image" className="larger-image" />
//                             <div>
//                                 <button onClick={handleDownloadImage} className='btn create_ac_btn'><i class="fa-solid fa-download"></i> Download</button>
//                             </div>
//                         </div>
//                     </div>
//                 )}
//             </div>
//         </div>
//     );
// }

// --------------------------------------------------------------------------------------------------------

import React, { useEffect, useState, useRef } from 'react';
import { Col, Table } from 'react-bootstrap';
import attachment1 from "../../../images/howitworks_2.webp";
import attachment2 from "../../../images/howitworks_3.jpg";
import { useFetchAuth } from '../../../utils/hooks/useFetchAuth';
import base_url from '../../../utils/config';
import { tr } from 'date-fns/locale';
import pdfdummyimg from "./../../../images/pdfDownload.png";
import audioimage from "./../../../images/audio_image.avif";
import videoimage from "./../../../images/video_icon.jpg";

export default function QuestionReport({ auditStoreId }) {
    const token = localStorage.getItem("token");
    // const [selectedImage, setSelectedImage] = useState(null);
    const [sectionData, setSectionData] = useState([]);
    const [answerData, setAnswerData] = useState([]);
    const [reportSummary, setReportSummary] = useState([]);
    const [attachments, setAttachments] = useState([]);
    const [sectionId, setSectionId] = useState(null);
    const [selectedImage, setSelectedImage] = useState({});
    const enlargedViewRef = useRef(null);
    
    useEffect(() => {
        if (auditStoreId) {
            getSectionData(`client/audit_store/${auditStoreId}/section`);
            getAnswerData(`client/audit_store/${auditStoreId}/answer`);
            getReportSummaryData(`client/audit_store/${auditStoreId}/report_section`);
        }

    }, [auditStoreId])

    useEffect(() => {
        // Scroll to the enlarged view when selectedImage changes
        if (selectedImage[sectionId]) {
          enlargedViewRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }
      }, [selectedImage, sectionId]);

    useEffect(() => {
        if (auditStoreId && sectionData.length > 0) {
            sectionData.forEach((item) => {
                getAttachments(`client/audit_store/${auditStoreId}/section/${item.id}/attachment`, item.id);
                // getAttachments(`client/audit_store/${auditStoreId}/attachment`, item.id);
            });
        }
    }, [sectionData, auditStoreId]);

    const getSectionData = async (url) => {
        try {
            const headers = {
                'Authorization': `Token ${token}`,
                'Content-Type': 'application/json'
            };

            const options = {
                method: 'GET',
                headers: headers
            };

            const response = await fetch(base_url + url, options);

            if (response.ok) {
                const result = await response.json();
                setSectionData(result);
                if (result.length > 0) {
                    setSectionId(result[0].id);
                }
            } else {
                console.error('API request failed:', response.status, response.statusText);
            }
        } catch (error) {
            console.error('An error occurred while fetching the API:', error);
        }
    };

    const getAnswerData = async (url) => {
        try {
            const headers = {
                'Authorization': `Token ${token}`,
                'Content-Type': 'application/json'
            };

            const options = {
                method: 'GET',
                headers: headers
            };

            const response = await fetch(base_url + url, options);

            if (response.ok) {
                const result = await response.json();
                setAnswerData(result);
            } else {
                console.error('API request failed:', response.status, response.statusText);
            }
        } catch (error) {
            console.error('An error occurred while fetching the API:', error);
        }
    };
    const getReportSummaryData = async (url) => {
        try {
            const headers = {
                'Authorization': `Token ${token}`,
                'Content-Type': 'application/json'
            };

            const options = {
                method: 'GET',
                headers: headers
            };

            const response = await fetch(base_url + url, options);

            if (response.ok) {
                const result = await response.json();
                setReportSummary(result);
            } else {
                console.error('API request failed:', response.status, response.statusText);
            }
        } catch (error) {
            console.error('An error occurred while fetching the API:', error);
        }
    };

    const getAttachments = async (url, sectionId) => {
        try {
            const headers = {
                'Authorization': `Token ${token}`,
                'Content-Type': 'application/json'
            };

            const options = {
                method: 'GET',
                headers: headers
            };

            const response = await fetch(base_url + url, options);

            if (response.ok) {
                const result = await response.json();
                // Attach the attachments to the corresponding section using sectionId
                setAttachments((prevAttachments) => ({
                    ...prevAttachments,
                    [sectionId]: result,
                }));
            } else {
                console.error('API request for attachments failed:', response.status, response.statusText);
            }
        } catch (error) {
            console.error('An error occurred while fetching attachments from the API:', error);
        }
    };
    const handleImageClick = (imageSrc) => {
        setSelectedImage({ ...selectedImage, [sectionId]: imageSrc });
    };

    const handleCloseImage = () => {
        // setSelectedImage(null);
        setSelectedImage({ ...selectedImage, [sectionId]: null });
    };
    const handleDownloadImage = () => {
        // if (selectedImage) {
        //     const anchor = document.createElement('a');
        //     anchor.href = selectedImage;
        //     anchor.download = 'image.jpg'; 
        //     anchor.click();
        // }
        if (selectedImage[sectionId]) {
            const anchor = document.createElement('a');
            anchor.href = selectedImage[sectionId];
            anchor.download = 'image.jpg'; 
            anchor.click();
        }
    };
    const renderAttachment = (attachment) => {
        // const extension = attachment.file_name.split('.').pop().toLowerCase();
        const extension = attachment.mime_type.split('/').pop().toLowerCase();
        if (attachment.mime_type === "application/pdf") {
            return (
                <div style={{ backgroundColor: "#d3d3d3", width: "180px", height: "180px", padding: ".5rem", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <img
                        src={pdfdummyimg}
                        width="100%"
                        alt={attachment.file_name}
                        onClick={() => handleImageClick(attachment.direct_url)}
                    />
                </div>
            );
        }
        else if (attachment.mime_type.split("/").includes("video")) {
            return (
                <video controls width="300px" height="180px" onClick={() => handleImageClick(attachment.direct_url)}>
                    <source src={attachment.direct_url} type={`video/${extension}`} />
                    Your browser does not support the video tag.
                </video>
            );
        } 
       
        else if (attachment.mime_type.split("/").includes("audio") || attachment.mime_type.split("/").includes("Audio")) {
            return (
            //     <audio controls onClick={() => handleImageClick(attachment.direct_url)}>
            //     <source src={attachment.direct_url} type={`audio/${extension}`} />
            //     Your browser does not support the audio element.
            //   </audio>
              <img src={audioimage} alt={attachment.file_name} className='report_img_container_img' onClick={() => handleImageClick(attachment.direct_url)}/>
            );
        } 
        else {
            return (
                <img
                    src={attachment.direct_url}
                    alt={attachment.file_name}
                    className='report_img_container_img'
                    onClick={() => handleImageClick(attachment.direct_url)}
                />
            );
        }
    };
 
    return (
        
        <div>
            {sectionData && sectionData.map((item) => {
                const correspondingSummary = reportSummary.find((summary) => summary.section === item?.id);
                return (
                    <div style={{ marginTop: "2rem" }}>
                        <div className='report_details_result_box'>
                            <h6> <span style={{ marginRight: "1rem" }}>{item?.sequence}.</span>{item?.name}</h6>
                        </div>
                        <div className='table-responsive'>
                        <Table className='report_question_table'>
                            <thead>
                                {item?.questions.length > 0 ?
                                    <tr>
                                        <th>#</th>
                                        <th>Question</th>
                                        <th>Answer</th>
                                        <th>Marks</th>
                                        <th>Max. Marks</th>
                                    </tr> :
                                    <tr>
                                        <th>#</th>
                                        <th>Question</th>
                                        <th>Answer</th>
                                    </tr>
                                }

                            </thead>
                            <tbody>
                                {item?.questions.length > 0 ? item?.questions.map((question) => {
                                    const correspondingAnswer = answerData.find((answer) => answer.question === question.id);
                                    return (
                                        <tr key={question.id}>
                                            <td>{question.sequence}</td>
                                            <td>{question.question_txt}</td>
                                            <td>{correspondingAnswer ? correspondingAnswer.answer_text : 'N/A'}</td>
                                            <td>{correspondingAnswer ? correspondingAnswer.marks_obtained : 'N/A'}</td>
                                            <td>{question.max_marks}</td>
                                        </tr>
                                    );
                                }) : <tr>
                                    <td style={{ visibility: "hidden" }}>random</td>
                                    <td colSpan="3">No questions here...</td>
                                </tr>}

                            </tbody>
                        </Table>
                        </div>
                        <div className='report_question_summary'>
                            <p> <b>Total Marks :</b> {correspondingSummary ? `${correspondingSummary.marks_obtained} out of ${correspondingSummary.max_marks}` : 'N/A'}</p>
                            {/* <p style={{ overflow: 'hidden' }}> <b>Section Summary :</b> {correspondingSummary?.auditor_comment} </p> */}
                        </div>
                        <div className='report_question_attachment'>
                            <h5>Attachments :</h5>
                            {attachments[item.id] && (
                                <div className='report_question_attachment_imgcontainer'>
                                    {attachments[item.id].map((attachment) => (
                                        <div key={attachment.id} className="attachment-container">
                                            {renderAttachment(attachment)}
                                        </div>
                                    ))}
                                </div>
                            )}

                            {selectedImage[item.id] && (
                                <div className='larger-image-container' ref={enlargedViewRef}>
                                    <div className='larger-image-container_closebtn'>
                                        <p>Enlarged View </p>
                                        <button className="btn" onClick={() => handleCloseImage(item.id)}><i className="fa-solid fa-x"></i></button>
                                    </div>
                                    <div className='larger-image-container_imgbox'>
                                      {  attachments[item.id].filter(attachment=>attachment.mime_type.split("/").includes("pdf")).find(attachment=>attachment.direct_url===selectedImage[sectionId]) ? (
                                            <div style={{ backgroundColor: "#d3d3d3",padding: ".5rem", display: "flex", justifyContent: "center", alignItems: "center",flexDirection:"column" }}>
                                                <img
                                                    src={pdfdummyimg}
                                                    alt="Large Media"
                                                    className="larger-image"
                                                />
                                            </div>
                                        ) : 
                                            attachments[item.id].filter(attachment=>attachment.mime_type.split("/").includes("video")).find(attachment=>attachment.direct_url===selectedImage[sectionId]) ? (
                                            <>
                                            <p style={{fontWeight:"bold"}}>If You are unable to watch the Video, Please download it</p>
                                            <video controls width="100%" height="400px">
                                                <source src={selectedImage[item.id]} type={`video/${attachments[item.id].filter(attachment=>attachment.mime_type.split("/").includes("video")).find(attachment=>attachment.direct_url===selectedImage[sectionId]).mime_type.split("/").pop().toLowerCase()}`} />
                                                Your browser does not support the video tag.
                                            </video>
                                            </>
                                            // <img src={videoimage} alt="Large Media" className="larger-image" />
                                        )
                                        : 
                                            attachments[item.id].filter(attachment=>attachment.mime_type.split("/").includes("audio")).find(attachment=>attachment.direct_url===selectedImage[sectionId]) ? (
                                            <>
                                            <p style={{fontWeight:"bold"}}>If You are unable to play the Audio, Please download it</p>
                                            <audio controls>
                                            <source src={selectedImage[item.id]} type={`audio/${attachments[item.id].filter(attachment=>attachment.mime_type.split("/").includes("audio")).find(attachment=>attachment.direct_url===selectedImage[sectionId]).mime_type.split("/").pop().toLowerCase()}`} />
                                            Your browser does not support the audio element.
                                          </audio>
                                            </>
                                        )
                                            :
                                            (
                                                <>
                                                <img src={selectedImage[item.id]} alt="Large Media" className="larger-image" />
                                                </>
                                            )}
                                        <div>
                                            <button onClick={() => handleDownloadImage(item.id)} className='btn create_ac_btn'><i class="fa-solid fa-download"></i> Download</button>
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>


                )
            })}

        </div >
    );
}


