import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Table, Alert } from 'react-bootstrap';
import DateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { useFetchAuth } from '../../../utils/hooks/useFetchAuth';
import { PDFDownloadLink } from '@react-pdf/renderer';
import InvoiceSlip from "../dashInvoice/InvoiceSlip";
import base_url from '../../../utils/config';
import moment from 'moment';
import loadingImg from "../../../images/loading.avif";
import "../../../styles/dashreport.css";

export default function Invoices() {
  const pdfRef = useRef();
  const token = localStorage.getItem("token");
  const { data, setData, isLoading } = useFetchAuth("client/mp/invoice?pages=invoice&status=ALL");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [formattedStartDate, setFormattedStartDate] = useState(null);
  const [formattedEndDate, setFormattedEndDate] = useState(null);
  const [searchFetchedData, setSearchFetchedData] = useState({});
  const [searchFetchedYearData, setSearchFetchedYearData] = useState({});
  const [formData, setFormData] = useState({
    year: '',
    product_name: '',
  });
  const [uniqueProductNames, setUniqueProductNames] = useState([]);
  const [uniqueYears, setUniqueYears] = useState([]);
  const [NoDataAlert, setNoDataAlert] = useState(false);
  const [dateKey, setDateKey] = useState(0);
  
  useEffect(()=>{
    getSearchProductNameData(`client/mp/reportproductlist`);
    getSearchYearData(`client/mp/invoice_product_year_list`);
  },[])

  async function  getSearchProductNameData(url) {
		try {
			const headers = {
				'Authorization': `Token ${token}`,
				'Content-Type': 'application/json'
			};

			const options = {
				method: 'GET',
				headers: headers
			};

			const response = await fetch(base_url + url, options);

			if (response.ok) {
				const result = await response.json();
				setSearchFetchedData(result);
				if (result.error) {
					alert("error");
				}
			} else {
				console.error('API request failed:', response.status, response.statusText);
			}
		} catch (error) {
			console.error('An error occurred while fetching the API:', error);
		}
	};
  async function  getSearchYearData(url) {
		try {
			const headers = {
				'Authorization': `Token ${token}`,
				'Content-Type': 'application/json'
			};

			const options = {
				method: 'GET',
				headers: headers
			};

			const response = await fetch(base_url + url, options);

			if (response.ok) {
				const result = await response.json();
				setSearchFetchedYearData(result);
				if (result.error) {
					alert("error");
				}
			} else {
				console.error('API request failed:', response.status, response.statusText);
			}
		} catch (error) {
			console.error('An error occurred while fetching the API:', error);
		}
	};
  const extractUniqueYears = () => {
    if (data.invoice_data_list) {
      const years = new Set(
        data.invoice_data_list.map((invoice) => {
          const date = new Date(invoice.transaction.payment_success_date);
          return date.getFullYear().toString();
        })
      );
      setUniqueYears(Array.from(years));
    }
  };
  const extractUniqueProductNames = () => {
    if (data.invoice_data_list) {
      const productNames = new Set(
        data.invoice_data_list.map((invoice) => invoice?.order?.solution?.name)
      );
      setUniqueProductNames(Array.from(productNames));
    }
  };
  useEffect(() => {
    extractUniqueYears();
    extractUniqueProductNames();
  }, [data.invoice_data_list]);

  const formatDate = (timestamp) => {
    const dateObj = new Date(timestamp);
    const day = dateObj.getDate();
    const month = dateObj.getMonth() + 1;
    const year = dateObj.getFullYear();
    return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
  };
  
  const handleStartDateChange = (date) => {
    if (moment.isMoment(date)) {
      const formattedDate = date.format('YYYY-MM-DD');
      setFormattedStartDate(formattedDate);
    } else if (date === null) {
      setFormattedStartDate(null);;
    }
    setStartDate(date || null);
  };
  

  const handleEndDateChange = (date) => {
    if (moment.isMoment(date)) {
      const formattedDate = date.format('YYYY-MM-DD');
      setFormattedEndDate(formattedDate);
    }else if (date === null) {
      setFormattedEndDate('');
    }
    setEndDate(date || null);
  };
  const isValidEndDate = (currentDate) => {
    return startDate ? currentDate.isAfter(startDate - 1) : true;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
    if (name === 'product_name') {
      getApiData(`client/mp/invoice?pages=invoice&product_name=${value}`);
    }
    if (name === 'year') {
      getApiData(`client/mp/invoice?pages=invoice&year=${value}`);
    }
  };
  const handleDateFilter = () => {
    getApiData(`client/mp/invoice?pages=invoice&start_date=${formattedStartDate}&end_date=${formattedEndDate}`);
  }
const handleCleanFilter = () => {
  setDateKey(dateKey + 1);
  handleStartDateChange(null);
  handleEndDateChange(null);
  getApiData(`client/mp/invoice?pages=invoice&status=ALL`);
}
  const getApiData = async (url) => {
    try {
      const headers = {
        'Authorization': `Token ${token}`,
        'Content-Type': 'application/json'
      };

      const options = {
        method: 'GET',
        headers: headers
      };

      const response = await fetch(base_url + url, options);

      if (response.ok) {
        const result = await response.json();
        if (result.error) {
          setNoDataAlert(true);
        }
        setData(result);
      } else {
        console.error('API request failed:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('An error occurred while fetching the API:', error);
    }
  };

  // const handleCleanFilter = () => {
  //   if (startDateRef.current) {
  //     startDateRef.current.value = '';
  //   }
  //   if (endDateRef.current) {
  //     endDateRef.current.value = '';
  //   }
  // }
  return (
    <div>
      {/* <div className='back_market' style={{ padding: "0rem" }}>
        <Link to="/userdashboardmain/reports">
          <i className="fa-solid fa-arrow-left"></i>
          <span>Back to Projects</span>
        </Link>
      </div> */}
      <Row style={{ marginTop: "3rem" }}>
        <Col md="3">
          <p className="registration_form_content">
            <label>Product Name</label>
            <select
              name='product_name'
              value={formData.product_name}
              onChange={handleChange}
            >
              <option value="">All Products</option>
               {searchFetchedData.unique_product_names
									? Object.keys(searchFetchedData.unique_product_names).map((productname,index) => (
										<option key={index} value={searchFetchedData.unique_product_names[productname]}>
											{searchFetchedData.unique_product_names[productname]}
										</option>
									))
									: null
								}
            </select>
          </p>
        </Col>
        <Col md="3">
          <label style={{ marginRight: "1rem", marginBottom: ".5rem" }}>Year</label>
          <select
            className='dash_report_select report_date_select'
            // style={{ width: "90%" }}
            name='year'
            value={formData.year}
            onChange={handleChange}
          >
            <option value="">All Years</option>
              {searchFetchedYearData.year_list
									? Object.keys(searchFetchedYearData.year_list).map((year,index) => (
										<option key={index} value={searchFetchedYearData.year_list[year]}>
											{searchFetchedYearData.year_list[year]}
										</option>
									))
									: null
								}
          </select>
        </Col>
        <Col md="2">
          <label className='report_date'>Start Date</label>
          <DateTime
          key={`start-date-${dateKey}`}
            value={startDate}
            onChange={handleStartDateChange}
            dateFormat="DD/MM/YYYY"
            timeFormat={false}
            inputProps={{ className: 'dash_report_select report_date_select', 
           }}
            closeOnSelect
          />
        </Col>
        <Col md="2">
          <label className='report_date'>End Date</label>
          <DateTime
          key={`start-date-${dateKey}`}
            value={endDate}
            onChange={handleEndDateChange}
            dateFormat="DD/MM/YYYY"
            timeFormat={false}
            inputProps={{ className: 'dash_report_select report_date_select',
           }}
            isValidDate={isValidEndDate}
            closeOnSelect
          />
        </Col>
        <Col md="1">
          <button className='dash_report_select create_ac_btn dash_report_select_btn'
            onClick={handleDateFilter}
          >Go</button>
        </Col>
        <Col md="1">
          <button className='dash_report_select create_ac_btn dash_report_select_btn'
            onClick={handleCleanFilter}
            
          >Clean</button>
        </Col>
      </Row>
      {NoDataAlert ? (
      <Alert variant="danger" onClose={() => setNoDataAlert(false)} dismissible style={{ textAlign: "center",marginTop:"5rem" }}>
      No payment for any audit cycles
        </Alert>
        ) :
        <div className='table-responsive'>
      <Table className="text-center table" style={{ marginTop: "5rem" }}>
        <thead className='report_details_score_table_head'>
          <tr>
            <th className="fw-bold">DD/MM/YYYY</th>
            <th className="fw-bold">Status</th>
            <th className="fw-bold">Product Name</th>
            <th className="fw-bold">Number of Responses</th>
            <th className="fw-bold">Company Name</th>
            <th className="fw-bold">Total Amount</th>
            <th className="fw-bold">Download</th>
          </tr>
        </thead>
        <tbody className='report_details_score_table_body'>
          { isLoading ? <tr>
            <td colSpan="7"><img src={loadingImg} alt="Loading..." height="180px" /></td>
            </tr> :
          data.invoice_data_list ? (data.invoice_data_list.map((invoice) => (
            <tr key={invoice.transaction.id} >
              <td>{formatDate(invoice.transaction.payment_success_date)}</td>
              <td>{invoice?.order?.payment_status? invoice?.order?.payment_status : "---"}</td>
              <td>{invoice?.order?.solution?.name}</td>
              <td>{invoice?.order?.no_of_response}</td>
              <td>{invoice?.client_profile_data?.brand}</td>
              {invoice?.order?.country_code === "IN" ?
               <td>INR. {invoice?.order?.price + ((invoice?.order?.price * invoice?.order?.solution?.tax?.rate) / 100)} /-</td>
               :
               <td>USD. {invoice?.order?.price + ((invoice?.order?.price * invoice?.order?.solution?.tax?.rate) / 100)} /-</td>
              }
              
              {invoice?.order?.payment_status==="SUCCESS"?
              <td>
                <InvoiceSlip id={invoice?.order?.id}/>
              </td>
              :
              <td>
                 No Invoice
              </td>
}
            </tr>
          )))
           : <tr>
            <td colSpan="7" style={{color: "red"}}>No Invoice found...</td>
            </tr>}

        </tbody>
      </Table>
      </div>
      }
    </div>
  )
}
