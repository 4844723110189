import React, { useEffect,useState } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import manyavarlogo from "../../../images/manyavar_logo_case.png";
import jmccard from "../../../images/jmccard.jpg";
import jmccenter from "../../../images/jmccenter.jpg";
import jmcside1 from "../../../images/jmcside1.png";
import jmcside2 from "../../../images/jmcside2.png";
import side1 from "../../../images/side1.png";
import side2 from "../../../images/side2.png";
import centerimg from "../../../images/Centre.jpg";
import CommonCards from './CommonCards';

export default function JMC() {
    const [isReadMoreOutcome, setIsReadMoreOutcome] = useState(false);
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, []);

    return (
        <div>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: "3rem", marginTop: "1rem" }}>
                <img src={jmccard} alt="jmc_logo" style={{width:"220px"}}/>
                <div className='lead manyavar_heading_text'>
                    <span>JMC Jewellers is a renowned jewelry manufacturer in India for the last 71 years.</span>
                </div>
                <h3 className='lead manyavar_heading'>The challenge at hand was to elevate JMC's standing and performance within the Indore jewelry market. To achieve this objective, the focus was on conducting a thorough comparative analysis of JMC in relation to its competitors in the same market. </h3>
            </div>
            <div className='container'>
                <div className='row row-cols-1 row-cols-md-3'>
                    <div className='col-4'>
                        <div style={{ marginRight: "-20px" }}>
                            <img src={jmcside1} alt="" style={{ width: "100%", height: "100%" }} className='card-img-top img-float' />
                        </div>
                    </div>
                    <div className='col-4'>
                        <div style={{ marginLeft: "-30px", marginTop: "-16px" }}>
                            <img src={jmccenter} alt="" style={{ width: "100%", height: "100%" }} className='card-img-top img-float' />
                        </div>
                    </div>
                    <div className='col-4'>
                        <div style={{ marginLeft: "-24px" }}>
                            <img src={jmcside2} alt="" style={{ width: "100%", height: "100%" }} className='card-img-top img-float' />
                        </div>
                    </div>
                </div>
            </div>
            <div className='manyavar_heading_next_box'>
                <div className='row row-cols-1 row-cols-lg-3 g-5'>
                    <div className='col-lg-4'>
                        <div className='card h-100' style={{ borderRadius: "20px" }}>
                            <div className='card-body shadow' style={{ borderRadius: "20px" }}>
                                <h3 className='card-title mt-sm-4 text-center' style={{ color: "#007DC1" }}>Objective </h3>
                                <p className='card-text mt-sm-4' style={{ padding: "0rem 1rem 1rem 1rem" }}>
                                    <b> 1.</b> Enhance JMC's Position and Performance in the Indore Jewelry Market     <br />   <b>2.</b>The main Objective was to figure out how JMC compared to their competition in Indore. They looked at how many customers visit, how well the staff knows about the jewelry they sell, how they treat customers, the prices they offer, the types of jewelry they sell, and the overall feel of the store.   <br />
                                    <b>3.</b> They decided to address this objective through mystery shopping, the mystery shoppers were sent to the stores to gauge how the staff treats customers, the quality of the products, and the prices.
                                </p>

                            </div>

                        </div>

                    </div>
                    <div className='col-lg-4'>
                        <div className='card h-100' style={{ borderRadius: "20px" }}>
                            <div className='card-body shadow' style={{ borderRadius: "20px" }}>
                                <h3 className='card-title mt-sm-4 text-center' style={{ color: "#007DC1" }}>Approach </h3>
                                <p className='card-text mt-sm-4' style={{ padding: "0rem 1rem 1rem 1rem" }}>
                                    <b> 1.</b> To gather this comprehensive data, an assessment was conducted by examining the jewelry presentation, monitoring foot traffic within the stores, evaluating the staff's knowledge and customer assistance, conducting price comparisons, exploring the diverse range of jewelry available, and closely observing the overall in-store ambiance.        <br />
                                    <b> 2.</b>The mystery shoppers were the working proffestionals about the age of 25 to 30 they possed as a couple who were about to get married and looking for bridal jewelry
                                </p>

                            </div>

                        </div>

                    </div>
                    <div className='col-lg-4'>
                        <div className='card h-100' style={{ borderRadius: "20px" }}>
                            <div className='card-body shadow' style={{ borderRadius: "20px" }}>
                                <h3 className='card-title mt-sm-4 text-center' style={{ color: "#007DC1" }}>Outcome</h3>
                                <p className='card-text mt-sm-4' style={{ padding: "0rem 1rem 1rem 1rem" }}>
                                    <b>  1.</b>The Analysis of the entire jewelry market indore was generated in which the camparison of the price , product quality , product availabity was done amongst the 12 jewelers. <br />
                                    <b> 2.</b> The mystery shopper had to pretend as couple who is about to get married and looking for various kinds of jewelery which encouraged the sellers to show varities of jewelery types to the potential buyer                                    <br />
                                    <b> 3. </b>They did a SWOT analysis to understand the jewelry market in Indore, which means they looked at the strengths, weaknesses, opportunities, and threats. This gave them a <br />
                                    {/* good overall view of the jewelry market in Indore, helping them understand how they compared to their competitors. */}
                                    {isReadMoreOutcome ?
                                        <><span>
                                             good overall view of the jewelry market in Indore, helping them understand how they compared to their competitors.</span> <br />
                                            <span onClick={() => setIsReadMoreOutcome(false)} style={{
                                                color: "blue", cursor: "pointer", fontSize: "14px",
                                                textDecoration: "underline", textUnderlineOffset: "5px",
                                                position: "absolute", right: "3rem",
                                                paddingTop: ".5rem"
                                            }}>Read Less</span> <br /></>
                                        :
                                        <> <span onClick={() => setIsReadMoreOutcome(true)} style={{
                                            color: "blue", cursor: "pointer", fontSize: "14px",
                                            textDecoration: "underline", textUnderlineOffset: "5px",
                                            position: "absolute", right: "3rem",
                                            paddingTop: ".5rem"
                                        }}>Read More</span> <br />
                                        </>
                                    }
                                </p>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
          <CommonCards/>
        </div>
    )
}
