import  base_url  from "../config";
import { useState, useEffect } from 'react';

export function useFetchAuth(url) {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const token = localStorage.getItem("token");
    const existingUser = localStorage.getItem("user_id");

    useEffect(() => {
        getApiData(url);
    }, []);

    const getApiData = async (url) => {
        setIsLoading(true);
        try {
            const headers = {
                'Authorization': `Token ${token}`,
                'Content-Type': 'application/json'
            };

            const options = {
                method: 'GET',
                headers: headers
            };

            const response = await fetch(base_url + url, options);

            if (response.ok) {
                const result = await response.json();
                setData(result);
            } else {
                console.error('API request failed:', response.status, response.statusText);
            }
        } catch (error) {
            console.error('An error occurred while fetching the API:', error);
        }finally {
            setIsLoading(false); 
          }
    };

    return { data, setData , isLoading};
}