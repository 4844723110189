import React from 'react';
import { Col,Table } from 'react-bootstrap';
import { useFetchAuth } from '../../../utils/hooks/useFetchAuth';
import { tr } from 'date-fns/locale';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import loadingImg from "../../../images/loading.avif";
import "../../../styles/dashreport.css";

export default function StoreReportList({projectId}) {
  const { data, isLoading } = useFetchAuth(`client/mp/audit/${projectId}/audit_stores`);
  const navigate = useNavigate();
  const [currentUrl,setCurrentUrl] = useState("");
  useEffect(()=>{
setCurrentUrl(window.location.href);
  },[])
  
    const handleView = (reportId) => {
        const url = `/userdashboardmain/reportslistdetail?reportId=${reportId}`;
        const newTab = window.open(url, '_blank');
    
      }
      const handleviewnavigate = (reportId) => {
        navigate(`/userdashboardmain/reportslistdetail`, {state : reportId })
      }
  return (
    <div>
    <div className='dash_report_table table-responsive' style={{ marginTop: "2rem", paddingTop: "0rem" }}>
      <Table className="text-center store_report_table_mobile" >
        <thead >
          <tr>
            <th className="fw-bold ">Report ID</th>
            <th className="fw-bold ">Audit Date</th>
            <th className="fw-bold ">report status</th>
            <th className="fw-bold ">Report Details</th>
          </tr>
        </thead>
        <tbody>
        {isLoading ? (
          <tr><td colSpan="5">
            <img src={loadingImg} alt="Loading..." height="150px" />
            </td></tr>
        ) :
          data.length>0 ? data.map((report) => (
            <tr key={report?.id}>
              <td className=' fw-bold'>{report?.id}</td>
              <td >{report?.audit_date}</td>
              <td >{report?.status}</td>
              {+currentUrl.split("=").slice(1) ? 
              <td onClick={() => handleviewnavigate(report?.id)} style={{color:"blue",cursor:"pointer"}}> view</td>
              :
              <td ><a href="#" onClick={()=>handleView(report?.id)} target="_blank" style={{textDecoration:"none"}}> view</a></td>
          }
            </tr>
           )) : <tr>
            <td colSpan="5" style={{color:"red"}}>No Reports Available...</td>
            </tr>} 
        </tbody>
      </Table>

    </div>
  </div>
  )
}
