import React, { useEffect } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import "../../../styles/blogs.css";
import cardimg from "../../../images/card.png";
import blog1Img from "../../../images/Blog A.jpg";
import blog2Img from "../../../images/Blog B.jpg";
import blog3Img from "../../../images/Blog C.jpg";
import blog4Img from "../../../images/Blog D.png";
import blog5Img from "../../../images/Blog E.jpg";
import blog6Img from "../../../images/Blog F.jpg";

export default function BlogsMain() {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, []);
    return (
        <>
            <div className='categoryname_banner indiv_blog_heading' style={{ height: "120px" }}>
                <h1 style={{ fontSize: "2.5rem" }}>Blogs</h1>
            </div>
            <Container fluid className='homepage_brand_container' style={{ marginTop: "3rem" }}>
                <Row className='homepage_brand blogs_main_box' style={{ paddingTop: "1rem" }}>
                    <Col className='homepage_brand_box casestudy_box' style={{ borderRadius: "7px" }}>
                        <Link to="/blogs/checkmarketgap">
                            <div className='homepage_brand_img'>
                            <img src={blog1Img} alt="manyvarimg" style={{ borderTopLeftRadius: "7px", borderTopRightRadius: "7px" }} />
                            </div>
                            <div className='homepage_brand_text blogs_main_box_text'>
                                <div>
                                    <h6 style={{ margin: "1rem", textTransform: "uppercase", lineHeight: "1.3rem" }}> <b> Check the Market Gap in Products For Your Online and Offline Stores
                                    </b></h6>
                                    <p style={{ margin: "0rem 1rem", height: "auto" }}>Marketing, Product, and Category Managers play a pivotal role in ensuring the success of their online and offline stores. To do so, they must continually identify market gaps in price and product availability...</p>
                                </div>
                            </div>
                        </Link>
                    </Col>
                    <Col className='homepage_brand_box casestudy_box' style={{ borderRadius: "7px" }}>
                        <Link to="/blogs/retailersaspect">
                            <div className='homepage_brand_img'>
                                <img src={blog2Img} alt="manyvarimg" style={{ borderTopLeftRadius: "7px", borderTopRightRadius: "7px" }} />
                            </div>
                            <div className='homepage_brand_text  blogs_main_box_text'>
                                <div>
                                    <h6 style={{ margin: "1rem", textTransform: "uppercase", lineHeight: "1.3rem" }}> <b> Are Retailers Meeting Your Branding Guidelines
                                    </b></h6>
                                    <p style={{ margin: "0rem 1rem", height: "auto" }}>In the dynamic world of marketing and brand management, ensuring brand consistency across on-ground retailers is crucial to building a strong and cohesive brand identity. A Category Manager responsible for maintaining branding guidelines...</p>
                                </div>
                            </div>
                        </Link>
                    </Col>
                    <Col className='homepage_brand_box casestudy_box' style={{ borderRadius: "7px" }}>
                        <Link to="/blogs/stockavailability">
                            <div className='homepage_brand_img'>
                                <img src={blog3Img} alt="manyvarimg" style={{ borderTopLeftRadius: "7px", borderTopRightRadius: "7px" }} />
                            </div>
                            <div className='homepage_brand_text blogs_main_box_text'>
                                <div>
                                    <h6 style={{ margin: "1rem", textTransform: "uppercase", lineHeight: "1.3rem" }}> <b> Discrepancies in Stock Availability at Your Retail Stores</b></h6>
                                    <p style={{ margin: "0rem 1rem", height: "auto" }}>As a retail/sales manager, one of my primary responsibilities is to ensure that our products are consistently available on the shelves of our partner retailers. The on-shelf availability of our products is crucial...</p>
                                </div>
                            </div>
                        </Link>
                    </Col>
                </Row>
                <Row className='homepage_brand blogs_main_box' style={{ paddingTop: "1rem" }}>
                    <Col className='homepage_brand_box casestudy_box' style={{ borderRadius: "7px" }}>
                        <Link to="/blogs/auditstore">
                            <div className='homepage_brand_img'>
                                <img src={blog4Img} alt="manyvarimg" style={{ borderTopLeftRadius: "7px", borderTopRightRadius: "7px" }} />
                            </div>
                            <div className='homepage_brand_text blogs_main_box_text'>
                                <div>
                                    <h6 style={{ margin: "1rem", textTransform: "uppercase", lineHeight: "1.3rem" }}> <b> Unveiling FloorWalk Marketplace: Revolutionizing On-Ground Research and Auditing (A Quicker Way To Audit Your Stores)
                                    </b></h6>
                                    <p style={{ margin: "0rem 1rem", height: "auto" }}>In a world where understanding consumer behaviour and gaining insights into market trends is crucial for businesses... </p>
                                </div>
                            </div>
                        </Link>
                    </Col>
                    <Col className='homepage_brand_box casestudy_box' style={{ borderRadius: "7px" }}>
                        <Link to="/blogs/genuinereview">
                            <div className='homepage_brand_img'>
                                <img src={blog5Img} alt="manyvarimg" style={{ borderTopLeftRadius: "7px", borderTopRightRadius: "7px" }} />
                            </div>
                            <div className='homepage_brand_text blogs_main_box_text'>
                                <div>
                                    <h6 style={{ margin: "1rem", textTransform: "uppercase", lineHeight: "1.3rem" }}> <b> How Can Genuine Customer Reviews Increase Sales Traction Online
                                    </b></h6>
                                    <p style={{ margin: "0rem 1rem", height: "auto" }}>As a Brand or Marketing Manager, one must be aware of the profound impact that purchasing genuine online reviews from actual customers can have on a company's success. In today's digital age, online reviews... </p>
                                </div>
                            </div>
                        </Link>
                    </Col>
                    <Col className='homepage_brand_box casestudy_box' style={{ borderRadius: "7px" }}>
                        <Link to="/blogs/consumerinsight">
                            <div className='homepage_brand_img'>
                                <img src={blog6Img} alt="manyvarimg" style={{ borderTopLeftRadius: "7px", borderTopRightRadius: "7px" }} />
                            </div>
                            <div className='homepage_brand_text blogs_main_box_text'>
                                <div>
                                    <h6 style={{ margin: "1rem", textTransform: "uppercase", lineHeight: "1.3rem" }}> <b> Need Quick Insights On Your Target Consumers About Your </b></h6>
                                    <p style={{ margin: "0rem 1rem", height: "auto" }}>As a consultant, product manager, or startup owner, the success of a product in the market hinges upon comprehensive insights into its reception and price sensitivity. Understanding where the product is accepted...</p>
                                </div>
                            </div>
                        </Link>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
