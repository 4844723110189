import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function ConsumerInsight() {
    const [modalShow, setModalShow] = useState(false);
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, []);
    return (
        <>
            <div className='categoryname_banner indiv_blog_heading' style={{ height: "120px" }}>
                <h1>Need Quick Insights On Your Target Consumers About Your Product Or Price?
                </h1>
            </div>
            <div className='blog_content_box'>
                <p>As a consultant, product manager, or startup owner, the success of a product in the market hinges upon comprehensive insights into its reception and price sensitivity. Understanding where the product is accepted and evaluating customer sentiment is pivotal for its growth. Hence, in the quest for a market research company, the focus lies on finding a partner capable of offering extensive product evaluation and price sensitivity testing. The ideal company should possess a wide-reaching network across diverse regions, replicating the profiles of our actual customers, and ensuring accurate and valuable insights.

                </p>

                <p>The primary goal is to comprehend where our product stands in the market and how it's perceived by customers. This requires a research partner with an extensive network capable of replicating our customer base across various regions. The ability to gather insights and feedback mirroring our diverse audience's sentiments is paramount.
                </p>
                <p>In addition, price sensitivity testing is crucial for gauging the optimal pricing strategy for our product. It's imperative to work with a research company that can assess how different customer segments react to varying price points. This allows us to optimize our pricing strategy to maximize revenue while remaining competitive.</p>
                <p><b>Key Attributes To Seek in a Market Research Company:</b></p>
                <p><b> 1. Extensive and Diverse Network:</b> The chosen company should boast a wide-reaching network spanning diverse regions, demographics, and behavioral patterns to replicate our actual customer profiles effectively.

                </p>
                <p><b> 2. Experience in Product Evaluation:</b> The company must have a proven track record in product evaluation and market testing. Experience in understanding customer sentiment and acceptance of products similar to ours is a fundamental requirement.
                </p>
                <p><b> 3. Quick turnaround time:</b> Staying ahead of the competition or gaining the first-mover advantage is essential at an age where ideas are implemented every second. For initial research purposes and with a limited budget, a plug-and-play model should be any business owner’s or marketer’s go-to for quick results.
                </p>
                <p><b> 4. Ethical and Confidential Practices:</b> An unwavering commitment to ethical data collection, privacy, and confidentiality is non-negotiable. It's imperative to work with a company that upholds the highest ethical standards in market research practices.</p>
                <p><b>Read about brands that have leveraged FloorWalk’s expertise to evaluate their target markets:
                </b></p>
                <ul>
                    <Link to="/casestudies/shott" style={{ textUnderlineOffset: "8px", color: "#007DC1" }}> <li style={{ listStyle: "inside", marginBottom: ".5rem" }}> <b> Shott :</b> <span>Shott's challenge was to effectively benchmark against 22 competitors in the combined markets of Pune and Hyderabad.Their objective was to conduct comprehensive market research to evaluate the competition, with a particular focus on pricing, ongoing promotions, and the foot traffic dynamics within gaming centers. </span></li></Link>
                    {/* <Link to="/casestudies/manyavar" style={{ textUnderlineOffset: "8px", color: "#007DC1" }}> <li style={{ listStyle: "inside" }}> <b> Manyavar :</b> Apparel & Ethnic Wear Brand</li></Link> */}
                </ul>
               
                <p>FloorWalk Marketplace provides you with a plug-and-play model where all your research needs can be met within moments. With a pre-built questionnaire capturing the essence of initial insights on the market, FloorWalk marketplace allows you to build a research project within minutes and make it live. Start getting insights within a couple of days.
                </p>
                {/* <p><b>Conclusion: </b> purchasing genuine online reviews from actual customers is a strategic investment with substantial long-term benefits. It not only boosts a brand's credibility and trust but also influences consumer behaviour, enhances visibility, and ultimately drives sales. It is a pivotal element of modern marketing strategies, reflecting a brand's commitment to transparency and customer satisfaction. Embracing the power of authentic customer feedback can be a game changer, propelling a brand toward increased sales traction and sustained success in today's digital marketplace.

                </p> */}
            </div>

            <div className='blog_button_container'>
                <button
                    className="btn mb-5 mt-3 btn-md text-center btn-size create_ac_btn"
                    onClick={() =>
                        setModalShow(
                            true,
                        )
                    }
                >
                    Talk to a consultant
                </button>
                <Link to="/exploremarketplace">
                    <button
                        className="btn mb-5 mt-3 btn-md text-center btn-size create_ac_btn"
                    >
                        Explore Marketplace
                    </button>
                </Link>
            </div>
            <Modal
                show={modalShow}
                centered
                size='lg'
                style={{ marginTop: '40px' }}
                scrollable={true}
                className='modal-dialog-scrollable p-6 modal_mobile'
                onHide={() => setModalShow(false)}>
                <div style={{ position: "absolute", right: "2rem", top: ".8rem", cursor: "pointer", }}
                    onClick={() => setModalShow(false)}
                ><i className="fa-solid fa-x"></i></div>
                <iframe
                    frameBorder='0'
                    style={{
                        height: '500px',
                        width: '99%',
                        border: 'none',
                    }}
                    src='https://forms.zohopublic.com/floorwalk/form/MysteryShoppingServices/formperma/xW3CS_zHKW2DcCLHVZUplXsC1wLR_fITFv3kCFrBY9U?zf_rszfm=1'></iframe>
            </Modal>
        </>
    )
}
