import React from 'react'

export default function MobileReview() {
    return (
        <>
            <div className='dash_stepper_box_right_up' style={{padding:"2rem",boxShadow: "2px 4px 10px 2px rgba(0, 0, 0, 0.25)"}}>
                <h4><i className="fa-solid fa-image" style={{marginRight:".2rem"}}></i> Take a Final Look</h4>
                <p>Check the details carefully before proceeding to purchase the product. Once submitted the details cannot be changed. If you wish to edit details before paying, simply press the back button and modify the information</p>
            </div>
        </>
    )
}
