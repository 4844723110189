import React from 'react';
import "../styles/individualcategory.css";
import { Row, Col, Container, Carousel } from "react-bootstrap";
import { Link } from 'react-router-dom';
import "../styles/individualsolutionpage.css";

export default function IndividualSolutionPage() {
    return (
        <div>
            <div className='categoryname_banner'>
                <h1>Solution Name</h1>
            </div>
            <div className='category_product_container'>
                <Row>
                    <h5>Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident quos quia, tenetur nemo rem obcaecati impedit doloremque est maiores velit minus consectetur odio delectus iusto aut debitis sunt, voluptas eos.</h5>
                </Row>
                <Row>
                    <Col md="4" className='category_product_box'>
                        <div className='category_name'>
                            Category Name
                        </div>
                        <div className='category_product_box_img' style={{ background: "url(https://marketplace.floorwalk.in/media/homepage/blogs/anytimehome.png)center/cover" }}>
                            <div className='categoty_price'>
                                Price /Response
                            </div>
                        </div>
                        <div className='category_product_box_text'>
                            <h5>Product Heading</h5>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                        </div>
                        <div className='category_product_btn_container'>
                            <Link to="/individualproduct">
                                <i className="fa-solid fa-arrow-right"></i>
                                <span>Buy Now</span>
                            </Link>
                        </div>
                    </Col>
                    <Col md="4" className='category_product_box'>
                        <div className='category_name'>
                            Category Name
                        </div>
                        <div className='category_product_box_img' style={{ background: "url(https://marketplace.floorwalk.in/media/homepage/blogs/anytimehome.png)center/cover" }}>
                            <div className='categoty_price'>
                                Price /Response
                            </div>
                        </div>
                        <div className='category_product_box_text'>
                            <h5>Product Heading</h5>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                        </div>
                        <div className='category_product_btn_container'>
                            <Link to="/individualproduct">
                                <i className="fa-solid fa-arrow-right"></i>
                                <span>Buy Now</span>
                            </Link>
                        </div>
                    </Col>
                    <Col md="4" className='category_product_box'>
                        <div className='category_name'>
                            Category Name
                        </div>
                        <div className='category_product_box_img' style={{ background: "url(https://marketplace.floorwalk.in/media/homepage/blogs/anytimehome.png)center/cover" }}>
                            <div className='categoty_price'>
                                Price /Response
                            </div>
                        </div>
                        <div className='category_product_box_text'>
                            <h5>Product Heading</h5>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                        </div>
                        <div className='category_product_btn_container'>
                            <Link to="/individualproduct">
                                <i className="fa-solid fa-arrow-right"></i>
                                <span>Buy Now</span>
                            </Link>
                        </div>
                    </Col>
                </Row>

            </div>
            {/************************************************************************ */}
            <div className='explore_market_category_down'>
                <Col md="6" className='individual_solution_down_text'>
                    <h2>BLOG TITLE RELATED TO THE SOLUTIONS/PRODUCTS MENTIONED</h2>
                    <p style={{ margin: "2rem 0rem" }}> Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit suscipit consequatur eum ipsa soluta saepe perferendis quia dolorum ratione veniam odio quibusdam eligendi modi quas, sed placeat debitis quaerat illo. </p>
                    <div className='category_product_btn_container'>
                        <Link to="/individualproduct">
                            <span style={{ marginRight: "2rem" }}>Read Now</span>
                            <i className="fa-solid fa-arrow-right"></i>
                        </Link>
                    </div>
                </Col>
                <Col md="6" className='individual_solution_down_carousel' >
                    <Carousel className='carousel-dark'>
                        <Carousel.Item interval={1000}>
                            <div className='d-flex'>
                                <Col md="6" className='explore_market_category_box individual_product_box'>
                                    <Link to="/individualsolutionpage/:id">
                                        <div className='explore_market_category_img'>
                                            <img src="https://marketplace.floorwalk.in/media/homepage/blogs/ohsohome.png" alt="" />
                                        </div>
                                        <div className='explore_market_category_text'>
                                            <h5>Solution Heading</h5>
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. </p>
                                        </div>
                                    </Link>
                                </Col>
                                <Col md="6" className='explore_market_category_box individual_product_box'>
                                    <Link to="/individualsolutionpage/:id">
                                        <div className='explore_market_category_img'>
                                            <img src="https://marketplace.floorwalk.in/media/homepage/blogs/ohsohome.png" alt="" />
                                        </div>
                                        <div className='explore_market_category_text'>
                                            <h5>Solution Heading</h5>
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. </p>
                                        </div>
                                    </Link>
                                </Col>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item interval={1000}>
                            <div className='d-flex'>
                                <Col md="6" className='explore_market_category_box individual_product_box'>
                                    <Link to="/individualsolutionpage/:id">
                                        <div className='explore_market_category_img'>
                                            <img src="https://marketplace.floorwalk.in/media/homepage/blogs/ohsohome.png" alt="" />
                                        </div>
                                        <div className='explore_market_category_text'>
                                            <h5>Solution Heading</h5>
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. </p>
                                        </div>
                                    </Link>
                                </Col>
                                <Col md="6" className='explore_market_category_box individual_product_box'>
                                    <Link to="/individualsolutionpage/:id">
                                        <div className='explore_market_category_img'>
                                            <img src="https://marketplace.floorwalk.in/media/homepage/blogs/ohsohome.png" alt="" />
                                        </div>
                                        <div className='explore_market_category_text'>
                                            <h5>Solution Heading</h5>
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. </p>
                                        </div>
                                    </Link>
                                </Col>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item interval={1000}>
                            <div className='d-flex'>
                                <Col md="6" className='explore_market_category_box individual_product_box'>
                                    <Link to="/individualsolutionpage/:id">
                                        <div className='explore_market_category_img'>
                                            <img src="https://marketplace.floorwalk.in/media/homepage/blogs/ohsohome.png" alt="" />
                                        </div>
                                        <div className='explore_market_category_text'>
                                            <h5>Solution Heading</h5>
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. </p>
                                        </div>
                                    </Link>
                                </Col>
                                <Col md="6" className='explore_market_category_box individual_product_box'>
                                    <Link to="/individualsolutionpage/:id">
                                        <div className='explore_market_category_img'>
                                            <img src="https://marketplace.floorwalk.in/media/homepage/blogs/ohsohome.png" alt="" />
                                        </div>
                                        <div className='explore_market_category_text'>
                                            <h5>Solution Heading</h5>
                                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. </p>
                                        </div>
                                    </Link>
                                </Col>
                            </div>
                        </Carousel.Item>
                    </Carousel>
                </Col>

            </div>
        </div>
    )
}

