import React, { useEffect } from 'react';
import { Col } from 'react-bootstrap';
import "../styles/auditprojectsetupfinal.css";
import { Link } from 'react-router-dom';

export default function AuditProjectSetupFinal({ updateStep, currentStep, setIsPaymentCompleted }) {
  useEffect(() => {
    setIsPaymentCompleted(true);
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, []);

  return (
      <div className='project_setup_final_container'>
        <h2>Voila! Your Project is ready to be executed.</h2>
        <p>Sit back, we'll take it  from here. Meanwhile visit the  <Link to="/userdashboardmain">USER DASHBOARD </Link>. Your results will be made live there.</p>
      </div>
  )
}
