import React, { useState, useEffect } from 'react';
import "../../styles/dashboardbody.css";
import DashboardStepper from './dashboardStepper/DashboardStepper';
import { Form } from 'react-bootstrap';
import DashboardProjectTab from './dashboardProjectTab/DashboardProjectTab';
import { Link } from 'react-router-dom';
import { useFetchAuth } from '../../utils/hooks/useFetchAuth';
import cup_coffee from "../../images/cup_coffee.avif";
import base_url from '../../utils/config';
import loadingImg from "../../images/loading.avif";
import MobileSlider from './dashboardStepper/MobileSlider';

export default function UserDashboardBody() {
  const token = localStorage.getItem("token");
  const {data,isLoading} = useFetchAuth("client/mp/orderstatus");
  const [isSteppervisible, setIsStepperVisible] = useState(false);
  const [currentDayDate, setCurrentDayDate] = useState("");
  const [profileData,setProfileData] = useState({});
  const [loading,setLoading] = useState(false);


  const { complete_order_count, active_order_count } = data;
  
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
      
    const currentDate = new Date();
    const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    const dayIndex = currentDate.getDay();
    const monthIndex = currentDate.getMonth();
    const dayOfMonth = currentDate.getDate();
    const currentDayDateText = `${weekdays[dayIndex]}, ${months[monthIndex]} ${dayOfMonth}${getDaySuffix(dayOfMonth)}`;
    setCurrentDayDate(currentDayDateText);

    function getDaySuffix(day) {
      if (day >= 11 && day <= 13) {
        return 'th';
      }
      switch (day % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
      }
    }
    getApiData("client/client_profile");
  }, []);
 
  useEffect(() => {
    const isDataAllZero = Object.values(data).filter((item)=>item!==0).length === 0;
    // if(Object.values(data).filter((item)=>item!==0).length === 0){
        if(!(complete_order_count > 0 || active_order_count > 0)){
      setIsStepperVisible(true);
    }else{
      setIsStepperVisible(false);
    }
  }, [data]);

  const getApiData = async (url) => {
    setLoading(true);
      try {
          const headers = {
              'Authorization': `Token ${token}`,
              'Content-Type': 'application/json'
          };
  
          const options = {
              method: 'GET',
              headers: headers
          };
  
          const response = await fetch(base_url + url, options);
  
          if (response.ok) {
              const result = await response.json();
              console.log(result)
              setProfileData(result);
          } else {
              console.error('API request failed:', response.status, response.statusText);
          }
      } catch (error) {
          console.error('An error occurred while fetching the API:', error);
      }finally{
        setLoading(false);
      }
  };
  function handleStepperShow() {
    setIsStepperVisible(!isSteppervisible);
  }
  return (
    <div>
      <div className='deflt_dash_head'>
        <img src={cup_coffee} alt="cup_of_coffee" />
        <div className='deflt_dash_sub_head'>
          <h5>{currentDayDate}</h5>
           {loading? 
          <h2>Good Morning, User!</h2>
          :
          <h2>Good Morning, {profileData?.first_name}!</h2>
          }
        </div>
      </div>
      {isLoading ?   
      <div className='getStarted'>   
        <img src={loadingImg} alt="Loading..." style={{width:"250px"}}/>
        </div> 
        : (
        <>
      <div className='deflt_dash_stepper'>
        <div className='deflt_dash_stepper_heading'>
      <h4 style={{textAlign:"center"}}><b>How To Start Building A Project</b></h4>     
        <div className='hide_btn_container' onClick={handleStepperShow} style={{cursor:"pointer", color:"blue", textDecoration:"underline",textUnderlineOffset:"7px"}}>
            {isSteppervisible  ? "Hide Process" : "Show Process"}
        </div>
        </div>
        {isSteppervisible && <div>
          {/* <h4><b>How To Start Building A Project</b></h4> */}
          <div className='deflt_dash_stepper_container'>
            <DashboardStepper />
          </div>
          <div className='deflt_dash_stepper_container_mobile'>
           <MobileSlider/>
           </div>
        </div>}
      </div>
      {/* { Object.values(data).filter((item)=>item!==0).length>0 ?  */}
      { data && !(complete_order_count > 0 || active_order_count > 0)? 
      (
        <>
           <Link to="/userdashboardmain/projectsetup" style={{color:"black", textDecoration:"none"}}>
         <div className='getStarted' style={{height:"auto"}}>   
        {/* <h1>Let's Get Started...</h1> */}
        <button className="btn btn-lg text-center btn-size create_ac_btn" style={{marginTop:"1rem"}}>Let's Get Started...</button>
        </div>
        </Link>
          {/* <Link to="/userdashboardmain/projectsetup">
      <div className='strt_project_container'>
          <button className='dash_side_list_first strt_project_btn btn mb-4 mt-3 btn-md text-center btn-size'><span><i className="fa-solid fa-plus"></i></span></button>
        </div>
        </Link> */}
        </>
      )
      :
      (
        <>
      <div className='dash_project_summary'>
        <h4><b>Project Summary</b></h4>
        <div className='summary_box_container'>
        <div className='summary_box'>
            <h1>{data?.active_order_count}</h1>
            <p><b>Active Projects</b></p>
          </div>
          <div className='summary_box'>
            <h1>{data?.complete_order_count}</h1>
            <p><b>Delivered Projects</b></p>
          </div>
          {/* <div className='summary_box'>
            <h1>{data?.draft_order_count}</h1>
            <p><b>Draft Projects</b></p>
          </div> */}
        </div>
      </div>

      <div className='dash_project_detail_tab'>
        <DashboardProjectTab />
      </div>
      <Link to="/userdashboardmain/projectsetup">
      <div className='strt_project_container'>
          <button className='dash_side_list_first strt_project_btn btn mb-4 mt-3 btn-md text-center btn-size'><span><i className="fa-solid fa-plus"></i></span></button>
        </div>
        </Link>
        </>
      )
      }
      </>)}
    </div>
      
  )
}


