import React, { useEffect,useState } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import vajorcard from "../../../images/vajorcard.png";
import vajorcenter from "../../../images/vajorcenter.jpg";
import vajorside1 from "../../../images/vajorside1.png";
import vajorside2 from "../../../images/vajorside2.png";
import side1 from "../../../images/side1.png";
import side2 from "../../../images/side2.png";
import centerimg from "../../../images/Centre.jpg";
import amazonLogo from "../../../images/Amazon_logo.png";
import CommonCards from './CommonCards';


export default function Vajor() {
    const [isReadMoreObjective, setIsReadMoreObjective] = useState(false);
    const [isReadMoreOutcome, setIsReadMoreOutcome] = useState(false);
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, []);

    return (
        <div>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: "3rem", marginTop: "1rem" }}>
                <img src={vajorcard} alt="vajor_logo" style={{width:"180px"}}/>
                <div className='lead manyavar_heading_text'>
                    <span>Vajor is a provider of fashion and lifestyle products. It offers dresses, jumpsuits, shirts, tops, kids clothing items, sunglasses, bags, socks, bath and body products, cushion covers, lightings, accessories, etc.</span>
                </div>
                <h3 className='lead manyavar_heading'>The challenge at hand was to ensure that the product presentation within the company's retail outlets aligns with established standards. This necessitated a comprehensive evaluation of the product display, alongside an assessment of staff knowledge, grooming, store ambience, and marketing elements</h3>
            </div>
            <div className='container'>
                <div className='row row-cols-1 row-cols-md-3'>
                    <div className='col-4'>
                        <div style={{ marginRight: "-20px" }}>
                            <img src={vajorside1} alt="" style={{ width: "100%", height: "100%" }} className='card-img-top img-float' />
                        </div>
                    </div>
                    <div className='col-4'>
                        <div style={{ marginLeft: "-30px", marginTop: "-16px" }}>
                            <img src={vajorcenter} alt="" style={{ width: "100%", height: "100%" }} className='card-img-top img-float' />
                        </div>
                    </div>
                    <div className='col-4'>
                        <div style={{ marginLeft: "-24px" }}>
                            <img src={vajorside2} alt="" style={{ width: "100%", height: "100%" }} className='card-img-top img-float' />
                        </div>
                    </div>
                </div>
            </div>
            <div className='manyavar_heading_next_box'>
                <div className='row row-cols-1 row-cols-lg-3 g-5'>
                    <div className='col-lg-4'>
                        <div className='card h-100' style={{ borderRadius: "20px" }}>
                            <div className='card-body shadow' style={{ borderRadius: "20px" }}>
                                <h3 className='card-title mt-sm-4 text-center' style={{ color: "#007DC1" }}>Objective </h3>
                                <p className='card-text mt-sm-4' style={{ padding: "0rem 1rem 1rem 1rem" }}>
                                <b> 1.</b> What was their goal - The primary goal was to check if the product presentation is in accordance with the companys' standard or not.  <br />
                                    <b> 2.</b>  What was their objective for the goal- The main objective of this activity was to check the product diplay and get the proofs on the same along with that the mystery shopper also have to assess staff knowleadge, grooming, Store ambience and marketing elements such as promotional banners to ensure that the <br />
                                    {/* retail outlet maintains a consistent level of quality in terms of its products and services.  <br />
                                    <b>3. </b>How did they decide to address the objective - They conducted a mystery shopping at thier stores with the help of FloorWalk <br /> */}

                                    {isReadMoreObjective ?
                                        <><span>  retail outlet maintains a consistent level of quality in terms of its products and services.  <br />
                                        <b>3. </b>How did they decide to address the objective - They conducted a mystery shopping at thier stores with the help of FloorWalk </span> <br />
                                            <span onClick={() => setIsReadMoreObjective(false)} style={{
                                                color: "blue", cursor: "pointer", fontSize: "14px",
                                                textDecoration: "underline", textUnderlineOffset: "5px",
                                                position: "absolute", right: "3rem",
                                                paddingTop: ".5rem"
                                            }}>Read Less</span> <br />
                                        </>
                                        :
                                        <>  <span onClick={() => setIsReadMoreObjective(true)} style={{
                                            color: "blue", cursor: "pointer", fontSize: "14px",
                                            textDecoration: "underline", textUnderlineOffset: "5px",
                                            position: "absolute", right: "3rem",
                                            paddingTop: ".5rem"
                                        }}>Read More</span> <br />
                                        </>
                                    }
                                </p>

                            </div>

                        </div>

                    </div>
                    <div className='col-lg-4'>
                        <div className='card h-100' style={{ borderRadius: "20px" }}>
                            <div className='card-body shadow' style={{ borderRadius: "20px" }}>
                                <h3 className='card-title mt-sm-4 text-center' style={{ color: "#007DC1" }}>Approach </h3>
                                <p className='card-text mt-sm-4' style={{ padding: "0rem 1rem 1rem 1rem" }}>
                                    <b> 1.</b>  The mystery shopper was assigned specific scenarios designed to assess various elements within the retail environment, including an evaluation of color options and varieties in clothing and home decor, as well as the examination of mannequins, promotional banners, store ambience, product displays, and visual merchandising strategies. <br />
                                    <b> 2.</b>The premium segment of mystery shoppers were taken for this particular mystery shopping.  <br />
                                  
                                </p>

                            </div>

                        </div>

                    </div>
                    <div className='col-lg-4'>
                        <div className='card h-100' style={{ borderRadius: "20px" }}>
                            <div className='card-body shadow' style={{ borderRadius: "20px" }}>
                                <h3 className='card-title mt-sm-4 text-center' style={{ color: "#007DC1" }}>Outcome</h3>
                                <p className='card-text mt-sm-4' style={{ padding: "0rem 1rem 1rem 1rem" }}>
                                    <b>  1.</b> Workflow outcome--  the product displays were well-maintained, with the exception of a few stores where the mannequins lacked proper accessorization. Additionally, there were instances where the pricing information on the storefronts was not clearly presented, and the overall experience at the cash counter fell short of expectations. However, it's worth noting that the brand's signage was well-illuminated and in good working order.            <br />
                                    <b> 2.</b> How using this kind of auditor profile <br />
                                    {/* worked (2 - 3 sentences)The Mystery shoppers used were the target sagment of Vajor which in-turn helped us generate the valuable feedbacks or insights   <br />
                                    <b> 3. </b>Activity Discovery (2-3 sentences, what was observed and analysed post execution) Following the implementation of the audit findings, there was a noticeable improvement in waiting times at the billing counter, and efforts to enhance the upkeep of the mannequins were evident. */}

                                    {isReadMoreOutcome ?
                                        <><span>
                                             worked (2 - 3 sentences)The Mystery shoppers used were the target sagment of Vajor which in-turn helped us generate the valuable feedbacks or insights   <br />
                                    <b> 3. </b>Activity Discovery (2-3 sentences, what was observed and analysed post execution) Following the implementation of the audit findings, there was a noticeable improvement in waiting times at the billing counter, and efforts to enhance the upkeep of the mannequins were evident.</span> <br />
                                            <span onClick={() => setIsReadMoreOutcome(false)} style={{
                                                color: "blue", cursor: "pointer", fontSize: "14px",
                                                textDecoration: "underline", textUnderlineOffset: "5px",
                                                position: "absolute", right: "3rem",
                                                paddingTop: ".5rem"
                                            }}>Read Less</span> <br /></>
                                        :
                                        <> <span onClick={() => setIsReadMoreOutcome(true)} style={{
                                            color: "blue", cursor: "pointer", fontSize: "14px",
                                            textDecoration: "underline", textUnderlineOffset: "5px",
                                            position: "absolute", right: "3rem",
                                            paddingTop: ".5rem"
                                        }}>Read More</span> <br />
                                        </>
                                    }
                                </p>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
           <CommonCards/>
        </div>
    )
}
