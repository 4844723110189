import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import "../styles/homebrand.css";
import shotcard from "../images/shotcard.jpg";
import indicard from "../images/indicard.jpg";
import cardimg from "../images/card.png";
import { Link } from 'react-router-dom';

export function BrandDetails() {
    return (
        // <Row className='h-100 homepage_brand'>
        //     <Col className='homepage_brand_box'>
        //         <div className='homepage_brand_img'>
        //             <img src={manyvarimg} alt="manyvarimg" />
        //         </div>
        //         <div className='homepage_brand_text'>
        //             <div>
        //                 <img src={manyavar} alt="manyvar_logo" style={{ marginLeft: "1rem" }} />
        //                 <h6 style={{ margin: "1rem" }}> <b> Apparel & Ethnic Wear Brand</b></h6>
        //                 <p style={{ margin: "0rem 1rem" }}>Manyavar & Mohey are India’s own brand in ethnic wear and tradtional festive clothing. The objective of this activity was to check... </p>
        //                 <div style={{ marginTop: "1rem" }}>
        //                     <a href="https://floorwalk.in/Manyavar.html" target='_blank'><span>Read their story </span><i className="fa-solid fa-arrow-right" style={{marginTop:"3px"}}></i></a>
        //                 </div>
        //             </div>
        //         </div>
        //     </Col>
        //     <Col className='homepage_brand_box'>
        //         <div className='homepage_brand_img'>
        //             <img src={mahindraimg} alt="mahindraimg" />
        //         </div>
        //         <div className='homepage_brand_text'>
        //             <div>
        //                 <img src={mahindra} alt="mahindra_logo" style={{ marginLeft: "1rem" }} />
        //                 <h6 style={{ margin: "1rem" }}> <b>Automobile Brand</b></h6>
        //                 <p style={{ margin: "0rem 1rem" }}>Mahindra & Mahindra Limited is an Indian multinational car manufacturing corporation. The objective of this activity was to check the infrastructure of... </p>
        //                 <div style={{ marginTop: "1rem" }}>
        //                     <a href="https://floorwalk.in/Mahindra.html" target='_blank'><span>Read their story</span> <i className="fa-solid fa-arrow-right" style={{marginTop:"3px"}}></i></a>
        //                 </div>
        //             </div>
        //         </div>
        //     </Col>
        //     <Col className='homepage_brand_box'>
        //         <div className='homepage_brand_img'>
        //             <img src={porscheimg} alt="porscheimg" />
        //         </div>
        //         <div className='homepage_brand_text'>
        //             <div>
        //                 <img src={porsche} alt="porsche_logo" style={{ marginLeft: "1rem" }} />
        //                 <h6 style={{ margin: "1rem" }}> <b>Luxury Car Brand</b></h6>
        //                 <p style={{ margin: "0rem 1rem" }}>Porsche India Pvt Ltd is a division of the German automobile manufacturer Porsche and the Volkswagen Group India. The objective of this activity... </p>
        //                 <div style={{ marginTop: "1rem" }}>
        //                     <a href="https://floorwalk.in/Porsche.html" target='_blank'><span>Read their story </span><i className="fa-solid fa-arrow-right" style={{marginTop:"3px"}}></i></a>
        //                 </div>
        //             </div>
        //         </div>
        //     </Col>

        // </Row>
        <Row className='homepage_brand casestudy_box_container' style={{ paddingTop: "1rem" }}>
            <Col className='homepage_brand_box casestudy_box' style={{ borderRadius: "7px" }}>
                <Link to="/casestudies/amazon">
                    <div className='homepage_brand_img'>
                        <img src={cardimg} alt="manyvarimg" style={{ borderTopLeftRadius: "7px", borderTopRightRadius: "7px", height: "auto" }} />
                    </div>
                    <div className='homepage_brand_text casestudy_brand_text'>
                        <div>
                            <h5 style={{ marginLeft: "1rem", fontWeight: "bold" }}>Amazon</h5>
                            <p style={{ margin: "0rem 1rem" }}>The client wanted to study the Market price of the various television models. This was mainly to understand price gaps in the online and offline market.</p>
                            <div style={{ marginTop: "1rem", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0rem 1rem" }}>
                                <button className="btn btn-md text-center btn-size create_ac_btn">Online Services</button>
                                <div style={{ border: "2px solid black", padding: "3px 7px", borderRadius: "50%" }}>
                                    <i className="fa-solid fa-arrow-right" style={{ marginTop: "3px", fontSize: "1.2rem" }}></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>
            </Col>
            <Col className='homepage_brand_box casestudy_box' style={{ borderRadius: "7px" }}>
                <Link to="/casestudies/indyverse">
                    <div className='homepage_brand_img'>
                        <img src={indicard} alt="manyvarimg" style={{ borderTopLeftRadius: "7px", borderTopRightRadius: "7px", height: "auto" }} />
                    </div>
                    <div className='homepage_brand_text casestudy_brand_text'>
                        <div>
                            <h5 style={{ marginLeft: "1rem", fontWeight: "bold" }}>Indyverse</h5>
                            <p style={{ margin: "0rem 1rem" }}>The challenge at hand was to bolster sales by leveraging customer reviews and ratings as a means to enhance brand visibility in the target market. </p>
                            <div style={{ marginTop: "1rem", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0rem 1rem" }}>
                                <button className="btn btn-md text-center btn-size create_ac_btn">Online Services</button>
                                <div style={{ border: "2px solid black", padding: "3px 7px", borderRadius: "50%" }}>
                                    <i className="fa-solid fa-arrow-right" style={{ marginTop: "3px", fontSize: "1.2rem" }}></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>
            </Col>
            <Col className='homepage_brand_box casestudy_box' style={{ borderRadius: "7px" }}>
                <Link to="/casestudies/shott">
                    <div className='homepage_brand_img'>
                        <img src={shotcard} alt="manyvarimg" style={{ borderTopLeftRadius: "7px", borderTopRightRadius: "7px", height: "auto" }} />
                    </div>
                    <div className='homepage_brand_text casestudy_brand_text'>
                        <div>
                            <h5 style={{ marginLeft: "1rem", fontWeight: "bold" }}>Shott</h5>
                            <p style={{ margin: "0rem 1rem" }}>The challenge at hand was to ensure that the product presentation within the company's retail outlets aligns with established standards... </p>
                            <div style={{ marginTop: "1rem", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0rem 1rem" }}>
                                <button className="btn btn-md text-center btn-size create_ac_btn">Entertainment</button>
                                <div style={{ border: "2px solid black", padding: "3px 7px", borderRadius: "50%" }}>
                                    <i className="fa-solid fa-arrow-right" style={{ marginTop: "3px", fontSize: "1.2rem" }}></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>
            </Col>

        </Row>
    )
}

export default function HomeBrand() {
    return (
        <Container fluid className='homepage_brand_container'>
            <div className='homepage_brand_heading'>
                <h5>Suggested Reads</h5>
            </div>
            <BrandDetails />
        </Container>
    )
}
