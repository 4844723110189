import { configureStore } from "@reduxjs/toolkit";
import cartSlice from "./cartSlice";
import orderdataslice from "./orderdataslice";
import draftDataSlice from "./draftDataSlice";
import stepSlice from "./stepSlice";

const store = configureStore({
    reducer: {
        cart: cartSlice,
        orderdata: orderdataslice,
        draftData: draftDataSlice,
        step: stepSlice,
    },
})

export default store;