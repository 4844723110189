import { useState, useEffect } from "react"

export const useFetch = (url) => {
  const [data, setData] = useState([])
  useEffect(() => {
    getApiData(url);
  }, []);

  const getApiData = async (url) => {
    try {
      const response = await fetch(url);
      if (response.ok) {
        const result = await response.json();
        setData(result);
      } else {
        console.error('API request failed:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('An error occurred while fetching the API:', error);
    }
  };
  return { data, setData }
}