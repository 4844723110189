import React, { useState,useContext } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import "../styles/homesolution.css";
import { Link } from 'react-router-dom';
import { Jodit } from 'jodit-react';
// import { AppContext } from './MainPage';
import { AppContext } from '../App';

export default function HomeSolution() {
    const {categoryDataMain, setCategoryDataMain} = useContext(AppContext);
    return (

        <Container fluid className='homepage_solution_container'>
            <div className='homepage_solution_heading'>
                <h5>Search by Categories</h5>
            </div>
            <Row className='h-100 homepage_solution'>
                {categoryDataMain.slice(0, 4).map(item => {
                    return (
                        <Col className='homepage_solution_box' key={item.id}>
                            <Link to={"/" + item.url_structure} state={item?.id}>
                                <div className='homepage_solution_img'>
                                    <img src={item.attachments[0]?.preview_url} alt={item.attachments[0]?.file_name} />
                                </div>
                                <div className='homepage_solution_text'>
                                    <div>
                                        <h6><b>{item?.name}</b></h6>
                                        <p>{Jodit.modules.Helpers.stripTags(item?.short_description).split("").slice(0,90).join("")}...</p>
                                    </div>
                                </div>
                                <p className='d-flex justify-content-end align-items-center popular_products_shop' style={{width:"90%"}}>
                                        <i className="fa-solid fa-arrow-right"></i> <span style={{ marginLeft: ".5rem", fontSize: "14px" }}> See more </span>
                                </p>
                            </Link>
                        </Col>
                    )
                })}

            </Row>

        </Container>

    )
}
