import React, { useEffect,useState } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import shotcard from "../../../images/shotcard.jpg";
import shotcenter from "../../../images/shotcenter.jpg";
import shotside1 from "../../../images/shotside1.png";
import shotside2 from "../../../images/shotside2.png";
import side1 from "../../../images/side1.png";
import side2 from "../../../images/side2.png";
import centerimg from "../../../images/Centre.jpg";
import amazonLogo from "../../../images/Amazon_logo.png";
import CommonCards from './CommonCards';
import NewCommonCards from './NewCommonCards';


export default function Shott() {
    const [isReadMoreApproach, setIsReadMoreApproach] = useState(false);
    const [isReadMoreOutcome, setIsReadMoreOutcome] = useState(false);
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, []);

    return (
        <div>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: "3rem", marginTop: "1rem" }}>
                <img src={shotcard} alt="amazon_logo" style={{width:"200px"}}/>
                <div className='lead manyavar_heading_text'>
                    <span>Amusement centre with power-packed simulative games, Bowling, Banquets and Restaurant.</span>
                </div>
                <h3 className='lead manyavar_heading'>Shott's challenge was to effectively benchmark against 22 competitors in the combined markets of Pune and Hyderabad.Their objective was to conduct comprehensive market research to evaluate the competition, with a particular focus on pricing, ongoing promotions, and the foot traffic dynamics within gaming centers.  </h3>
            </div>
            <div className='container'>
                <div className='row row-cols-1 row-cols-md-3'>
                    <div className='col-4'>
                        <div style={{ marginRight: "-20px" }}>
                            <img src={shotside1} alt="" style={{ width: "100%", height: "100%" }} className='card-img-top img-float' />
                        </div>
                    </div>
                    <div className='col-4'>
                        <div style={{ marginLeft: "-30px", marginTop: "-16px" }}>
                            <img src={shotcenter} alt="" style={{ width: "100%", height: "100%" }} className='card-img-top img-float' />
                        </div>
                    </div>
                    <div className='col-4'>
                        <div style={{ marginLeft: "-24px" }}>
                            <img src={shotside2} alt="" style={{ width: "100%", height: "100%" }} className='card-img-top img-float' />
                        </div>
                    </div>
                </div>
            </div>
            <div className='manyavar_heading_next_box'>
                <div className='row row-cols-1 row-cols-lg-3 g-5'>
                    <div className='col-lg-4'>
                        <div className='card h-100' style={{ borderRadius: "20px" }}>
                            <div className='card-body shadow' style={{ borderRadius: "20px" }}>
                                <h3 className='card-title mt-sm-4 text-center' style={{ color: "#007DC1" }}>Objective </h3>
                                <p className='card-text mt-sm-4' style={{ padding: "0rem 1rem 1rem 1rem" }}>
                                <b> 1.</b> Competition benchmarking amongst 22 competitors in Pune and Hyderabad combined   <br />
                                    <b> 2.</b> To set up Shott centers in these areas, Shott aimed to conduct market research to assess competition, specifically focusing on pricing, ongoing promotions, and foot traffic volume within the gaming centers.      <br />
                                    <b>3. </b>They decided to opt for market reaserch services provided by the floorwalk in order to study and generate reports on the entire market of Pune and Hyderabad <br />
                                </p>

                            </div>

                        </div>

                    </div>
                    <div className='col-lg-4'>
                        <div className='card h-100' style={{ borderRadius: "20px" }}>
                            <div className='card-body shadow' style={{ borderRadius: "20px" }}>
                                <h3 className='card-title mt-sm-4 text-center' style={{ color: "#007DC1" }}>Approach </h3>
                                <p className='card-text mt-sm-4' style={{ padding: "0rem 1rem 1rem 1rem" }}>
                                    <b> 1.</b> Mystery shoppers were assigned two distinct time slots, one during weekdays and the other on weekends, to assess crowd patterns. They conducted a thorough examination of game pricing, inquired about available party packages, and assessed the offerings of food and beverage services. Simultaneously, the internal FloorWalk team conducted a location analysis to determine the most suitable sites for establishing Shott gaming centers in Pune and Hyderabad. <br />
                                    {/* <b> 2.</b>Students and young working professionals were used aged between 20 to 27 as the mystery shopper for this particular project  <br /> */}
                                    {/* <b> 3.</b>Tracking when the mystery shopper on visited the gaming centre was a tendious task  <br /> */}
                                    {isReadMoreApproach ? 
                                 <><span> 
                                     <b> 2.</b>Students and young working professionals were used aged between 20 to 27 as the mystery shopper for this particular project  <br />
                                    <b> 3.</b>Tracking when the mystery shopper on visited the gaming centre was a tendious task  <br /> </span>
                                   <span onClick={()=>setIsReadMoreApproach(false)} style={{ color: "blue", cursor: "pointer", fontSize: "14px", 
                                   textDecoration:"underline",textUnderlineOffset:"5px",
                                   position:"absolute",right:"3rem",
                                   paddingTop:".5rem" }}>Read Less</span> <br /></> 
                                   :
                                 <>  <span onClick={()=>setIsReadMoreApproach(true)} style={{ color: "blue", cursor: "pointer", fontSize: "14px", 
                                    textDecoration:"underline",textUnderlineOffset:"5px",
                                    position:"absolute",right:"3rem",
                                    paddingTop:".5rem" }}>Read More</span> <br />
                                    </>
                                }
                                </p>

                            </div>

                        </div>

                    </div>
                    <div className='col-lg-4'>
                        <div className='card h-100' style={{ borderRadius: "20px" }}>
                            <div className='card-body shadow' style={{ borderRadius: "20px" }}>
                                <h3 className='card-title mt-sm-4 text-center' style={{ color: "#007DC1" }}>Outcome</h3>
                                <p className='card-text mt-sm-4' style={{ padding: "0rem 1rem 1rem 1rem" }}>
                                    <b>  1.</b> A comprehensive evaluation was conducted to analyze the distinctive selling points of each competitor. This encompassed a detailed assessment of game pricing, a comparison of available party packages, and an in-depth analysis of food and beverage services, including menu items and pricing. Additionally, through footfall analysis, the peak crowd inflow times were identified. In parallel, a location analysis process was employed to pinpoint potential areas in Pune and Hyderabad 
                                    {/* suitable for the establishment of Shott Centers.           <br />
                                    <b> 2.</b> Younger auditor profile were used as they are the target audience for these gaming zone which turned out to be the perfect fit for the market research project  <br />
                                    <b> 3. </b>Prior to the decision to establish a center in Pune and Hyderabad, a thorough examination of each competitor's strengths, weaknesses, opportunities, and threats was conducted. */}
                                       {isReadMoreOutcome ? 
                                 <><span> 
                                    <b> 2.</b> Younger auditor profile were used as they are the target audience for these gaming zone which turned out to be the perfect fit for the market research project  <br />
                                    <b> 3. </b>Prior to the decision to establish a center in Pune and Hyderabad, a thorough examination of each competitor's strengths, weaknesses, opportunities, and threats was conducted. <br /></span>
                                   <span onClick={()=>setIsReadMoreOutcome(false)} style={{ color: "blue", cursor: "pointer", fontSize: "14px", 
                                   textDecoration:"underline",textUnderlineOffset:"5px",
                                   position:"absolute",right:"3rem",
                                   paddingTop:".5rem" }}>Read Less</span> <br /></> 
                                   :
                                 <> <br /> <span onClick={()=>setIsReadMoreOutcome(true)} style={{ color: "blue", cursor: "pointer", fontSize: "14px", 
                                    textDecoration:"underline",textUnderlineOffset:"5px",
                                    position:"absolute",right:"3rem",
                                    paddingTop:".5rem" }}>Read More</span> <br />
                                    </>
                                }
                                </p>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
          <NewCommonCards/>
        </div>
    )
}
