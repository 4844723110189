import React, { useState, useRef } from 'react';
import { Modal, Tabs, Tab, Row, Col, Alert } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import base_url from '../utils/config';
import { useSelector } from 'react-redux';
import { postSaveForLaterData, postResetPasswordData } from '../utils/services/PostdataHandle';
import ForgotPassword from './accountinfo/ForgotPassword';

export default function AuthModal({ modalShow, setModalShow, url_structure, id }) {
    const navigate = useNavigate();
    const [userId, SetUserId] = useState(0);
    const [emailVerificationBox, setEmailVaerificatinBox] = useState(false);
    const [loginEmailVaerificatinBox, setLoginEmailVaerificatinBox] = useState(false);
    const [forgotEmailVaerificatinBox, setForgotEmailVaerificatinBox] = useState(false);
    const [isForgetPassword, setIsForgetPassword] = useState(false);
    const [isChangePassword, setIsChangePassword] = useState(false);
    const [matchErrorMessage, setMatchErrorMessage] = useState(false);
    const [showResetAlert, setShowResetAlert] = useState(false);
    const [showForgotAlert, setShowForgotAlert] = useState(false);
    const [otp, setOTP] = useState(['', '', '', '']);
    const otpInputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
    const [errorMessage, setErrorMessage] = useState("");
    const [otpErrorMessage, setOtpErrorMessage] = useState("");
    const [forgotErrorMessage, setForgotErrormessage] = useState("");
    const [otpShowAlert, setOtpShowAlert] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [loginShowAlert, setLoginShowAlert] = useState(false);
    const [registerShowAlert, setRegisterShowAlert] = useState(false);
    const [loading, setLoading] = useState(false);
    const [reponseMail, setResponseMail] = useState("");
    const [formData, setFormData] = useState({
        username: '',
        phone: '',
        password: '',
        brand: '',
        firstname: '',
        lastname: '',
        cpassword: '',
        email: '',
    });
    const [errorMessages, setErrorMessages] = useState({
        usernameError: '',
        phoneError: '',
        passwordError: '',
        brandError: '',
        firstnameError: '',
        lastnameError: '',
        cpasswordError: '',
        emailError: '',
    });
    const existingUser = (localStorage.getItem('user_id'));
    const selectedCategoryId = (localStorage.getItem('selectedCategoryId'))
    const auditcountReduxwithId = useSelector((store) => store.orderdata.secondPage.totalAuditsPerStoreWithStoreId);
    // const modifiedauditcountwithId = Object.entries(auditcountReduxwithId).map(([storeId, count]) => ({ store_id: parseInt(storeId), count: parseInt(count) }));
    const modifiedauditcountwithId = Object.entries(auditcountReduxwithId).map(([storeId, count]) => {
        if (count !== "") {
            return { store_id: parseInt(storeId), count: parseInt(count) };
        }
        return null;
    }).filter(item => item !== null);
    const cartItems = useSelector((store) => store.cart);
    const storedInputs = useSelector((store) => store.orderdata.firstPage.inputs);
    const selectedFiles = useSelector((store) => store.orderdata.firstPage.selectedFile)
    function excludeProperties(obj, propsToExclude) {
        const newObj = {};
        for (const key in obj) {
            if (!propsToExclude.includes(key)) {
                newObj[key] = obj[key];
            }
        }
        return newObj;
    }
    // const propsToExclude = ['describe', 'preference'];
    const propsToExclude = ['describe', 'preference', 'selectedFile'];
    const alignment_factors = excludeProperties(storedInputs, propsToExclude);
    const fileData = selectedFiles.length > 0
        ? {
            file_name: selectedFiles[0].fileName,
            file_size: selectedFiles[0].fileSize,
            file_type: selectedFiles[0].fileType,
        }
        : {};
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
        setErrorMessages(prevErrors => ({
            ...prevErrors,
            [`${name}Error`]: ''
        }));
    };

    // ----------------------------------REGISTRATION--------------------------------------------------

    const handleRegistration = (e) => {
        e.preventDefault();
        setLoading(false);
        if (!formData.username) {
            setErrorMessages(prevErrors => ({
                ...prevErrors,
                usernameError: "email can't be empty"
            }));
            setHasError(true);
            setTimeout(() => {
                setErrorMessages(prevErrors => ({
                    ...prevErrors,
                    usernameError: ""
                }));
                setHasError(false);
            }, 1200)
        }

        if (!formData.password) {
            setErrorMessages(prevErrors => ({
                ...prevErrors,
                passwordError: "Password can't be empty"
            }));
            setHasError(true);
            setTimeout(() => {
                setErrorMessages(prevErrors => ({
                    ...prevErrors,
                    passwordError: ""
                }));
                setHasError(false);
            }, 1200)
        }
        if (formData.password && formData.password.length < 8) {
            setErrorMessages(prevErrors => ({
                ...prevErrors,
                passwordError: "Password must be of minimum 8 characters"
            }));
            setHasError(true);
            setTimeout(() => {
                setErrorMessages(prevErrors => ({
                    ...prevErrors,
                    passwordError: ""
                }));
                setHasError(false);
            }, 1200)
        }
        if (!formData.brand) {
            setErrorMessages(prevErrors => ({
                ...prevErrors,
                brandError: "Brand can't be empty"
            }));
            setHasError(true);
            setTimeout(() => {
                setErrorMessages(prevErrors => ({
                    ...prevErrors,
                    brandError: ""
                }));
                setHasError(false);
            }, 1200)
        }
        if (!formData.firstname) {
            setErrorMessages(prevErrors => ({
                ...prevErrors,
                firstnameError: "First name can't be empty"
            }));
            setHasError(true);
            setTimeout(() => {
                setErrorMessages(prevErrors => ({
                    ...prevErrors,
                    firstnameError: ""
                }));
                setHasError(false)
            }, 1200)
        }
        if (!formData.phone) {
            setErrorMessages(prevErrors => ({
                ...prevErrors,
                phoneError: "Mobile number can't be empty"
            }));
            setHasError(true);
            setTimeout(() => {
                setErrorMessages(prevErrors => ({
                    ...prevErrors,
                    phoneError: ""
                }));
                setHasError(false)
            }, 1200)
        }
        const isValidMobileNumber = /^[0-9]{10}$/.test(formData.phone.trim());

        if (formData.phone && !isValidMobileNumber) {
            setErrorMessages(prevErrors => ({
                ...prevErrors,
                phoneError: "Mobile number should be a 10-digit number"
            }));
            setHasError(true);
            setTimeout(() => {
                setErrorMessages(prevErrors => ({
                    ...prevErrors,
                    phoneError: ""
                }));
                setHasError(false)
            }, 1200)
        }

        if (formData.username && formData.password && formData.brand && formData.firstname && formData.phone && isValidMobileNumber) {

            const requestData = {
                username: formData.username,
                phone: formData.phone,
                password: formData.password,
                brand: formData.brand,
                first_name: formData.firstname,
                last_name: formData.lastname,
            };
            // if (formData.phone === "" || (formData.phone && isValidMobileNumber)) {
            setLoading(true);
            fetch(base_url + "client/market_place/signup_api", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestData)
            })
                .then(response => response.json())
                .then(data => {
                    setResponseMail(data?.email);
                    setHasError(false);
                    setRegisterShowAlert(true);
                    setTimeout(() => {
                        setRegisterShowAlert(false);
                        setLoading(false);
                    }, 1700)
                    setErrorMessage(data);
                    SetUserId(data.user);
                    if (data.detail === "Client Registered Successfully. Please Check Email for OTP Verification...") {
                        setEmailVaerificatinBox(true);
                    }
                    if (data && data.client_dashboard_data.client_profile_info.user_id) {
                        localStorage.setItem('user_id', data.client_dashboard_data.client_profile_info.user_id);
                        localStorage.setItem('token', data.token);
                        localStorage.setItem('client_id', data.client_dashboard_data.client_profile_info.client_id);
                        setModalShow(false)
                    }
                    setFormData({
                        username: '',
                        phone: '',
                        password: '',
                        brand: '',
                        firstname: '',
                        lastname: '',
                    });
                })
                .catch(error => {
                    console.error('Error:', error);
                }).finally(() => {
                    setLoading(false);
                })
            // }
        }
    };

    // --------------------------------------LOGIN-----------------------------------------------

    const handleLogin = (e) => {
        e.preventDefault();
        // setLoading(false);
        if (!formData.username) {
            setErrorMessages(prevErrors => ({
                ...prevErrors,
                usernameError: "email can't be empty"
            }));
            setHasError(true);
            setTimeout(() => {
                setErrorMessages(prevErrors => ({
                    ...prevErrors,
                    usernameError: ""
                }));
                setHasError(false);
            }, 1200)
        }

        if (!formData.password) {
            setErrorMessages(prevErrors => ({
                ...prevErrors,
                passwordError: "Password can't be empty"
            }));
            setHasError(true);
            setTimeout(() => {
                setErrorMessages(prevErrors => ({
                    ...prevErrors,
                    passwordError: ""
                }));
                setHasError(false);
            }, 1200)
        }

        if (formData.username && formData.password) {
            setLoading(true);
            const requestData = {
                username: formData.username,
                password: formData.password,
            };

            fetch(base_url + "client/market_place/login_api", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestData)
            })
                .then(response => response.json())
                .then(data => {
                    setResponseMail(data?.email);
                    setHasError(false);
                    setLoginShowAlert(true);
                    setTimeout(() => {
                        setLoginShowAlert(false);
                        setLoading(false);
                    }, 1500)
                    setErrorMessage(data);
                    SetUserId(data.user);
                    if (data.details === "OTP is Shared On Your Email !!") {
                        setLoginEmailVaerificatinBox(true);
                    }
                    if (data && data.client_dashboard_data.client_profile_info.user_id) {
                        localStorage.setItem('user_id', data.client_dashboard_data.client_profile_info.user_id);
                        localStorage.setItem('token', data.token);
                        localStorage.setItem('client_id', data.client_dashboard_data.client_profile_info.client_id);
                        let country_code = localStorage.getItem("selectedCountry") ? localStorage.getItem("selectedCountry") : "IN";
                        setModalShow(false);
                        const requestData = {
                            no_of_response: cartItems.totalQuantity,
                            solution: cartItems.items[0].id,
                            describe: storedInputs.describe,
                            file: fileData,
                            user: +existingUser,
                            status: "DRAFT",
                            alignment_factors: alignment_factors,
                            store: modifiedauditcountwithId,
                            category: +selectedCategoryId,
                            country_code: country_code,
                        };
                        const promise = postSaveForLaterData(requestData, "client/order_mp");
                        promise.then(response => response.json())
                            .then(data => {
                                localStorage.setItem("orderId", data.id);
                                //    navigate(`/stepupprogressbar/${url_structure}`, { state: id })
                                if (!data.detail) {
                                    navigate(`/stepupprogressbar/${url_structure}`, { state: id });
                                }
                                else {
                                    alert("Somathing went wrong, please refresh")
                                }
                            })
                            .catch(error => {
                                console.error('Error:', error);
                            }).finally(() => {
                                setLoading(false);
                            });
                    }
                    setFormData({
                        username: '',
                        password: '',
                    });
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    }

    //-----------------------------------------EMAIL VARIFIUCATION------------------------------------------------------

    const handleOTPInput = (index, event) => {
        const value = event.target.value;
        const newOTP = [...otp];
        if (/^[0-9]*$/.test(value) && index < 3) {
            newOTP[index] = value;
            setOTP(newOTP);
            otpInputRefs[index + 1].current.focus();
        } else if (/^[0-9]*$/.test(value) && index === 3) {
            newOTP[index] = value;
            setOTP(newOTP);
        }
    };
    const handleInputKeyDown = (index, event) => {
        if (event.key === 'Backspace') {
            if (!otp[index] && index > 0) {
                const newOTP = [...otp];
                newOTP[index - 1] = '';
                setOTP(newOTP);
                otpInputRefs[index - 1].current.focus();
            }
        }
    };
    function handleOTP() {
        setLoading(true);
        const enteredOtp = otp.join("").toString();
        const requestData = {
            user: userId,
            otp: enteredOtp,
        };
        fetch(base_url + "client/market_place/verify_email_by_otp", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestData)
        })
            .then(response => response.json())
            .then(data => {
                setHasError(false);
                setOtpShowAlert(true);
                setTimeout(() => {
                    setOtpShowAlert(false);
                    setLoading(false);
                }, 1700)
                setOtpErrorMessage(data);
                if (data && data.client_dashboard_data.client_profile_info.user_id) {

                    localStorage.setItem('user_id', data.client_dashboard_data.client_profile_info.user_id);
                    localStorage.setItem('token', data.token);
                    localStorage.setItem('client_id', data.client_dashboard_data.client_profile_info.client_id);
                    setModalShow(false);
                    const requestData = {
                        no_of_response: cartItems.totalQuantity,
                        solution: cartItems.items[0].id,
                        describe: storedInputs.describe,
                        file: fileData,
                        user: +existingUser,
                        status: "DRAFT",
                        alignment_factors: alignment_factors,
                        store: modifiedauditcountwithId,
                        category: +selectedCategoryId,
                    };
                    const promise = postSaveForLaterData(requestData, "client/order_mp");
                    promise.then(response => response.json())
                        .then(data => {
                            localStorage.setItem("orderId", data.id);
                            if (!data.detail) {
                                navigate(`/stepupprogressbar/${url_structure}`, { state: id });
                            }
                            else {
                                alert("Somathing went wrong, please refresh")
                            }
                        })
                        .catch(error => {
                            console.error('Error:', error);
                        });
                }
            })
            .catch(error => {
                console.error('Error:', error);
            }).finally(() => {
                setLoading(false);
            });
    }

    function handleforgetPasswrdOTP() {
        setLoading(true);
        const enteredOtp = otp.join("").toString();
        const requestData = {
            user: userId,
            otp: enteredOtp,
        };
        fetch(base_url + "client/market_place/verify_email_by_otp", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestData)
        })
            .then(response => response.json())
            .then(data => {
                setHasError(false);
                setOtpShowAlert(true);
                setTimeout(() => {
                    setOtpShowAlert(false);
                    setLoading(false)
                }, 1700)
                setOtpErrorMessage(data);
                if (data && data.client_dashboard_data.client_profile_info.user_id) {
                    localStorage.setItem('user_id', data.client_dashboard_data.client_profile_info.user_id);
                    localStorage.setItem('token', data.token);
                    localStorage.setItem('client_id', data.client_dashboard_data.client_profile_info.client_id);
                    let country_code = localStorage.getItem("selectedCountry") ? localStorage.getItem("selectedCountry") : "IN";

                    const requestData = {
                        no_of_response: cartItems.totalQuantity,
                        solution: cartItems.items[0].id,
                        describe: storedInputs.describe,
                        file: fileData,
                        user: +existingUser,
                        status: "DRAFT",
                        alignment_factors: alignment_factors,
                        store: modifiedauditcountwithId,
                        category: +selectedCategoryId,
                        country_code: country_code,
                    };
                    const promise = postSaveForLaterData(requestData, "client/order_mp");
                    promise.then(response => response.json())
                        .then(data => {
                            localStorage.setItem("orderId", data.id);
                            if (!data.detail) {
                                setForgotEmailVaerificatinBox(false);
                                setIsChangePassword(true);
                                setLoginEmailVaerificatinBox(false);
                            }
                            else {
                                alert("Somathing went wrong, please refresh")
                            }
                        })
                        .catch(error => {
                            console.error('Error:', error);
                        }).finally(() => {
                            setLoading(false);
                        });
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }
    // -------------------------------------FORGETPASSWORD----------------------------------------------

    const handleForgetPasswordSubmit = (e) => {
        e.preventDefault();
        setLoading(false);
        if (!formData.username) {
            setErrorMessages(prevErrors => ({
                ...prevErrors,
                usernameError: "email can't be empty"
            }));
            setHasError(true)
            setTimeout(() => {
                setErrorMessages(prevErrors => ({
                    ...prevErrors,
                    usernameError: ""
                }));
                setHasError(false);
            }, 1200)
        }

        if (formData.username) {
            setLoading(true);
            const requestData = {
                email: formData.username,
            };
            fetch(base_url + "client/market_place/forgot_password_api", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestData)
            })
                .then(response => response.json())
                .then(data => {
                    setHasError(false);
                    setShowForgotAlert(true);
                    setTimeout(() => {
                        setShowForgotAlert(false);
                    }, 1700)
                    setForgotErrormessage(data);
                    SetUserId(data.user);
                    if (data.details === "OTP is Sent In Your Registered Mail !! ") {
                        setForgotEmailVaerificatinBox(true);
                        setIsForgetPassword(false);
                        setLoginEmailVaerificatinBox(false);
                    }
                    setFormData({
                        username: '',
                    });
                })
                .catch(error => {
                    console.error('Error:', error);
                }).finally(() => {
                    setLoading(false);
                });
        }
    }

    // -------------------------------------------CHANGE/RESET PASSWORD--------------------------------------
    const handleResetPasswordSubmit = (e) => {
        e.preventDefault();
        setLoading(false);
        if (!formData.password) {
            setErrorMessages(prevErrors => ({
                ...prevErrors,
                passwordError: "Password can't be empty"
            }));
            setHasError(true);
            setTimeout(() => {
                setErrorMessages(prevErrors => ({
                    ...prevErrors,
                    passwordError: ""
                }));
                setHasError(false);
              }, 1200)
        }
        if (!formData.cpassword) {
            setErrorMessages(prevErrors => ({
                ...prevErrors,
                cpasswordError: "confirm Password can't be empty"
            }));
            setHasError(true);
            setTimeout(() => {
                setErrorMessages(prevErrors => ({
                    ...prevErrors,
                    cpasswordError: ""
                }));
                setHasError(false);
              }, 1200)
        }
        if (formData.cpassword && formData.password !== formData.cpassword) {
            setMatchErrorMessage(true);
            setTimeout(() => {
                setMatchErrorMessage(false);
            }, 1700)
        }

        if (formData.password && formData.password === formData.cpassword) {
            setLoading(true);
            const requestData = {
                password: formData.password,
            };
            const promise = postResetPasswordData(requestData, "client/market_place/verify_and_forgot_password_set_api");
            promise.then(response => response.json())
                .then(data => {
                    setHasError(false);
                    setShowResetAlert(true);
                    setTimeout(() => {
                        setShowResetAlert(false);
                        setLoading(false);
                    }, 1700)
                    setErrorMessage(data);
                    if (data.detail === "Password Changed") {
                        setTimeout(() => {
                            navigate(`/stepupprogressbar/${url_structure}`, { state: id });
                        }, 1000);
                    }

                    setFormData({
                        password: '',
                        cpassword: '',
                    });
                })
                .catch(error => {
                    console.error('Error:', error);
                }).finally(() => {
                    setLoading(false);
                });
        }
    }

    function handleModalShow() {
        setModalShow(false);
        setLoginEmailVaerificatinBox(false);
        setIsForgetPassword(false);
        setIsChangePassword(false);
        setForgotEmailVaerificatinBox(false);
    }
    function handleSignupTab(e) {
        // console.count(e);
        setLoginEmailVaerificatinBox(false);
        setIsForgetPassword(false);
        setIsChangePassword(false);
        setForgotEmailVaerificatinBox(false);
    }

    return (
        <Modal
            show={modalShow}
            centered
            size='lg'
            style={{ background: "rgba(0,0,0,.7)", height: "100vh" }}
            className='modal-dialog-scrollable p-6 modal_mobile'
            onHide={handleModalShow}
        >
            <div
                className='savefr_later_container'
                style={{ paddingTop: "3rem" }}
            >
                <div style={{ position: "absolute", right: "2rem", top: ".8rem", cursor: "pointer", }}
                    onClick={handleModalShow}
                ><i className="fa-solid fa-x"></i></div>
                <Tabs
                    defaultActiveKey="profile"
                    id="justify-tab-example"
                    className="mb-3"
                    justify
                    onSelect={(e) => handleSignupTab(e)}
                >
                    <Tab eventKey="profile" title="Login" className='login_tab' >

                        {/* -----------------------------EMAIL VERIFICATION FOR LOGIN------------------------------------------ */}

                        {loginEmailVaerificatinBox && !isForgetPassword && !isChangePassword && !forgotEmailVaerificatinBox ?
                            (<div
                                style={{
                                    width: '100%',
                                    height: "50vh",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    border: 'none',
                                }}
                            >
                                {otpShowAlert && (<Alert variant={otpErrorMessage.detail === "OTP Verified !! Login Successfully" ? "success" : "danger"} onClose={() => setOtpShowAlert(false)} dismissible>
                                    {otpErrorMessage.detail}
                                </Alert>
                                )}
                                <h4>Client Registered Successfully. </h4>
                                <h5 style={{ fontSize: "1.4rem" }}><b> Please Check <span style={{ color: "green" }}>{reponseMail}</span> & Enter the OTP for verification</b></h5>
                                <div className="otp_box_container">
                                    {otp.map((digit, index) => (
                                        <input
                                            className="otp_box"
                                            key={index}
                                            type="text"
                                            maxLength="1"
                                            value={digit}
                                            onChange={(e) => handleOTPInput(index, e)}
                                            onKeyDown={(e) => handleInputKeyDown(index, e)}
                                            ref={otpInputRefs[index]}
                                        />
                                    ))}
                                </div>
                                {loading ?

                                    <button className="btn mb-5 btn-md text-center btn-size create_ac_btn" type="button" disabled style={{ fontSize: "1rem", background: "#007DC1", color: "white", }}>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: ".5rem" }}></span>
                                        Loading...
                                    </button>

                                    :

                                    <div className="btn mb-5 btn-md text-center btn-size create_ac_btn"
                                        onClick={handleOTP}
                                    >Enter otp</div>
                                }
                            </div>) :

                            // ------------------------------------------FORGET PASSWORD SECTION---------------------------------------
                            isForgetPassword && !loginEmailVaerificatinBox && !isChangePassword ? (
                                <>
                                    {showForgotAlert && (<Alert variant={forgotErrorMessage.details === "OTP is Sent In Your Registered Mail !! " ? "success" : "danger"} onClose={() => setOtpShowAlert(false)} dismissible>
                                        {forgotErrorMessage.details}
                                    </Alert>
                                    )}
                                    <form className="form" onSubmit={handleForgetPasswordSubmit}>
                                        <Row>
                                            <Col md="12" className="d-flex justify-content-center">
                                                <div className="login-box">
                                                    <p className="form-control login_form_control">
                                                        <label>Email<span>*</span></label>
                                                        <input
                                                            type="text"
                                                            placeholder="Email ID"
                                                            name='username'
                                                            value={formData.username}
                                                            onChange={handleChange}
                                                            className={hasError ? "login_input error_border" : "login_input success_border"}
                                                        />
                                                        {errorMessages.usernameError && (
                                                            <span className="error_message">{errorMessages.usernameError}</span>
                                                        )}
                                                    </p>
                                                    {loading ?
                                                        <div className="login_btn_container">
                                                            <button className="loginButton" type="button" disabled style={{ fontSize: ".8rem" }}>
                                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: ".5rem" }}></span>
                                                                Loading...
                                                            </button>
                                                        </div>
                                                        :
                                                        <div className="login_btn_container">
                                                            <button
                                                                className="loginButton"
                                                            >
                                                                Send OTP
                                                            </button>
                                                        </div>
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    </form>
                                </>
                            ) :

                                // --------------------------------------------CHANGE PASSWORD---------------------------------------------- 
                                isChangePassword && !isForgetPassword && !loginEmailVaerificatinBox ? (
                                    <div>
                                        {showResetAlert && (<Alert variant={errorMessage.detail === "Password Changed" ? "success" : "danger"} onClose={() => setShowResetAlert(false)} dismissible style={{ textAlign: "center" }}>
                                            {errorMessage.detail}
                                        </Alert>
                                        )}

                                        <form className="form" onSubmit={handleResetPasswordSubmit}>
                                            <Row>
                                                <Col md="12" className="d-flex justify-content-center">
                                                    <div className="login-box">
                                                        <p className="form-control login_form_control">
                                                            <label htmlFor="login_password">New Password<span>*</span></label>
                                                            <input
                                                                type="password"
                                                                placeholder="*******"
                                                                name='password'
                                                                value={formData.password}
                                                                onChange={handleChange}
                                                                className={hasError ? "login_input error_border" : "login_input success_border"}
                                                            />
                                                            {errorMessages.passwordError && (
                                                                <span className="error_message">{errorMessages.passwordError}</span>
                                                            )}
                                                        </p>
                                                        <p className="form-control login_form_control">
                                                            <label htmlFor="login_password">Confirm Password<span>*</span></label>
                                                            <input
                                                                type="password"
                                                                placeholder="*******"
                                                                name='cpassword'
                                                                value={formData.cpassword}
                                                                onChange={handleChange}
                                                                className={hasError ? "login_input error_border" : "login_input success_border"}
                                                            />
                                                            {errorMessages.cpasswordError && (
                                                                <span className="error_message">{errorMessages.cpasswordError}</span>
                                                            )}
                                                        </p>
                                                        {matchErrorMessage && <span style={{ color: "#ff6f6f", fontSize: ".9rem" }}>Password and Confirm Password doesn't match</span>}
                                                        {loading ?
                                                            <div className="login_btn_container">
                                                                <button className="loginButton" type="button" disabled style={{ fontSize: ".8rem" }}>
                                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: ".5rem" }}></span>
                                                                    Loading...
                                                                </button>
                                                            </div>
                                                            :
                                                            <div className="login_btn_container">
                                                                <button
                                                                    className="btn mb-4 mt-3 btn-md text-center btn-size create_ac_btn"
                                                                >
                                                                    Reset Password
                                                                </button>
                                                            </div>
                                                        }
                                                    </div>
                                                </Col>
                                            </Row>
                                        </form>
                                    </div>
                                ) :
                                    // ---------------------------------------------FORGOT EMAIL VERIFICATION BOX------------
                                    forgotEmailVaerificatinBox && !isForgetPassword && !loginEmailVaerificatinBox ?
                                        (<div
                                            style={{
                                                width: '100%',
                                                height: "50vh",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                border: 'none',
                                            }}
                                        >
                                            {otpShowAlert && (<Alert variant={otpErrorMessage.detail === "OTP Verified !! Login Successfully" ? "success" : "danger"} onClose={() => setOtpShowAlert(false)} dismissible>
                                                {otpErrorMessage.detail}
                                            </Alert>
                                            )}
                                            <h4>Client Registered Successfully. </h4>
                                            <h4><b> Please Check Email & Enter the OTP for verification</b></h4>
                                            <div className="otp_box_container">
                                                {otp.map((digit, index) => (
                                                    <input
                                                        className="otp_box"
                                                        key={index}
                                                        type="text"
                                                        maxLength="1"
                                                        value={digit}
                                                        onChange={(e) => handleOTPInput(index, e)}
                                                        onKeyDown={(e) => handleInputKeyDown(index, e)}
                                                        ref={otpInputRefs[index]}
                                                    />
                                                ))}
                                            </div>
                                            {loading ?

                                                <button className="btn mb-5 btn-md text-center btn-size create_ac_btn" type="button" disabled style={{ fontSize: "1rem", background: "#007DC1", color: "white", }}>
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: ".5rem" }}></span>
                                                    Loading...
                                                </button>

                                                :

                                                <div className="btn mb-5 btn-md text-center btn-size create_ac_btn"
                                                    onClick={handleforgetPasswrdOTP}
                                                >Enter otp</div>
                                            }
                                        </div>
                                        )
                                        :

                                        // -----------------------------------------------LOGIN PAGE-------------------------------------------------
                                        !isChangePassword && !isForgetPassword && !loginEmailVaerificatinBox ?
                                            (
                                                <form className="form" onSubmit={handleLogin}>
                                                    <Row>
                                                        {loginShowAlert && (<Alert variant={errorMessage.detail === "Login Successfully" ? "success" : "danger"} onClose={() => setLoginShowAlert(false)} dismissible style={{ textAlign: "center" }}>
                                                            {errorMessage.detail}
                                                        </Alert>
                                                        )}
                                                        <Col md="12" className="d-flex justify-content-center">
                                                            <div className="login-box">
                                                                <p className="form-control login_form_control">
                                                                    <label>Email<span>*</span></label>
                                                                    <input
                                                                        type="text"
                                                                        placeholder="Email ID"
                                                                        name='username'
                                                                        value={formData.username}
                                                                        onChange={handleChange}
                                                                        className={hasError ? "login_input error_border" : "login_input success_border"}
                                                                    />
                                                                    {errorMessages.usernameError && (
                                                                        <span className="error_message">{errorMessages.usernameError}</span>
                                                                    )}
                                                                </p>
                                                                <p className="form-control login_form_control">
                                                                    <label htmlFor="login_password">Password<span>*</span></label>
                                                                    <input
                                                                        type="password"
                                                                        placeholder="*******"
                                                                        name='password'
                                                                        value={formData.password}
                                                                        onChange={handleChange}
                                                                        className={hasError ? "login_input error_border" : "login_input success_border"}
                                                                    />
                                                                    {errorMessages.passwordError && (
                                                                        <span className="error_message">{errorMessages.passwordError}</span>
                                                                    )}
                                                                </p>
                                                                {loading ?
                                                                    <div className="login_btn_container">
                                                                        <button className="loginButton" type="button" disabled style={{ fontSize: ".8rem" }}>
                                                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: ".5rem" }}></span>
                                                                            Loading...
                                                                        </button>
                                                                    </div>
                                                                    :
                                                                    <div className="login_btn_container">
                                                                        <button
                                                                            className="loginButton"
                                                                        >
                                                                            Log In
                                                                        </button>
                                                                    </div>
                                                                }
                                                                <div className="save-forget-password-auth d-flex">
                                                                    <div className="forget-password-auth"
                                                                        onClick={() => setIsForgetPassword(true)}
                                                                    // onClick={handleforgetpasswrdlink}
                                                                    >
                                                                        Forget Password?
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </form>) : null}
                    </Tab>

                    {/* --------------------------REGISTRATION EMAIL VERIFICATION----------------------------------------- */}

                    <Tab eventKey="home" title="Sign Up" className='registration_tab' 
                    // onclick={handleSignupTab}
                    >
                        {emailVerificationBox ?
                            (<div
                                style={{
                                    width: '100%',
                                    height: "50vh",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    border: 'none',
                                }}
                            >
                                {otpShowAlert && (<Alert variant={otpErrorMessage.detail === "OTP Verified !! Login Successfully" ? "success" : "danger"} onClose={() => setOtpShowAlert(false)} dismissible>
                                    {otpErrorMessage.detail}
                                </Alert>
                                )}
                                <h4>Client Registered Successfully. </h4>
                                <h5 style={{ fontSize: "1.4rem",whiteSpace:"nowrap" }}><b> Please Check <span style={{ color: "green" }}>{reponseMail}</span> & Enter the OTP for verification</b></h5>
                                <div className="otp_box_container">
                                    {otp.map((digit, index) => (
                                        <input
                                            className="otp_box"
                                            key={index}
                                            type="text"
                                            maxLength="1"
                                            value={digit}
                                            onChange={(e) => handleOTPInput(index, e)}
                                            onKeyDown={(e) => handleInputKeyDown(index, e)}
                                            ref={otpInputRefs[index]}
                                        />
                                    ))}
                                </div>
                                {loading ?

                                    <button className="btn mb-5 btn-md text-center btn-size create_ac_btn" type="button" disabled style={{ fontSize: "1rem", background: "#007DC1", color: "white", }}>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: ".5rem" }}></span>
                                        Loading...
                                    </button>

                                    :
                                    <div className="btn mb-5 btn-md text-center btn-size create_ac_btn"
                                        onClick={handleOTP}
                                    >Enter otp</div>}
                            </div>) :

                            // ----------------------------------------REGISTRATION PAGE-----------------------------------------------

                            (<form onSubmit={handleRegistration}>
                                <Row>
                                    {registerShowAlert && (<Alert variant={errorMessage.detail === "Client Registered Successfully. Please Check Email for OTP Verification..." ? "success" : "danger"} onClose={() => setRegisterShowAlert(false)} dismissible>
                                        {errorMessage.details}
                                    </Alert>
                                    )}
                                    <Col md="6">
                                        <p className="registration_form_content">
                                            <label>First Name<span>*</span></label>
                                            <input
                                                type="text"
                                                name='firstname'
                                                value={formData.firstname}
                                                onChange={handleChange}
                                                className={hasError ? "error_border" : "success_border"}
                                            />
                                            {errorMessages.firstnameError && (
                                                <span className="error_message">{errorMessages.firstnameError}</span>
                                            )}
                                        </p>
                                    </Col>
                                    <Col md="6">
                                        <p className="registration_form_content">
                                            <label>Last Name</label>
                                            <input
                                                type="text"
                                                name='lastname'
                                                value={formData.lastname}
                                                onChange={handleChange}
                                                className={hasError ? "error_border" : "success_border"}
                                            />
                                            {errorMessages.lastnameError && (
                                                <span className="error_message">{errorMessages.lastnameError}</span>
                                            )}
                                        </p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="6">
                                        <p className="registration_form_content">
                                            <label>Email<span>*</span></label>
                                            <input
                                                type="email"
                                                name='username'
                                                value={formData.username}
                                                onChange={handleChange}
                                                className={hasError ? "error_border" : "success_border"}
                                            />
                                            {errorMessages.usernameError && (
                                                <span className="error_message">{errorMessages.usernameError}</span>
                                            )}
                                        </p>
                                    </Col>
                                    <Col md="6">
                                        <p className="registration_form_content">
                                            <label>Brand Name<span>*</span></label>
                                            <input
                                                type="text"
                                                name='brand'
                                                value={formData.brand}
                                                onChange={handleChange}
                                                className={hasError ? "error_border" : "success_border"}
                                            />
                                            {errorMessages.brandError && (
                                                <span className="error_message">{errorMessages.brandError}</span>
                                            )}
                                        </p>
                                    </Col>
                                </Row>
                                <Row>

                                    <Col md="2" className='bigscrn_mobilenumber'>
                                        <p className="registration_form_content">
                                            <label htmlFor="registration_phone">Mobile<span>*</span></label>
                                            <select name="country_codes" id="country_codes">
                                                <option value="+91">+91</option>
                                            </select>
                                        </p>
                                    </Col>
                                    <Col md="4" className='bigscrn_mobilenumber'>
                                        <p className="registration_form_content">
                                            <label style={{ visibility: "hidden" }}>Mobile</label>
                                            <input
                                                type="tel"
                                                value={formData.phone}
                                                name='phone'
                                                onChange={handleChange}
                                                className={hasError ? "error_border" : "success_border"}
                                            />
                                            {errorMessages.phoneError && (
                                                <span className="error_message">{errorMessages.phoneError}</span>
                                            )}
                                        </p>
                                    </Col>
  {/* ------------------------------------------MOBILE VIEW------------------------------------------- */}
                                     <Col md="6" className='smallscrn_mobilenumber'>
                                     <label>Mobile <span style={{color:"red"}}>*</span></label>
                                        <p className="registration_form_content" style={{flexDirection:"row"}}>
                                            <select name="country_codes" id="country_codes" style={{width:"20%",padding:"0px",paddingLeft:"5px"}}>
                                                <option value="+91">+91</option>
                                            </select>
                                            <input
                                                type="tel"
                                                value={formData.phone}
                                                style={{width:"80%"}}
                                                name='phone'
                                                onChange={handleChange}
                                                className={hasError ? "error_border" : "success_border"}
                                            />
                                            {errorMessages.phoneError && (
                                                <span className="error_message">{errorMessages.phoneError}</span>
                                            )}
                                        </p>
                                    </Col>
{/* ------------------------------------------------------------------------------------------------------------ */}
                                    <Col md="6">
                                        <p className="registration_form_content">
                                            <label>Password<span>*</span></label>
                                            <input
                                                type="password"
                                                name='password'
                                                value={formData.password}
                                                onChange={handleChange}
                                                className={hasError ? "error_border" : "success_border"}
                                            />
                                            {errorMessages.passwordError && (
                                                <span className="error_message">{errorMessages.passwordError}</span>
                                            )}
                                        </p>
                                    </Col>
                                </Row>
                              
                                <Row className='registration_form_terms'>
                                    <h5>By clicking create account you agree to the terms of use and privacy policy</h5>
                                </Row>
                                {loading ?

                                    <button className="btn mb-5 btn-md text-center btn-size create_ac_btn" type="button" disabled style={{ fontSize: "1rem", background: "#007DC1", color: "white", }}>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: ".5rem" }}></span>
                                        Loading...
                                    </button>

                                    :
                                    <button
                                        className="btn mb-5 mt-3 btn-md text-center btn-size create_ac_btn"
                                    >
                                        Create Account
                                    </button>}
                            </form>)
                        }
                    </Tab>
                </Tabs>
            </div >
        </Modal >
    )
}
