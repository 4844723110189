import React from 'react';

export default function SelectProduct({ updateStep, currentStep }) {
    return (
        <div className='dash_stepper_box_right'>
            <div className='dash_stepper_box_right_up'>
                <i className="fa-solid fa-image"></i>
                <h2>Specify your objective</h2>
                <p>Choose a product from the list of categories or sub-categories. Select the number of responses and click on 'But Now'</p>
            </div>
            <div className='dash_stepper_btn_container'>
                <button onClick={() => updateStep(currentStep + 1)} className='dash_stepper_btn_container_btn'>Next Step</button>
            </div>
        </div>
    )
}
