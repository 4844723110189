import React, { useState } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import ReportListDetail from './ReportListDetail';
import { useFetchAuth } from '../../../utils/hooks/useFetchAuth';
import ShimmerTableRow from '../../shimmerComponents/ShimmerTableRow';

export default function ReportList({ project }) {
  const auditCycleId = project?.audit_cycle?.id;
  const { data } = useFetchAuth(`client/mp/reportlist/${auditCycleId}`);
  
  const handleView = () => {
    const url = `/userdashboardmain/reportslistdetail?projectId=${auditCycleId}`;
    const newTab = window.open(url, '_blank');

  }
  return (
    <div>
      <h4 style={{ marginTop: "2rem", textAlign: "center", marginBottom: "1rem" }}>Report List</h4>
      <div className='dash_report_table' style={{ marginTop: "0rem", paddingTop: "0rem" }}>
        <Table className="text-center table-responsive table_border_head_green" >
          <thead className='dash_tab_table_head_green'>
            <tr>
              <th className="fw-bold table_border">Store Name</th>
              <th className="fw-bold table_border">City</th>
              <th className="fw-bold table_border">State</th>
              <th className="fw-bold table_border">Total Score</th>
              <th className="fw-bold table_border">Details</th>
              <th className="fw-bold table_border">Status</th>
            </tr>
          </thead>
          <tbody>
            {data?.audit_cycle_data?.[0]?.store_data.map((store) => (
              <tr key={store.id}>
                <td className='table_border fw-bold'>{store.store_data.name}</td>
                <td className='table_border'>{store.store_data.city.name}</td>
                <td className='table_border'>{store.store_data.city.state_name}</td>
                <td className='table_border'>---</td>
                <td className='table_border'><a href="#" onClick={handleView} target="_blank" style={{textDecoration:"none"}}> view</a></td>
                <th className="fw-bold table_border">{store.order.status}</th>
              </tr>
            ))}
            {data?.audit_cycle_data?.[0]?.store_data.length === 0 && (
              <tr>
                <td colSpan="8" style={{ textAlign: "center" }}>Loading....</td>
              </tr>
            )}
          </tbody>
        </Table>

      </div>
    </div>
  )
}
