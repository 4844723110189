import React, { useEffect, useState } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import manyavarlogo from "../../../images/manyavar_logo_case.png";
import side1 from "../../../images/side1.png";
import side2 from "../../../images/side2.png";
import centerimg from "../../../images/Centre.jpg";
import CommonCards from './CommonCards';
import garmincard from "../../../images/garmaincard.png";
import garmincenter from "../../../images/garmaincenter.jpg";
import garminside1 from "../../../images/garmainside1.png";
import garminside2 from "../../../images/garmainside2.png";

export default function Garmin() {
    const [isReadMoreObjective, setIsReadMoreObjective] = useState(false);
    const [isReadMoreOutcome, setIsReadMoreOutcome] = useState(false);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, []);

    return (
        <div>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: "3rem", marginTop: "1rem" }}>
                <img src={garmincard} alt="garmin_logo" style={{ width: "250px" }} />
                <div className='lead manyavar_heading_text'>
                    <span>Garmin Ltd designs, develops, manufactures and markets hand-held, portable and fixed-mount global positioning system (GPS)-enabled products. The company also offers navigation, communications, and information products for the automotive/mobile, outdoor, fitness, marine, and general aviation markets.</span>
                </div>
                <h3 className='lead manyavar_heading'>Garmin wanted to check the stock availabiltiy and the product display at the store to understand the reason behind sales count and whether the staff have the knowleadge of the product to communicate to the customer. Also to check whether the sales persons are in adherence to the Net retail price policy of the company.</h3>
            </div>
            <div className='container'>
                <div className='row row-cols-1 row-cols-md-3'>
                    <div className='col-4'>
                        <div style={{ marginRight: "-20px" }}>
                            <img src={garminside1} alt="" style={{ width: "100%", height: "100%" }} className='card-img-top img-float' />
                        </div>
                    </div>
                    <div className='col-4'>
                        <div style={{ marginLeft: "-30px", marginTop: "-16px" }}>
                            <img src={garmincenter} alt="" style={{ width: "100%", height: "100%" }} className='card-img-top img-float' />
                        </div>
                    </div>
                    <div className='col-4'>
                        <div style={{ marginLeft: "-24px" }}>
                            <img src={garminside2} alt="" style={{ width: "100%", height: "100%" }} className='card-img-top img-float' />
                        </div>
                    </div>
                </div>
            </div>
            <div className='manyavar_heading_next_box'>
                <div className='row row-cols-1 row-cols-lg-3 g-5'>
                    <div className='col-lg-4'>
                        <div className='card h-100' style={{ borderRadius: "20px" }}>
                            <div className='card-body shadow' style={{ borderRadius: "20px" }}>
                                <h3 className='card-title mt-sm-4 text-center' style={{ color: "#007DC1" }}>Objective </h3>
                                <p className='card-text mt-sm-4' style={{ padding: "0rem 1rem 1rem 1rem" }}>
                                    <b> 1.</b> Enhance the Customer Experience and Brand Reputation of Garmin Retail Stores.  This goal involves creating an environment where customers feel well-assisted, well-informed, and confident in their purchase decisions when considering Garmin products.     <br />   <b>2.</b> The primary goal was to assess the in-store inventory availability, the presentation of products, and adherence to the Minimum Operating Price (the lowest permissible selling price). The staff underwent an evaluation based on their grooming, their capability to comprehend <br />
                                    {/* and meet customer needs, as well as their depth of product knowledge. The purpose was to ascertain whether the staff demonstrated a high level of proficiency in addressing customer inquiries and upholding the brand's reputation. <br />
<b>3.</b>  They decide to address this object through mystery shoppers who visited the store and analysed all the check points to generate insight  */}
                                    {isReadMoreObjective ?
                                        <><span>  and meet customer needs, as well as their depth of product knowledge. The purpose was to ascertain whether the staff demonstrated a high level of proficiency in addressing customer inquiries and upholding the brand's reputation. <br />
                                            <b>3.</b>  They decide to address this object through mystery shoppers who visited the store and analysed all the check points to generate insight </span> <br />
                                            <span onClick={() => setIsReadMoreObjective(false)} style={{
                                                color: "blue", cursor: "pointer", fontSize: "14px",
                                                textDecoration: "underline", textUnderlineOffset: "5px",
                                                position: "absolute", right: "3rem",
                                                paddingTop: ".5rem"
                                            }}>Read Less</span> <br />
                                        </>
                                        :
                                        <>  <span onClick={() => setIsReadMoreObjective(true)} style={{
                                            color: "blue", cursor: "pointer", fontSize: "14px",
                                            textDecoration: "underline", textUnderlineOffset: "5px",
                                            position: "absolute", right: "3rem",
                                            paddingTop: ".5rem"
                                        }}>Read More</span> <br />
                                        </>
                                    }
                                </p>

                            </div>

                        </div>

                    </div>
                    <div className='col-lg-4'>
                        <div className='card h-100' style={{ borderRadius: "20px" }}>
                            <div className='card-body shadow' style={{ borderRadius: "20px" }}>
                                <h3 className='card-title mt-sm-4 text-center' style={{ color: "#007DC1" }}>Approach </h3>
                                <p className='card-text mt-sm-4' style={{ padding: "0rem 1rem 1rem 1rem" }}>
                                    <b> 1.</b> The approach adopted to successfully deliver the scope of work was as under : customer handling , assess the ability of the staff to understand the requirement of the customers , branding , stock availability , gauge lowest price at which a product or service can be sold . All the major norms set by the brand were checked during the mystery shopping. <br />
                                    <b> 2.</b> Mystery shoppers with High net income were used to perform the mystery shopping. The mystery shopper has to pretend to be in athletics and have look for various kinds watches
                                </p>

                            </div>

                        </div>

                    </div>
                    <div className='col-lg-4'>
                        <div className='card h-100' style={{ borderRadius: "20px" }}>
                            <div className='card-body shadow' style={{ borderRadius: "20px" }}>
                                <h3 className='card-title mt-sm-4 text-center' style={{ color: "#007DC1" }}>Outcome</h3>
                                <p className='card-text mt-sm-4' style={{ padding: "0rem 1rem 1rem 1rem" }}>
                                    <b>  1.</b> he primary concerns identified in the audit were related to stock availability and adherence to the Minimum Operating Price (MOP) for the products. Following the audit, significant improvements were made in stock management to ensure consistent availability. Additionally, it was noted that the staff needed to enhance their uniform presentation. There was an emphasis on improving the overall presentation and organization of products and information, both in-store and on screens, to create a more appealing and cohesive shopping <br />
                                    {/* <b> 2.</b> The mystery shoppers used were high net income people which matches with the target customers of the brand hence helped in potraying a lead as a potential customer                                <br />
                                    <b> 3. </b>After checking, they made sure that the products were available when you visited the store. They also made sure the prices were fair. The staff now wears proper uniforms, so they look more professional and tidy. It gives a better impression.The way products are displayed earlier was improved.Staff formal training was conducted and it showed improvements in customer interactions and staff performance. */}
                                    {isReadMoreOutcome ?
                                        <><span>
                                            experience. <br />
                                            <b> 2.</b> The mystery shoppers used were high net income people which matches with the target customers of the brand hence helped in potraying a lead as a potential customer                                <br />
                                            <b> 3. </b>After checking, they made sure that the products were available when you visited the store. They also made sure the prices were fair. The staff now wears proper uniforms, so they look more professional and tidy. It gives a better impression.The way products are displayed earlier was improved.Staff formal training was conducted and it showed improvements in customer interactions and staff performance.</span>
                                            <span onClick={() => setIsReadMoreOutcome(false)} style={{
                                                color: "blue", cursor: "pointer", fontSize: "14px",
                                                textDecoration: "underline", textUnderlineOffset: "5px",
                                                position: "absolute", right: "3rem",
                                                paddingTop: ".5rem"
                                            }}>Read Less</span> <br /></>
                                        :
                                        <> <br /> <span onClick={() => setIsReadMoreOutcome(true)} style={{
                                            color: "blue", cursor: "pointer", fontSize: "14px",
                                            textDecoration: "underline", textUnderlineOffset: "5px",
                                            position: "absolute", right: "3rem",
                                            paddingTop: ".5rem"
                                        }}>Read More</span> <br />
                                        </>
                                    }
                                </p>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <CommonCards />
        </div>
    )
}
