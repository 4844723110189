import React, { useState,useContext } from 'react';
import { Row, Col, Container, Carousel, Modal } from "react-bootstrap";
import "../styles/howitworks.css";
import { Link } from 'react-router-dom';
import { useFetch } from '../utils/hooks/useFetch';
import base_url from '../utils/config';
import one from "../../src/images/3.png";
import two from "../../src/images/4.png";
import three from "../../src/images/5.png";
import four from "../../src/images/6.png";
import five from "../../src/images/8.png";
import ShimmerPopularFrontPage from './shimmerComponents/ShimmerPopularFrontPage';
// import { AppContext } from './MainPage';
import { AppContext } from '../App';

export default function HowitWorks() {
    const [modalShow, setModalShow] = useState(false);

    const {popularSolutionDataMain,setPopularSolutionDataMain} = useContext(AppContext)
    return (
        <div className='howit_works_home'>
            <Container fluid>
               <div className='blogs_link_box'>
                <Link to="/blogs/auditstore">Unveiling FloorWalk Marketplace: Revolutionizing On-Ground Research and Auditing (A Quicker Way To Audit Your Stores)</Link>
               </div>
                <Row className='h-100 homepage_sec_one_row'>
                    <Col md='5' className='howit_works'>
                        <h5>See How it works</h5>
                        <Carousel className='carousel-dark'>
                            <Carousel.Item interval={2000}>
                                <img
                                    className="d-block w-100 hw_it_wrk_carouse_img"
                                    src={one}
                                    alt="First slide"
                                />
                            </Carousel.Item>
                            <Carousel.Item interval={2000}>
                                <img
                                    className="d-block w-100 hw_it_wrk_carouse_img"
                                    src={two}
                                    alt="Second slide"
                                />
                            </Carousel.Item>
                            <Carousel.Item interval={2000}>
                                <img
                                    className="d-block w-100 hw_it_wrk_carouse_img"
                                    src={three}
                                    alt="Third slide"
                                />
                            </Carousel.Item>
                            <Carousel.Item interval={2000}>
                                <img
                                    className="d-block w-100 hw_it_wrk_carouse_img"
                                    src={four}
                                    alt="Forth slide"
                                />
                            </Carousel.Item>
                            <Carousel.Item interval={2000}>
                                <img
                                    className="d-block w-100 hw_it_wrk_carouse_img"
                                    src={five}
                                    alt="Fifth slide"
                                />
                            </Carousel.Item>
                        </Carousel>

                    </Col>
                    <Col md='5' className='popular_products'>
                        <h5>Popular Products for Retailers</h5>
                        {popularSolutionDataMain.length === 0 ? <ShimmerPopularFrontPage /> :
                            <Row className='popular_products_boxes'>
                                {popularSolutionDataMain && popularSolutionDataMain.slice(0, 2).map((item) => (
                                    <Col key={item.id} className='popular_products_col'>
                                        <Link to={item?.categories[0]?.url_structure + "/" + item?.url_structure} state={{id:item?.id,name:"popular",categoryId:item?.categories[0]?.id}}>
                                            <div className='popular_products_boxes_img'>
                                                <img src={item?.attachments[0]?.preview_url} alt={item?.attachments[0]?.extra_properties.file_name} />
                                                <div className="popular_products_content">
                                                    <h5>{item?.name}</h5>
                                                </div>
                                            </div>
                                        </Link>
                                        <p>
                                            <Link to={item?.categories[0]?.url_structure + "/" + item?.url_structure} state={{id:item?.id,name:"popular",categoryId:item?.categories[0]?.id}} className='popular_products_shop'>
                                                <i className="fa-solid fa-arrow-right"></i> <span style={{ marginLeft: ".5rem" }}> shop now </span>
                                            </Link>
                                        </p>

                                    </Col>
                                ))}
                            </Row>}
                        {popularSolutionDataMain.length === 0 ? <ShimmerPopularFrontPage /> :
                            <Row className='popular_products_boxes'>
                                {popularSolutionDataMain && popularSolutionDataMain.slice(2, 4).map((item) => (
                                    <Col key={item.id} className='popular_products_col'>
                                        <Link to={item?.categories[0]?.url_structure + "/"  + item?.url_structure} state={{id:item?.id,name:"popular",categoryId:item?.categories[0]?.id}} >
                                            <div className='popular_products_boxes_img'>
                                                <img src={item?.attachments[0]?.preview_url} alt={item?.attachments[0]?.extra_properties.file_name} />
                                                <div className="popular_products_content">
                                                    <h5>{item.name}</h5>
                                                </div>
                                            </div>
                                        </Link>
                                        <p>
                                            <Link to={item?.categories[0]?.url_structure + "/" + item?.url_structure} state={{id:item?.id,name:"popular",categoryId:item?.categories[0]?.id}} className='popular_products_shop'>
                                                <i className="fa-solid fa-arrow-right"></i> <span style={{ marginLeft: ".5rem" }}> shop now </span>
                                            </Link>
                                        </p>
                                    </Col>
                                ))}
                            </Row>}
                    </Col>
                    {/* ---------------------------------------MOBILE VIEW------------------------------------------------ */}
                    <Col md='5' className='popular_products popular_products_smlscrn'>
                        <h5>Popular Products for Retailers</h5>
                        {popularSolutionDataMain.length === 0 ? <ShimmerPopularFrontPage /> :
                            <div className='popular_products_boxes popular_products_boxes_smlscrn'>
                                {popularSolutionDataMain && popularSolutionDataMain.slice(0, 4).map((item) => (
                                    <Col key={item.id} className='popular_products_col popular_products_col_smlscrn'>
                                        <Link to={item?.categories[0]?.url_structure + "/"  + item?.url_structure} state={{id:item?.id,name:"popular",categoryId:item?.categories[0]?.id}}>
                                            <div className='popular_products_boxes_img'>
                                                <img src={item?.attachments[0]?.preview_url} alt={item?.attachments[0]?.extra_properties.file_name} />
                                                <div className="popular_products_content">
                                                    <h5>{item?.name}</h5>
                                                </div>
                                            </div>
                                        </Link>
                                        <p>
                                            <Link to={item?.categories[0]?.url_structure + "/"  + item?.url_structure} state={{id:item?.id,name:"popular"}} className='popular_products_shop'>
                                                <i className="fa-solid fa-arrow-right"></i> <span style={{ marginLeft: ".5rem" }}> shop now </span>
                                            </Link>
                                        </p>

                                    </Col>
                                ))}
                            </div>}
                    </Col>
                    {/* -------------------------------------------------------------------------------------------------- */}
                </Row>
                <div className='howitwrks_tellus howitwrks_tellus_productpage howitwrks_tellus_productpage_mobile'>
                    <div className='howitwrks_tellus_p howitwrks_tellus_productpage_p'>
                        <p style={{ marginBottom: "0rem" }}>Want a personalized solution? Talk to us and tell us what you want </p>
                        <i className="fa-solid fa-arrow-right howitwrks_tellus_productpage_arrow"></i>
                    </div>
                    <div className='howitwrks_tellus_p howitwrks_tellus_productpage_p_mobile'>
                        <p style={{ marginBottom: "0rem" }}>Want a personalized solution? </p>
                        <p>Talk to us and tell us what you want</p>
                    </div>
                    <div>
                        <button
                            onClick={() =>
                                setModalShow(
                                    true,
                                )
                            }>
                            Drop Your Enquiry
                        </button>
                    </div>
                </div>
            </Container>
            <Modal
                show={modalShow}
                centered
                size='lg'
                style={{ marginTop: '40px' }}
                scrollable={true}
                className='modal-dialog-scrollable p-6 modal_mobile'
                onHide={() => setModalShow(false)}>
                <div style={{ position: "absolute", right: "2rem", top: ".8rem", cursor: "pointer", }}
                    onClick={() => setModalShow(false)}
                ><i className="fa-solid fa-x"></i></div>
                <iframe
                    frameBorder='0'
                    style={{
                        height: '500px',
                        width: '99%',
                        border: 'none',
                    }}
                    src='https://forms.zohopublic.com/floorwalk/form/MysteryShoppingServices/formperma/xW3CS_zHKW2DcCLHVZUplXsC1wLR_fITFv3kCFrBY9U?zf_rszfm=1'></iframe>
            </Modal>
        </div>
    )
}
