import React, { useState, useEffect } from 'react';
import "../../../styles/dashreport.css";
import 'react-datetime/css/react-datetime.css';
import { Col, Row, Table, Modal } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import DateTime from 'react-datetime';
import { useFetchAuth } from '../../../utils/hooks/useFetchAuth';
import DetailsProject from './DetailsProject';
import ReportList from './ReportList';
import ShimmerTableRow from '../../shimmerComponents/ShimmerTableRow';
import base_url from '../../../utils/config';
import moment from 'moment';

export default function Reports() {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [formattedStartDate, setFormattedStartDate] = useState(null);
  const [formattedEndDate, setFormattedEndDate] = useState(null);
  const [expandedDetailsRows, setExpandedDetailsRows] = useState({});
  const [expandedReportListRows, setExpandedReportListRows] = useState({});
  const [filterData, setFilterdata] = useState([]);
  const [detailsModalShow, setDetailsModalShow] = useState({});
  const [reportsModalShow, setReportsModalShow] = useState({});
  const [activeProjectId, setActiveProjectId] = useState(null);
  const [reportActiveProjectId, setReportActiveProjectId] = useState(null);
  const [searchFetchedData, setSearchFetchedData] = useState({});
  const [dateKey, setDateKey] = useState(0);
  const [formData, setFormData] = useState({
    status: '',
    product_name: '',
  });
  const { data, setData,isLoading } = useFetchAuth(`client/mp/reports?pages=reports&status=ALL`);

  useEffect(()=>{
    getSearchProductNameData(`client/mp/reportproductlist`)
  },[])

  async function  getSearchProductNameData(url) {
		try {
			const headers = {
				'Authorization': `Token ${token}`,
				'Content-Type': 'application/json'
			};

			const options = {
				method: 'GET',
				headers: headers
			};

			const response = await fetch(base_url + url, options);

			if (response.ok) {
				const result = await response.json();
				setSearchFetchedData(result);
				if (result.error) {
					alert("error");
				}
			} else {
				console.error('API request failed:', response.status, response.statusText);
			}
		} catch (error) {
			console.error('An error occurred while fetching the API:', error);
		}
	};
  const toggleDetailsRow = (itemId) => {
    setActiveProjectId(itemId);
    setDetailsModalShow((prevDetailsModalShow) => ({
      ...prevDetailsModalShow,
      [itemId]: !prevDetailsModalShow[itemId],
    }));
  };
  const toggleReportListRow = (itemId) => {
    setReportActiveProjectId(itemId);
    setReportsModalShow((prevReportsModalShow) => ({
      ...prevReportsModalShow,
      [itemId]: !prevReportsModalShow[itemId],
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
    if (name === 'product_name') {
      getApiData(`client/mp/reports?pages=reports&product_name=${value}`);
    }
    if (name === 'status') {
      getApiData(`client/mp/reports?pages=reports&status=${value}`);
    }
  };

  const handleStartDateChange = (date) => {
    if (moment.isMoment(date)) {
      const formattedDate = date.format('YYYY-MM-DD');
      setFormattedStartDate(formattedDate);
    }
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    if (moment.isMoment(date)) {
      const formattedDate = date.format('YYYY-MM-DD');
      setFormattedEndDate(formattedDate);
    }
  };

  const handleDateFilter = () => {
    getApiData(`client/mp/reports?pages=reports&start_date=${formattedStartDate}&end_date=${formattedEndDate}`);
  }
  const handleCleanFilter = () => {
    setDateKey(dateKey + 1);
    handleStartDateChange(null);
    handleEndDateChange(null);
    getApiData(`client/mp/reports?pages=reports&status=ALL`);
  }
  const getApiData = async (url) => {
    try {
      const headers = {
        'Authorization': `Token ${token}`,
        'Content-Type': 'application/json'
      };

      const options = {
        method: 'GET',
        headers: headers
      };

      const response = await fetch(base_url + url, options);

      if (response.ok) {
        const result = await response.json();
        setData(result);
      } else {
        console.error('API request failed:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('An error occurred while fetching the API:', error);
    }
  };


  const isValidEndDate = (currentDate) => {
    return startDate ? currentDate.isAfter(startDate - 1) : true;
  };

  const handleNavigate = (audit_cycle_id) => {
    navigate("/userdashboardmain/reportstoreslist", { state: audit_cycle_id })
  }
  
  return (
    <div>
      <Row>
        <Col md="3" className='report_upperpart'>
          <p className="registration_form_content">
            <label>Product Name<span>*</span></label>
            <select name='product_name'
              onChange={handleChange}
              value={formData.product_name}
            >
              <option value="">All Products</option>
              {searchFetchedData.unique_product_names
									? Object.keys(searchFetchedData.unique_product_names).map((productname,index) => (
										<option key={index} value={searchFetchedData.unique_product_names[productname]}>
											{searchFetchedData.unique_product_names[productname]}
										</option>
									))
									: null
								}
            </select>
          </p>
        </Col>
        <Col md="2">
          <label className='report_date'>Start Date</label>
          <DateTime
           key={`start-date-${dateKey}`}
            value={startDate}
            onChange={handleStartDateChange}
            dateFormat="DD/MM/YYYY"
            timeFormat={false}
            inputProps={{ className: 'dash_report_select report_date_select' }}
            closeOnSelect
          />
        </Col>
        <Col md="2">
          <label className='report_date'>End Date</label>
          <DateTime
           key={`start-date-${dateKey}`}
            value={endDate}
            onChange={handleEndDateChange}
            dateFormat="DD/MM/YYYY"
            timeFormat={false}
            inputProps={{ className: 'dash_report_select report_date_select' }}
            isValidDate={isValidEndDate}
            closeOnSelect
          />
        </Col>
        <Col md="1">
          {/* <label style={{ marginRight: "1rem",visibility:"hidden" }}>End Date</label> */}
          <button className='dash_report_select create_ac_btn' style={{ marginTop: "1.8rem" }} onClick={handleDateFilter}>Go</button>
        </Col>
        <Col md="1">
          <button className='dash_report_select create_ac_btn dash_report_select_btn'
            onClick={handleCleanFilter}>Clean</button>
        </Col>
        <Col md="2" className='report_upperpart'>
          <p className="registration_form_content">
            <label>Status<span>*</span></label>
            <select name='status'
              onChange={handleChange}
              value={formData.status}
            >
              <option value="">Select</option>
              <option value="ACTIVE">Active</option>
              <option value="COMPLETE">Complete</option>
            </select>
          </p>
        </Col>
      </Row>
      <div className='dash_report_table table-responsive'>
        {data.error ? <div style={{display:"flex",justifyContent:"center", alignItems:"center", color:"red"}}><h3>No Reports Found...</h3></div> :
          (data.length <= 0 ? <ShimmerTableRow /> :
            <Table className="text-center table_border dash_tab_table report_table_mobile table-hover" >
              <thead className='dash_tab_table_head'>
                <tr>
                  <th className="fw-bold table_border">Order Placement Date</th>
                  <th className="fw-bold table_border">Project Name</th>
                  <th className="fw-bold table_border">No. of Reports</th>
                  <th className="fw-bold table_border">Status</th>
                  <th className="fw-bold table_border">Average Score</th>
                </tr>
              </thead>
              <tbody>
                {data?.audit_cycle_data.map((project, index) => (
                    <tr key={index} onClick={() => handleNavigate(project?.id)} style={{ cursor: "pointer"}} >
                      <td className='table_border fw-bold'>{project?.audit_cycle?.start_date}</td>
                      <td className='table_border fw-bold'>{project?.audit_cycle?.name}</td>
                      <td className='table_border'>{project?.audit_cycle?.audit_count}</td>
                      <td className='table_border'>{project?.audit_cycle?.order_status}</td>
                      {project?.audit_score ?
                        <td className='table_border'>{project?.audit_score}%</td>
                        :
                        <td className='table_border'>Yet to be calculated</td>
                    }
                    </tr>
                ))}
              </tbody>
            </Table>
          )}
      </div>
    </div>
  )
}
