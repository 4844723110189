import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    items: [],
    totalQuantity: 0,
    totalAmount: 0,
  },
  reducers: {
    addItem: (state, action) => {
      const newItem = action.payload;
      const existingItem = state.items.find(item => item?.id === newItem?.id);

      if (existingItem) {
        state.items = state.items.map(item => {
          if (item.id === existingItem.id) {
            return {
              ...item,
              quantity: item.quantity + 1,
            };
          }
          return item;
        });
      } else {
        state.items = [];
        state.totalQuantity = 0;
        state.totalAmount = 0;

        state.items.push({
          ...newItem,
          quantity: 1,
        });
      }

      state.totalQuantity += 1;
      if(localStorage.getItem("selectedCountry")){
     if(localStorage.getItem("selectedCountry") === "IN"){
      state.totalAmount += newItem?.price_INR;
     }else{
      state.totalAmount += newItem?.price_USD;
     }
    }else{
      state.totalAmount += newItem?.price_INR;
    }
      
    },
    removeItem: (state, action) => {
      const itemId = action.payload;
      const existingItem = state.items.find(item => item?.id === itemId);
      if (existingItem.quantity === 1) {
        state.items = state.items.filter(item => item?.id !== itemId);
      } else {
        existingItem.quantity -= 1;
      }
      state.totalQuantity -= 1;
      
      if(localStorage.getItem("selectedCountry")){
      if(localStorage.getItem("selectedCountry") === "IN"){
        state.totalAmount -= existingItem?.price_INR;
       }else{
        state.totalAmount -= existingItem?.price_USD;
       }
      }else{
        state.totalAmount -= existingItem?.price_INR;
      }
        
      state.totalAmount = state.items.length ? state.totalAmount : 0;
    },
    //   updateQuantity: (state, action) => {
    //     const { id, quantity } = action.payload;
    //     const existingItem = state.items.find(item => item?.id === id);

    //     if (existingItem) {
    //         const quantityDifference = quantity - existingItem.quantity;
    //         existingItem.quantity = quantity;
    //         state.totalQuantity += quantityDifference;
    //         state.totalAmount += quantityDifference * existingItem.price;
    //     }
    // },
    // ---------------------------------------------------------------------------------------------------
    updateQuantity: (state, action) => {
      const { id, quantity } = action.payload;
      const existingItem = state.items.find(item => item?.id === id);

      if (existingItem) {
        const quantityDifference = quantity - existingItem.quantity;
        existingItem.quantity = quantity;
        state.totalQuantity += quantityDifference;
        if(localStorage.getItem("selectedCountry")){
        if(localStorage.getItem("selectedCountry") === "IN"){
          state.totalAmount += quantityDifference * existingItem.price_INR;
         }else{
          state.totalAmount += quantityDifference * existingItem.price_USD;
         }
        }else{
          state.totalAmount += quantityDifference * existingItem.price_INR;
        }

        if (existingItem.quantity === 0) { //in case of manually removing the number using backSpace, it gives empty string and that makes totaQuantity null, to avoid that this part
          state.items = state.items.filter(item => item.id !== id);
        }
      }
    },
// ---------------------------------------------------------------------------------------------------------
updateQuantity: (state, action) => {
  const { id, quantity } = action.payload;
  const existingItem = state.items.find(item => item?.id === id);

  if (existingItem) {
    const quantityDifference = typeof quantity === 'number' ? quantity - existingItem.quantity : -existingItem.quantity;
    existingItem.quantity = quantity;
    state.totalQuantity += quantityDifference;
    // state.totalAmount += quantityDifference * existingItem.price;
    if(localStorage.getItem("selectedCountry")){
      if(localStorage.getItem("selectedCountry") === "IN"){
        state.totalAmount += quantityDifference * existingItem.price_INR;
       }else{
        state.totalAmount += quantityDifference * existingItem.price_USD;
       }
    }else{
      state.totalAmount += quantityDifference * existingItem.price_INR;
    }
   
    if (existingItem.quantity === 0) {
      state.items = state.items.filter(item => item.id !== id);
    }
  }
},

// ---------------------------------------------------------------------------------------------------------
    clearCart: (state) => {
      state.items = [];
      state.totalQuantity = 0;
      state.totalAmount = 0;
    }
  }
});

export const { addItem, removeItem, clearCart, updateQuantity } = cartSlice.actions;
export default cartSlice.reducer;