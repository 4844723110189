import React, { useState, useEffect,useContext } from 'react';
import { Container, Row, Col, Carousel, Modal } from "react-bootstrap";
import "../styles/exploremarketplace.css";
import { Link } from 'react-router-dom';
import base_url from '../utils/config';
import { useFetch } from '../utils/hooks/useFetch';
import { Jodit } from 'jodit-react';
import Shimmer from './shimmerComponents/Shimmer';
import { AppContext } from '../App';

export default function ExploreMarketPlace() {
  const [modalShow, setModalShow] = useState(false);
  const {categoryDataMain, setCategoryDataMain,popularSolutionDataMain,setPopularSolutionDataMain} = useContext(AppContext);
  useEffect(() => {
    // getApiData(productListUrl);
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
      document.title = "FloorWalk Market Place"   
  }, []);

  return categoryDataMain.length === 0 ? <Shimmer /> : (
    <div>
      <div className='explore_market_banner'>
        <div>
          <h1>FloorWalk Marketplace By Categories</h1>
        </div>
        <div>
          <p>Explore category solutions. Research made easier and faster with our do-it-yourself research tool</p>
        </div>
      </div>
      <div className='explore_market_category'>
        {categoryDataMain.map((item) => {
          return (
            <div className='explore_market_category_box' key={item.id}>
              <Link to={"/" + item.url_structure} state={item?.id}>
                <div className='explore_market_category_img'>
                  <img src={item.attachments[0]?.preview_url} alt={item.attachments[0]?.file_name} />
                </div>
                <div className='explore_market_category_text'>
                  <h5>{item?.name}</h5>
                  <p>{Jodit.modules.Helpers.stripTags(item?.short_description).split("").slice(0,80).join("")}...</p>
                </div>
                <p className='popular_products_shop explore_market_popular_products_shop' style={{padding:"0rem .5rem"}}>
                    <i className="fa-solid fa-arrow-right"></i> <span style={{ marginLeft: ".5rem",fontSize:"14px" }}> See Solutions </span>
                </p>
              </Link>
            </div>
          )
        })}
      </div>
      <div className='explore_market_category_down explore_market_category_down_smallscrn'>
        <Col md="6" className='explore_market_category_down_text explore_market_category_down_text_smallscrn'>
          <h2>You can either go solo or take our company</h2>
          <h5>Looking for a more customized solution for your research or audit? <br /> Let us help you!</h5>
          <p className='explore_market_category_down_text_smallscrn_p'>Floorwalk has expertise across retail, automative, e-commerce, Hospitality and much more. We're here to help you understand and device a customer-centric approach for your service/product delivery.</p>
          <button
            className="btn mb-5 mt-3 btn-md text-center btn-size create_ac_btn"
            onClick={() =>
              setModalShow(
                true,
              )
            }
          >
            Talk to a consultant
          </button>
        </Col>
        <Col md="6" className='explore_market_category_down_carousel_smallscrn'>

          <Carousel className='carousel-dark'>
            <Carousel.Item interval={1000} className='explore_carousel_box'>
              <div className='d-flex' style={{ gap: "1rem", flexWrap: "wrap", padding: ".5rem", paddingTop: "0rem" }}>
                {popularSolutionDataMain.slice(0, 2).map(item => (
                  <Col className='category_product_box' style={{ paddingTop: "0rem" }} key={item.id}>
                     <Link to={"/Popular-Products/" + item?.url_structure} state={item?.id} className='popular_products_shop'>
                      <>
                    <div className='category_product_box_img'>
                      <img src={item?.attachments[0]?.preview_url} alt="" height="200px" />
                      <div className='categoty_price'>
                        {item.price} /Response
                      </div>
                    </div>
                    <div className='category_product_box_text' style={{ height: '100px',overflow:"auto" }}>
                      <h5>{item.name}</h5>
                      <p>{Jodit.modules.Helpers.stripTags(item.short_description).split("").slice(0,90).join("")}...</p>
                    </div>
                    <div className='category_product_btn_container'>
                         <button className="btn btn-md text-center btn-size create_ac_btn">Buy Now</button>
                    </div>
                    </>
                    </Link>
                  </Col>
                ))}
              </div>
            </Carousel.Item>
            <Carousel.Item interval={1000} className='explore_carousel_box'>
              <div className='d-flex' style={{ gap: "1rem", flexWrap: "wrap", padding: ".5rem", paddingTop: "0rem" }}>
                {popularSolutionDataMain.slice(2, 4).map(item => (
                  <Col className='category_product_box' style={{ paddingTop: "0rem" }} key={item.id}>
                       <Link to={"/Popular-Products/" + item?.url_structure} state={item?.id} className='popular_products_shop'>
                      <>
                    <div className='category_product_box_img'>
                      <img src={item?.attachments[0]?.preview_url} alt="" height="200px" />
                      <div className='categoty_price'>
                        {item.price} /Response
                      </div>
                    </div>
                    <div className='category_product_box_text' style={{ height: '100px',overflow:"auto" }}>
                      <h5>{item.name}</h5>
                      <p>{Jodit.modules.Helpers.stripTags(item.short_description).split("").slice(0,90).join("")}...</p>
                    </div>
                    <div className='category_product_btn_container popular_products_shop'>
                         <button className="btn btn-md text-center btn-size create_ac_btn">Buy Now</button>
                    </div>
                    </>
                    </Link>
                  </Col>
                ))}
              </div>
            </Carousel.Item>
          </Carousel>
        </Col>
        <Modal
          show={modalShow}
          centered
          size='lg'
          style={{ marginTop: '40px' }}
          scrollable={true}
          className='modal-dialog-scrollable p-6'
          onHide={() => setModalShow(false)}>
          <iframe
            frameBorder='0'
            style={{
              height: '500px',
              width: '99%',
              border: 'none',
            }}
            src='https://forms.zohopublic.com/floorwalk/form/MysteryShoppingServices/formperma/xW3CS_zHKW2DcCLHVZUplXsC1wLR_fITFv3kCFrBY9U?zf_rszfm=1'></iframe>
        </Modal>
      </div>
    </div>
  )
}

