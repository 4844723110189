import React from 'react'

export default function YourAudit({ updateStep, currentStep }) {
    return (
        <div className='dash_stepper_box_right'>
            <div className='dash_stepper_box_right_up'>
                <i className="fa-solid fa-image"></i>
                <h2>Product/Service Information</h2>
                <p>Tell us the name, brand and look of the product. Additionally write details about how you want the project to be conducted or any specific details you'd like us to keep in mind.</p>
            </div>
            <div className='dash_stepper_btn_container'>
                <button onClick={() => updateStep(currentStep + 1)} className='dash_stepper_btn_container_btn'>Next Step</button>
            </div>
        </div>
    )
}
