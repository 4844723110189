import React from 'react'

export default function MobileSelectProduct() {
    return (
        <>
            <div className='dash_stepper_box_right_up' style={{padding:"2rem",boxShadow: "2px 4px 10px 2px rgba(0, 0, 0, 0.25)"}}>       
                <h4> <i className="fa-solid fa-image" style={{marginRight:".2rem"}}></i> Specify your objective</h4>
                <p>Choose a product from the list of categories or sub-categories. Select the number of responses and click on 'But Now'</p>
            </div>
        </>
    )
}
