import React from 'react';
import "../../styles/shimmer.css";

export default function ShimmerPopularFrontPage() {
  return (
    <div className="shimmerContainer">
            {Array(2).fill("  ").map((el,index) => {
                return (
                    <div className="cardShimmerSmall" key={index}>
                        <div className="cardShimmerUpSmall"></div>
                    </div>
                )
            })}
        </div>
  )
}
