// import { createSlice } from "@reduxjs/toolkit";

// const draftDataSlice = createSlice({
//   name: "draftData",
//   initialState: {
//     draftData: {},
//   },
//   reducers: {
//     setDraftData: (state, action) => {
//       state.draftData = action.payload;
//     },
//     removeDraftData: (state) => {
//       state.draftData = {};
//     },
//   },
// });

// export const { setDraftData,removeDraftData } = draftDataSlice.actions;
// export default draftDataSlice.reducer;

// projectSlice.js
import { createSlice } from '@reduxjs/toolkit';

const draftDataSlice = createSlice({
  name: 'draftData',
  initialState: [],
  reducers: {
    setDraftData: (state, action) => {
      state.push(action.payload);
    },
    removeDraftData: (state) => {
            state = [];
          },
  },
});

export const { setDraftData, removeDraftData } = draftDataSlice.actions;
export default draftDataSlice.reducer;
