import React from 'react'
import Header1 from '../Header1';
import { Outlet } from 'react-router-dom'
import Footer from '../Footer';

export default function AccountInfoMain() {
  return (
    <div>
      <Header1 />
      <Outlet />
      <Footer />
    </div>
  )
}
