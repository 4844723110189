import React,{useEffect} from 'react';
import DashboardSidebar from './DashboardSidebar';
import DashboardHeader from './dashheader/DashboardHeader';
import { Outlet } from 'react-router-dom';
import { Col } from 'react-bootstrap';
import Error from '../Error';
import "../../styles/dashboardSidebar.css";

export default function UserDashboardMain() {
    const existingUser = (localStorage.getItem('user_id'));
    useEffect(() => {
        document.title = "FloorWalk Market Place"   
      }, []);
    return existingUser ? (
        <div style={{ display: "flex", position: "relative" }}>
            <Col md="2" className='dash_sidebar_bigscrn'>
                <DashboardSidebar />
            </Col>
            <Col md="10" className='dash_sidebar_bigscrn'>
                <div className='dashbodymain'>
                    <Outlet />
                </div>
            </Col>
            <Col md="12" className='dash_sidebar_smallscrn' style={{width:"100%"}}>
                <DashboardHeader/>
                <div style={{ padding: "1rem",paddingTop:"1rem" }}>
                    <Outlet />
                </div>
            </Col>
        </div>
    ) : <Error />
}
