import React from 'react';
import {Col} from "react-bootstrap";
import "../styles/exploremarketplace.css";
import { Link } from 'react-router-dom';


export default function SolutionPage() {
  return (
    <div>
      <div className='explore_market_banner'>
        <div>
          <h1>FloorWalk Marketplace By Solutions</h1>
        </div>
        <div>
          <p>Explore category solutions. Research made easier and faster with our do-it-yourself research tool</p>
        </div>
      </div>
      <div className='explore_market_category'>
        <Col md="3" className='explore_market_category_box'>
          <Link to="/individualsolutionpage/:id">
            <div className='explore_market_category_img'>
              <img src="https://marketplace.floorwalk.in/media/homepage/blogs/ohsohome.png" alt="" />
            </div>
            <div className='explore_market_category_text'>
              <h5>Solution Heading</h5>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
            </div>
          </Link>
        </Col>
        <Col md="3" className='explore_market_category_box'>
          <Link to="/individualsolutionpage/:id">
            <div className='explore_market_category_img'>
              <img src="https://marketplace.floorwalk.in/media/homepage/blogs/ohsohome.png" alt="" />
            </div>
            <div className='explore_market_category_text'>
              <h5>Solution Heading</h5>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. </p>
            </div>
          </Link>
        </Col>
        <Col md="3" className='explore_market_category_box'>
          <Link to="/individualsolutionpage/:id">
            <div className='explore_market_category_img'>
              <img src="https://marketplace.floorwalk.in/media/homepage/blogs/ohsohome.png" alt="" />
            </div>
            <div className='explore_market_category_text'>
              <h5>Solution Heading</h5>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. </p>
            </div>
          </Link>
        </Col>
        <Col md="3" className='explore_market_category_box'>
          <Link to="/individualsolutionpage/:id">
            <div className='explore_market_category_img'>
              <img src="https://marketplace.floorwalk.in/media/homepage/blogs/ohsohome.png" alt="" />
            </div>
            <div className='explore_market_category_text'>
              <h5>Solution Heading</h5>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
            </div>
          </Link>
        </Col>
      </div>
      <div className='explore_market_category_down'>
        <Col md="6" className='explore_market_category_down_img' >
           <img src="https://marketplace.floorwalk.in/media/homepage/blogs/anytimehome.png" alt="" />
        </Col>
        <Col md="6" className='explore_market_category_down_text'>
          <h2>You can either go solo or take our company</h2>
          <h5>Looking for a more customized solution for your research or audit? <br /> Let us help you!</h5>
          <p>Floorwalk has expertise across retail, automative, e-commerce, Hospitality and much more. We're here to help you understand and device a customer-centric approach for your service/product delivery.</p>
          <button
            className="btn mb-5 mt-3 btn-md text-center btn-size create_ac_btn"
          >
            Talk to a consultant
          </button>
        </Col>
      </div>
    </div>
  )
}


