import React, { useState } from 'react';
import { Alert } from 'react-bootstrap';
import "../styles/save4latermodal.css";
import base_url from '../utils/config';
import { useSelector } from 'react-redux';
import { postSaveForLaterData } from '../utils/services/PostdataHandle';

export default function Save4LaterModal({ saveForLaterExitClicked, goBack }) {
  const [showAlert, setShowAlert] = useState(false);
  const [loading,setLoading] = useState(false);
  const [showWarningAlert, setShowWarningAlert] = useState(false);
  const existingUser = (localStorage.getItem('user_id'));
  const selectedCategoryId = (localStorage.getItem('selectedCategoryId'))
  const auditcountReduxwithId = useSelector((store) => store.orderdata.secondPage.totalAuditsPerStoreWithStoreId);
  const modifiedauditcountwithId = Object.entries(auditcountReduxwithId).map(([storeId, count]) => {
    if (count !== "") {
      return { store_id: parseInt(storeId), count: parseInt(count) };
    }
    return null; 
  }).filter(item => item !== null);
  
  const cartItems = useSelector((store) => store.cart);
  const storedInputs = useSelector((store) => store.orderdata.firstPage.inputs);
  const selectedFiles = useSelector((store) => store.orderdata.firstPage.selectedFile)
  const orderId = (localStorage.getItem('orderId'))
  function excludeProperties(obj, propsToExclude) {
    const newObj = {};
    for (const key in obj) {
      if (!propsToExclude.includes(key)) {
        newObj[key] = obj[key];
      }
    }
    return newObj;
  }
  // const propsToExclude = ['describe', 'preference'];
  const propsToExclude = ['describe', 'preference', 'selectedFile'];
  const alignment_factors = excludeProperties(storedInputs, propsToExclude);
  const handleSaveforLater = () => {
    setLoading(true);
    const fileData = selectedFiles.length > 0
      ? {
        file_name: selectedFiles[0].fileName,
        file_size: selectedFiles[0].fileSize,
        file_type: selectedFiles[0].fileType,
      }
      : {};
    const requestData = {
      no_of_response: cartItems.totalQuantity,
      solution: cartItems.items[0].id,
      describe: storedInputs.describe,
      file: fileData,
      user: +existingUser,
      status: "DRAFT",
      alignment_factors: alignment_factors,
      store: modifiedauditcountwithId,
      category: +selectedCategoryId,
    };

    const promise = postSaveForLaterData(requestData, `client/mp_order/${orderId}`);
    promise.then(response => response.json())
      .then(data => {
        if (data) {
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
            setLoading(false);
          }, 1500);
        }
        if (data && saveForLaterExitClicked) {
          goBack();
        }
      })
      .catch(error => {
        console.error('Error:', error);
        setShowWarningAlert(true);
        setTimeout(() => {
          setShowWarningAlert(false);
          setLoading(false);
        }, 1500);
      }).finally(() => {
        setLoading(false);
    });

  }



  return (
    <div>
      {/* <button className="btn btn-md text-center btn-size create_ac_btn"
        onClick={handleSaveforLater}
      >SAVE FOR LATER</button> */}
      {loading ?

        <button className="btn btn-md text-center btn-size create_ac_btn" type="button" disabled style={{ fontSize: "1rem", background: "#007DC1", color: "white", }}>
          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: ".5rem" }}></span>
          Saving...
        </button>

        :

        <div className="btn btn-md text-center btn-size create_ac_btn"
          onClick={handleSaveforLater}
        >SAVE FOR LATER</div>
      }
      {showAlert && (<Alert variant="success" onClose={() => setShowAlert(false)} dismissible>
        Draft Saved successfully
      </Alert>
      )}
      {showWarningAlert && (<Alert variant="danger" onClose={() => setShowWarningAlert(false)} dismissible>
        Failed to save as Draft
      </Alert>
      )}
    </div>
  )
}
