import React from 'react'

export default function MobileYourAudit() {
    return (
        <>
            <div className='dash_stepper_box_right_up' style={{padding:"2rem",boxShadow: "2px 4px 10px 2px rgba(0, 0, 0, 0.25)"}}>
                <h4><i className="fa-solid fa-image" style={{marginRight:".2rem"}}></i> Product/Service Information</h4>
                <p>Tell us the name, brand and look of the product. Additionally write details about how you want the project to be conducted or any specific details you'd like us to keep in mind.</p>
            </div>
        </>
    )
}
