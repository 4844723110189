import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, Row, Col, Alert } from "react-bootstrap";
import "../styles/header.css";
import { useDispatch } from "react-redux";
import { clearCart } from "../utils/cartSlice";
import { clearStoreData, removeFile } from "../utils/orderdataslice";
import base_url from "../utils/config";
import { useFetch } from "../utils/hooks/useFetch";
import floorwalklogo from "../../src/images/floorwalk_logo.png";
import { setCurrentSteptoZero } from "../utils/stepSlice";
import floorwalklogosmall from "../images/Logo_copy.png";
import { useEffect } from "react";
import GoogleTranslateWidget from "./GoogleTranslateWidget.js";

export function HeaderMenu() {
  const navigate = useNavigate();
  const [dropdownVisible, setDropdownVisible] = useState(true);
  const [dropdownVisible1, setDropdownVisible1] = useState(true);

  function handleCategoryChange(categoryId, url) {
    localStorage.setItem('selectedCategoryId', categoryId);
    navigate(`/${url}`);
    setDropdownVisible(false);
    setDropdownVisible1(false);
  }
  return (
    <ul className="nav menu-nav">

      <li className="nav-item product_head" style={{ position: "rtelative" }}
       onMouseEnter={() => setDropdownVisible(true)}
       onMouseLeave={() => setDropdownVisible(false)}
      >
        <Link className="nav-link"> Categories </Link>
       { dropdownVisible && <ul className="product_dropdown">
          <li className="dropdown_list">
            <span onClick={() => handleCategoryChange(13, "Category-Audits")}>Audits</span>
          </li>
          <li className="dropdown_list">
            <span onClick={() => handleCategoryChange(15, "Category-Insight")}>Insights</span>
          </li>
          <li className="dropdown_list">
            <span onClick={() => handleCategoryChange(14, "Category-Customer-Review")}>Customer Review</span>
          </li>
          <li className="dropdown_list">
            <span onClick={() => handleCategoryChange(16, "Category-Research")}>Research</span>
          </li>
          <li className="dropdown_list">
            <span onClick={() => handleCategoryChange(17, "solution-price")}>Pricing</span>
          </li>
          <li className="dropdown_list">
            <span onClick={() => handleCategoryChange(18, "solution-product-visibility")}>Product Visibility</span>
          </li>
        </ul>
}
      </li>
      {/* <li className="nav-item product_head" style={{ position: "rtelative" }}>
        <Link className="nav-link"> Solutions </Link>
        <ul className="product_dropdown">
          <li className="dropdown_list">
            <span onClick={() => handleCategoryChange(17, "solution-price")}>Pricing</span>
          </li>
          <li className="dropdown_list">
            <span onClick={() => handleCategoryChange(18, "solution-product-visibility")}>Product Visibility</span>
          </li>
        </ul>
      </li> */}
      <li className="nav-item product_head" style={{ position: "rtelative" }} 
       onMouseEnter={() => setDropdownVisible1(true)}
       onMouseLeave={() => setDropdownVisible1(false)}
      >
        <Link className="nav-link"> Resources </Link>
        { dropdownVisible1 &&  <ul className="product_dropdown">
          <li className="dropdown_list" onClick={() => setDropdownVisible1(false)}>
            <Link to="/blogs">Blog</Link>
          </li>
          <li className="dropdown_list"  onClick={() => setDropdownVisible1(false)}> 
            <Link to="/casestudies">Case Studies</Link>
          </li>
          <li className="dropdown_list"  onClick={() => setDropdownVisible1(false)}> 
          <Link to="/terms_and_conditions">Terms & Conditions</Link>
        </li>
        <li className="dropdown_list"  onClick={() => setDropdownVisible1(false)}> 
        <Link to="/refund_policy">Refund Policy</Link>
      </li>
      <li className="dropdown_list"  onClick={() => setDropdownVisible1(false)}> 
        <Link to="/privacy_policy">Privacy Policy</Link>
      </li>
      <li className="dropdown_list"  onClick={() => setDropdownVisible1(false)}> 
      <Link to="/frequently_asked_question">FAQs</Link>
    </li>
        </ul>
}
      </li>

      <li className="nav-item">
      <Link to="/contact_us" className="nav-link">
          Contact Us
        </Link>
      </li>
    </ul>
  )
}

const Header = () => {
  const [isOpenSidebar, setIsOpenSidebar] = useState(false);
  const existingUser = (localStorage.getItem('user_id'));
  const token = (localStorage.getItem('token'));
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const dispatch = useDispatch();
  const [isIndex, setIsIndex] = useState(0);
  const [profileData, setProfileData] = useState([]);

  useEffect(() => {
    if(existingUser){
      getProfileData("client/client_profile");
    }
  }, [isOpenSidebar])

  const getProfileData = async (url) => {
    try {
      const headers = {
        'Authorization': `Token ${token}`,
        'Content-Type': 'application/json'
      };

      const options = {
        method: 'GET',
        headers: headers
      };

      const response = await fetch(base_url + url, options);

      if (response.ok) {
        const result = await response.json();
        setProfileData(result);
      } else {
        console.error('API request failed:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('An error occurred while fetching the API:', error);
    }
  };
  function handleCategoryChange(categoryId, url) {
    localStorage.setItem('selectedCategoryId', categoryId);
    navigate(`/${url}`);
    setIsOpenSidebar(!isOpenSidebar);
    setIsIndex(0);
  }
  function handleClickSidebar() {
    setIsOpenSidebar(!isOpenSidebar);
    setIsIndex(0);
  }
  function handlesmallscreendropdown(index) {
    if (index === isIndex) {
      setIsIndex(0);
    } else {
      setIsIndex(index);
    }

  }
  function handleLogout() {
    dispatch(clearCart());
    dispatch(clearStoreData());
    dispatch(removeFile());
    localStorage.removeItem('user_id');
    localStorage.removeItem('token');
    localStorage.removeItem('client_id');
    localStorage.removeItem('orderId');
    setTimeout(() => {
      navigate("/");
    }, 1000);
    // setIsOpenSidebar(!isOpenSidebar);
    // setIsIndex(0);
  }
  function handleLogo() {
    dispatch(clearCart());
    dispatch(clearStoreData());
    dispatch(removeFile());
    localStorage.removeItem('orderId');
    window.location.reload();
  }
  function handleUsherDashboard() {
    dispatch(clearCart());
    dispatch(clearStoreData());
    dispatch(removeFile());
    dispatch(setCurrentSteptoZero());
    localStorage.removeItem('orderId');
    navigate("/userdashboardmain");
    setIsOpenSidebar(!isOpenSidebar);
    setIsIndex(0);
    window.location.reload();
  }
  function handleUsherProfile() {
    dispatch(clearCart());
    dispatch(clearStoreData());
    dispatch(removeFile());
    localStorage.removeItem('orderId');
    dispatch(setCurrentSteptoZero());
    navigate("/userdashboardmain/profile" , { state: "profile" }); 
    setIsOpenSidebar(!isOpenSidebar);
    setIsIndex(0);
  }
  return (
    <section className="nav_bar">
      <Container fluid>
        <div className="nav_bar_main">
          <Col md="1">
            <div className="logo" onClick={handleLogo}>
              <Link to="/">
                <img
                  src={floorwalklogo}
                  alt=""
                />
              </Link>
            </div>
            <i className="fa-solid fa-bars" style={{cursor:"pointer"}} onClick={handleClickSidebar}></i>
          </Col>
          <Col md="7" className="nav_lists">
            <HeaderMenu />
          </Col>
          <Col md="3" >
            <div className="search-signin">
              <div style={{marginRight:"10px"}}>
               <GoogleTranslateWidget />
              </div>

              {/* <form className="form-inline my-2 my-lg-0">
                <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" style={{ borderRadius: "25px" }} disabled />
              </form> */}
              {existingUser ?
                (<div className="apply-btn head_dropdown" style={{ position: "relative" }}>
                      <div style={{ backgroundColor: "black", padding: ".6rem .8rem", borderRadius: "50px" }}>
                        <i className="fa-solid fa-user" style={{ fontSize: "1rem", color: "white" }}></i>
                      </div>
                    <div className="sign-in">
                      Hi, {profileData?.first_name ? profileData?.first_name : "User" }
                    </div>
                  <ul className="logout_dropdown">
                    <li className="dropdown_list" onClick={handleUsherDashboard}>User Dashboard</li>
                    <li className="dropdown_list" onClick={handleUsherProfile}>Profile</li>
                    <li className="dropdown_list" onClick={handleLogout}>Log Out</li>
                  </ul>
                </div>)
                :
                (
                  <div className="apply-btn">
                    <Link to="/accountinfo/login">
                      <div style={{ backgroundColor: "black", padding: ".6rem .8rem", borderRadius: "50px" }}>
                        <i className="fa-solid fa-user" style={{ fontSize: "1rem", color: "white" }}></i>
                      </div>
                    </Link>
                    <Link to="/accountinfo/login" className="sign-in">
                      Sign in
                    </Link>
                  </div>)
              }
              
            </div>
          </Col>
        </div>
      </Container>

      {/**************************************SIDEBAR*************************************************/}

      <div className="logosmall" onClick={handleLogo}>
        <Link to="/">
          <img
            src={floorwalklogosmall}
            alt="floorwalk_logo"
            width="120px"
          />
        </Link>
      </div>
      {/* <div className={isOpenSidebar ? 'sidebar_nav_menu_container active' : 'sidebar_nav_menu_container'}> */}
      <div className={isOpenSidebar ? 'sidebar_nav_menu_container active' : 'sidebar_nav_menu_container'} onClick={(e) => {
        if (!e.target.classList.contains('sidebar_nav_menu_active')) {
          handleClickSidebar();
        }
      }}>
        <nav className={isOpenSidebar ? 'sidebar_nav_menu active' : 'sidebar_nav_menu'} onClick={(e) => e.stopPropagation()}>
          <ul className='sidebar_nav_menu_items'>
            <li className='sidebar_close_toggle' onClick={handleClickSidebar}>
              <i className="fa-solid fa-xmark"></i>
            </li>
            <ul className="sidebar_item_container" style={{ paddingLeft: "0rem" }}>
              {/* ------------------------------------------------------------------------------------------------ */}
              {existingUser ?
                (
                  < >
                    <li className="sidebar_nav_items" style={{ paddingTop: "0rem" }}>
                      <ul className="sidebar_nav_items" style={{ display: "flex", gap: "1rem", justifyContent: "flex-start", alignItems: "center", border: "none" }}>
                        <li>
                          <span style={{ backgroundColor: "black", padding: ".9rem 1rem", borderRadius: "50px" }}>
                            <i className="fa-solid fa-user" style={{ fontSize: "1rem", color: "white" }}></i>
                          </span>
                        </li>
                        <li style={{ display: "flex", flexDirection: "column", gap: ".2rem", justifyContent: "center" }}>
                          {profileData?.first_name && <span style={{ wordBreak: "break-all" }}>{profileData?.first_name} {profileData?.last_name}</span>}
                          {profileData?.email && <span style={{ wordBreak: "break-all" }}>{profileData?.email}</span>}
                        </li>
                      </ul>
                    </li>
                  </>
                ) : null}
              {/* ------------------------------------------------------------------------------------------------ */}
              <li
                style={{ display: "block", }}
                className="sidebar_nav_items" onClick={() => handlesmallscreendropdown(1)}><span className="sidebar_nav_items_li"> Categories </span>
                {isIndex === 1 ? <span><i className="fa-solid fa-chevron-up"></i></span> : <span><i className="fa-solid fa-chevron-down"></i></span>}
                {isIndex === 1 &&
                  <ul>
                    <li className="sidebar_nav_items">
                      <span onClick={() => handleCategoryChange(16, "Category-Research")} className="sidebar_nav_items_a" >Research</span>
                    </li>
                    <li className="sidebar_nav_items">
                      <span onClick={() => handleCategoryChange(13, "Category-Audits")} className="sidebar_nav_items_a">Audits</span>
                    </li>
                    <li className="sidebar_nav_items">
                      <span onClick={() => handleCategoryChange(14, "Category-Customer-Review")} className="sidebar_nav_items_a">Customer Review</span>
                    </li>
                    <li className="sidebar_nav_items">
                      <span onClick={() => handleCategoryChange(15, "Category-Insight")} className="sidebar_nav_items_a">Insights</span>
                    </li>
                    <li className="sidebar_nav_items">
                      <span onClick={() => handleCategoryChange(17, "solution-price")} className="sidebar_nav_items_a" >Pricing</span>
                    </li>
                    <li className="sidebar_nav_items">
                      <span onClick={() => handleCategoryChange(18, "solution-product-visibility")} className="sidebar_nav_items_a">Product Visibility</span>
                    </li>
                  </ul>
                }
              </li>

              {/* <li style={{ display: "block", }}  className="sidebar_nav_items sidebar_nav_items_li" onClick={() => handlesmallscreendropdown(2)}><span  style={{marginRight:"8rem"}}>Solutions</span>
              {isIndex === 2 ? <span><i className="fa-solid fa-chevron-up"></i></span> : <span><i className="fa-solid fa-chevron-down"></i></span>}
              {isIndex === 2 && 
              <ul >
              <li className="sidebar_nav_items">
                <span onClick={() => handleCategoryChange(17, "solution-price")} className="sidebar_nav_items_a" >Pricing</span>
              </li>
              <li className="sidebar_nav_items">
                <span onClick={() => handleCategoryChange(18, "solution-product-visibility")} className="sidebar_nav_items_a">Product Visibility</span>
              </li>
            </ul>
}
              </li> */}
              <li style={{ display: "block", }} className="sidebar_nav_items" onClick={() => handlesmallscreendropdown(3)}> <span className="sidebar_nav_items_li_resource"> Resources</span>
                {isIndex === 3 ? <span><i className="fa-solid fa-chevron-up"></i></span> : <span><i className="fa-solid fa-chevron-down"></i></span>}
                {isIndex === 3 &&
                  <ul >
                    <li className="sidebar_nav_items">
                      {/* <a href="https://floorwalk.in/blog/" className="sidebar_nav_items_a" target="_blank">Blog</a> */}
                      <Link to="/blogs" className="sidebar_nav_items_a" onClick={handleClickSidebar}>Blog</Link>
                    </li>
                    <li className="sidebar_nav_items">
                      {/* <a href="https://floorwalk.in/case_studies.html" target="_blank" className="sidebar_nav_items_a" onClick={handleClickSidebar}>Case Studies</a> */}
                      <Link to="/casestudies" className="sidebar_nav_items_a" onClick={handleClickSidebar}>Case Studies</Link>
                    </li>
                    <li className="sidebar_nav_items">
                    <Link to="/terms_and_conditions" className="sidebar_nav_items_a" onClick={handleClickSidebar}>Terms & Conditions</Link>
                    </li>
                    <li className="sidebar_nav_items">
                    <Link to="/refund_policy" className="sidebar_nav_items_a" onClick={handleClickSidebar}>Refund Policy</Link>
                    </li>
                    <li className="sidebar_nav_items">
                    <Link to="/privacy_policy" className="sidebar_nav_items_a" onClick={handleClickSidebar}>Privacy Policy</Link>
                    </li>
                    <li className="sidebar_nav_items">
                    <Link to="/frequently_asked_question" className="sidebar_nav_items_a" onClick={handleClickSidebar}>FAQs</Link>
                    </li>
                  </ul>}
              </li>
              <li className="sidebar_nav_items sidebar_nav_items_li">
              <Link to="/contact_us" className="sidebar_nav_items_a" onClick={handleClickSidebar} style={{ fontSize: ".9rem" }}>Contact Us</Link>
              </li>
              {existingUser ?
                (
                  < >
                    <li className="sidebar_nav_items" onClick={handleUsherDashboard}>User Dashboard</li>
                    <li className="sidebar_nav_items" onClick={handleUsherProfile}>Profile</li>
                    <li className="sidebar_nav_items" onClick={handleLogout} style={{ cursor: "pointer", color: "#ff6f6f" }}>Log Out</li>
                  </>
                )
                :
                (<Link to="/accountinfo/login" className="sidebar_nav_items cart" onClick={handleClickSidebar} style={{ color: "green" }}> <li>Sign in</li></Link>
                )
              }
            </ul>
          </ul>
        </nav>
      </div>
    </section>
  );
};

export default Header;

