import React, { useState, useEffect } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import base_url from '../../../utils/config';
import { useFetch } from '../../../utils/hooks/useFetch';
import { clearCart, addItem, removeItem } from '../../../utils/cartSlice';
import { useDispatch, useSelector } from 'react-redux';
import { clearStoreData, removeFile } from '../../../utils/orderdataslice';
import { setCurrentSteptoZero } from '../../../utils/stepSlice';
import { removeDraftData, setDraftData } from '../../../utils/draftDataSlice';
import { postSaveForLaterData } from '../../../utils/services/PostdataHandle';

export default function DashProjectSetup() {
    const location = useLocation();
    // const draftProduct = location.state;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // dispatch(setDraftData(draftProduct));
    const cartItems = useSelector((store) => store.cart.items);
    const cartTotalQuantity = useSelector((store) => store.cart.totalQuantity);
    const currentStepperStep = useSelector((store) => store.step.currentStep);
    const [formData, setFormData] = useState({
        category_name: '',
        product_name: '',
        // category_name: draftProduct?.mp_order?.category || '', 
        // product_name: draftProduct?.mp_order?.product_name || '',
    });
    const [errorMessages, setErrorMessages] = useState({
        category_nameError: '',
        product_nameError: '',
    });
    const [categoryListUrl, setCategoryListUrl] = useState(base_url + "manager/category_public");
    const { data, setData } = useFetch(categoryListUrl);
    const [productData, setProductData] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState({});
    const [selectedProductUrl, setSelectedProductUrl] = useState("");
    const [selectedProductId, setSelectedProductId] = useState(null);
    const [draftProduct, setDraftProduct] = useState({});
    const existingUser = (localStorage.getItem('user_id'));
    const token = localStorage.getItem("token");

    useEffect(() => {
        // dispatch(setDraftData(draftProduct));
        const draftOrderId = localStorage.getItem("orderId");
        if (draftOrderId) {
            getIndividualDraftData(`client/mp_order/${draftOrderId}`)
        }
    }, [])
    const getIndividualDraftData = async (url) => {
        try {
            const headers = {
                'Authorization': `Token ${token}`,
                'Content-Type': 'application/json'
            };

            const options = {
                method: 'GET',
                headers: headers
            };

            const response = await fetch(base_url + url, options);

            if (response.ok) {
                const result = await response.json();
                setFormData({
                    category_name: result?.category,
                    product_name: result?.solution?.name,
                })
                setDraftProduct({ ...result });
                if (result.error) {
                    alert("error");
                }
            } else {
                console.error('API request failed:', response.status, response.statusText);
            }
        } catch (error) {
            console.error('An error occurred while fetching the API:', error);
        }
    };
    useEffect(() => {
        if (formData.category_name) {
            fetchProductData(formData.category_name)
                .then((products) => {
                    setProductData(products);
                })
                .catch((error) => {
                    console.error('Error fetching product data:', error);
                });
        }
    }, [formData.category_name]);

    const fetchProductData = async (categoryId) => {
        try {
            const response = await fetch(base_url + "manager/public_category/" + categoryId + "/solution_detail");
            if (response.ok) {
                const result = await response.json();
                return result;
            } else {
                console.error('API request failed:', response.status, response.statusText);
                return [];
            }
        } catch (error) {
            console.error('An error occurred while fetching product data:', error);
            return [];
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
        setErrorMessages(prevErrors => ({
            ...prevErrors,
            [`${name}Error`]: ''
        }));
        if (name === 'category_name') {
            localStorage.setItem('selectedCategoryId', value);
        }
        if (name === 'product_name') {
            const Id = +value;
            const product = productData[0]?.solutions.find(item => item.id === Id);
            const productUrl = productData[0]?.solutions.find(item => item.id === Id)?.url_structure;
            const productId = productData[0]?.solutions.find(item => item.id === Id)?.id;
            setSelectedProduct(product);
            setSelectedProductUrl(productUrl);
            setSelectedProductId(productId);
        }
        dispatch(clearCart());
    };
    const handleAddItem = (item) => {
        dispatch(addItem(item));
    };

    const handleRemoveItem = (itemId) => {
        dispatch(removeItem(itemId));
    };

    const getAddedItemCount = (itemId) => {
        let count = 0;
        cartItems.forEach((item) => {
            if (item.id === itemId) {
                count += item.quantity;
            }
        });
        return count;
    };
    const handleBuyProduct = () => {
        const requestData = {
            no_of_response: 1,
            solution: selectedProductId,
            describe: "",
            file: {},
            user: +existingUser,
            status: "DRAFT",
            alignment_factors: [],
            store: {},
            category: +(localStorage.getItem("selectedCategoryId")),
        };
        const promise = postSaveForLaterData(requestData, "client/order_mp");
        promise.then(response => response.json())
            .then(data => {
                localStorage.setItem("orderId", data?.id)
                if (selectedProductUrl && selectedProductId) {
                    dispatch(addItem(selectedProduct));
                    navigate(`/userdashboardmain/projectsetup/stepupprogressbar/${selectedProductUrl}`, { state: selectedProductId });
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
        // if (selectedProductUrl && selectedProductId) {
        //     dispatch(addItem(selectedProduct));
        //     // localStorage.setItem('cartItemId', selectedProductId);
        //     navigate(`/userdashboardmain/projectsetup/stepupprogressbar/${selectedProductUrl}`, { state: selectedProductId });
        // }
    }
    const handleCrossIcon = () => {
        dispatch(clearCart());
        dispatch(clearStoreData());
        dispatch(removeFile());
        dispatch(setCurrentSteptoZero());
        dispatch(removeDraftData());
        localStorage.removeItem('orderId');
        navigate("/userdashboardmain")
    }

    return (
        <div>
            <div style={{ marginBottom: "1rem" }}>
                <div onClick={handleCrossIcon} style={{ cursor: "pointer" }}>
                    <i className="fa-solid fa-x"></i>
                </div>
            </div>
            {currentStepperStep === 0 ?
                <Row>
                    <Col md="4">
                        <p className="registration_form_content">
                            <label>Category<span>*</span></label>
                            <select name='category_name' onChange={handleChange} value={formData.category_name}>
                                <option value="">Select</option>
                                {data.map((item) => (
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                ))}
                            </select>
                            {errorMessages.category_nameError && (
                                <span className="error_message">{errorMessages.category_nameError}</span>
                            )}
                        </p>
                    </Col>
                    <Col md="4">
                        <p className="registration_form_content">
                            <label>Product<span>*</span></label>
                            <select name='product_name' onChange={handleChange} value={formData.product_name}>
                                <option value="">{formData?.product_name ? formData?.product_name : "Select"}</option>
                                {/* <option value="">Select</option> */}
                                {productData.map((item) => (
                                    item.solutions.map((product) => (
                                        <option key={product.id} value={product.id}>{product.name}</option>
                                    ))
                                ))}
                            </select>
                            {errorMessages.product_nameError && (
                                <span className="error_message">{errorMessages.product_nameError}</span>
                            )}
                        </p>
                    </Col>
                    {Object.entries(draftProduct).length ?
                        <Col md="2" style={{ paddingTop: "2rem" }}>
                            {/* {cartTotalQuantity === 0 && <button className="btn btn-md text-center btn-size create_ac_btn" onClick={handleBuyProduct}>Buy Now</button>} */}
                            {cartTotalQuantity > 0 && <div className="quantityContainer dashquantityContainer">
                                <button disabled={getAddedItemCount(draftProduct?.solution?.id) < 1}
                                    onClick={() => handleRemoveItem(draftProduct?.solution?.id)}><i className="fa-solid fa-minus" style={{ color: "black" }}></i></button>
                                <button>{draftProduct?.no_of_response}</button>
                                <button onClick={() => handleAddItem(draftProduct?.solution)}><i className="fa-solid fa-plus"></i></button>
                            </div>
                            }
                        </Col> :
                        <Col md="2" style={{ paddingTop: "2rem" }}>
                            {cartTotalQuantity === 0 && <button className="btn btn-md text-center btn-size create_ac_btn" onClick={handleBuyProduct}>Buy Now</button>}
                            {cartTotalQuantity > 0 && <div className="quantityContainer dashquantityContainer">
                                <button disabled={getAddedItemCount(selectedProduct.id) < 1}
                                    onClick={() => handleRemoveItem(selectedProduct.id)}><i className="fa-solid fa-minus" style={{ color: "black" }}></i></button>
                                <button>{getAddedItemCount(selectedProduct.id) || 0}</button>
                                <button onClick={() => handleAddItem(selectedProduct)}><i className="fa-solid fa-plus"></i></button>
                            </div>
                            }
                        </Col>
                    }
                </Row>
                : null}
            <div>
                <Outlet style={{ paddingLeft: "0rem" }} />
            </div>
        </div>
    );
}